import React, {useState} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Moment} from 'moment'
import {useScreenType} from '@/hooks'
import {getRangeDate, isDateDisabled, isDatesAreInRange} from '@/pages/Settlements/utils'
import {RangePickerContainer} from '@/components'
import {SettlementsRangePickerProps} from './props'

export const SettlementsFilterRangePickerContainerStoreSymbol = Symbol(
    'SettlementsFilterRangePickerContainerStore'
)

export const SettlementsRangePicker: React.FC<SettlementsRangePickerProps> = observer(() => {
    const {isMobile} = useScreenType()

    const [rangeTypeSelected, setRangeTypeSelected] = useState<'start' | 'end'>(null)
    const [localSelectedDates, setLocalSelectedDates] = useState<[Moment, Moment]>(undefined)

    return (
        <RangePickerContainer
            value={localSelectedDates}
            disabledDate={(currentDate, selectedDates) =>
                isDateDisabled(currentDate, localSelectedDates || selectedDates, rangeTypeSelected)
            }
            onOpenChange={(open) => {
                if (!open) {
                    setRangeTypeSelected(null)
                    setLocalSelectedDates(undefined)
                }
            }}
            onCalendarChange={(dates, _, info) => {
                setRangeTypeSelected(info.range)
                setLocalSelectedDates(dates)
                if (info.range === 'start' && !isDatesAreInRange(dates)) {
                    setLocalSelectedDates([dates[0], getRangeDate(dates[0], 'end')])
                }
                return dates
            }}
            injectableIdentifier={SettlementsFilterRangePickerContainerStoreSymbol}
        />
    )
})
