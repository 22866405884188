import {injectable} from 'inversify'
import {notification} from 'antd'
import {action, makeObservable, observable} from 'mobx'
import 'reflect-metadata'
import {SelectItem} from '@/components/dumb/Select'
import {TSearchSelectContainerStore} from '@/components'
import {SearchSelectValue} from '../models'
import {openErrorNotification} from '@/utils'

@injectable()
export class SearchSelectStore implements TSearchSelectContainerStore {
    constructor(options: SelectItem[], validation?: object) {
        makeObservable(this, {
            values: observable,
            options: observable,
            search: observable,
            setOptions: action.bound,
            onSearch: action.bound,
            setValues: action.bound
        })

        this.setOptions(options)
        this.validation = validation
    }

    options: SelectItem[] = []
    values: SearchSelectValue[] = []
    validation = null
    search = ''

    clear() {
        this.values = []
    }

    onSearch(search: string): void {
        this.search = search
    }

    setOptions(options: SelectItem[]): void {
        this.options = options
    }

    validateOptionsByPattern = (values: SearchSelectValue[]) => {
        if (!this.validation) {
            return true
        }

        const value = this.validation[values[values.length - 1]?.value]

        if (!value) {
            return true
        }

        notification.destroy(value?.error)

        if (!value?.pattern?.test(this.search)) {
            openErrorNotification(value?.error, 3, value?.error)

            return false
        }

        return true
    }

    setValues(values: SearchSelectValue[]): void {
        if (!this.validateOptionsByPattern(values)) {
            return
        }

        const prevValuesLength = this.values.length
        this.values = [...values].map(item => {
            const value = this.values.find(el => el.value === item.value)
            return {...value, ...item}
        })

        if (this.values[this.values.length - 1] && prevValuesLength <= values.length) {
            this.values[this.values.length - 1].query = this.search
        }

        this.onSearch('')
    }
}
