import React from 'react'
import {Popover as AntPopover, PopoverProps} from 'antd'
import classNames from 'classnames'
import styles from './styles.scss'

export const Popover: React.FC<PopoverProps> = ({
    overlayClassName,
    className,
    children,
    ...rest
}) => {
    return (
        <AntPopover
            overlayClassName={classNames(styles.popover, overlayClassName)}
            className={className}
            {...rest}
        >
            {children}
        </AntPopover>
    )
}
