import React from 'react'
import classNames from 'classnames'
import {Col, Grid, Row, Typography} from 'antd'
import {DescriptionProps} from './props'
import translations from './translations'
import styles from './styles.scss'

const {Text} = Typography

export function Description<T>(props: DescriptionProps<T>) {
    const {dataSource, className, labelCol = 8} = props
    const screens = Grid.useBreakpoint()
    const isMobile = screens.xs

    return (
        <>
            {dataSource
                .filter((field) => !field.hidden)
                .map((field) => {
                    const _labelCol = field.labelCol || labelCol
                    const _valueCol = _labelCol >= 24 ? 24 : 24 - _labelCol

                    return (
                        <Row
                            gutter={[16, 4]}
                            key={field.key || field.label}
                            className={classNames(
                                styles.row,
                                styles.description,
                                {
                                    [styles.descriptionMobile]: isMobile
                                },
                                className
                            )}
                        >
                            {field.label ? (
                                <Col xs={24} md={_labelCol}>
                                    <Text className={styles.grey}>{field.label}</Text>
                                </Col>
                            ) : null}
                            <Col xs={24} md={_valueCol}>
                                {Boolean(field.value) && field.copyable ? (
                                    <Text
                                        copyable={{
                                            text: `${field.value}`,
                                            tooltips: [translations().copy, translations().copied]
                                        }}
                                    >
                                        <>{field.render ? field.render() : field.value}</>
                                    </Text>
                                ) : (
                                    <Text>
                                        <>{field.render ? field.render() : field.value}</>
                                    </Text>
                                )}
                            </Col>
                        </Row>
                    )
                })}
        </>
    )
}
