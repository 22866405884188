import React from 'react'
import { PopupNotificationProps } from './props'
import {Modal, Button} from 'antd'
import {notificationDescription} from '@/utils'
import {CloseOutlined} from '@ant-design/icons'
import styles from './styles.scss'
import translations from './translations'

export const PopupNotification: React.FC<PopupNotificationProps> = ({ notification, onCloseNotification }) => {
    const { id, title, settings, description } = notification || {}

    const handleClose = () => {
        onCloseNotification(id)
    }

    return (
        <>
        <Modal
            open={true}
            closable={false}
            title={(
                <div className={styles.headerWrapper}>
                    <div>{title}</div>
                    <div>
                        <Button icon={<CloseOutlined />} size={'small'} onClick={handleClose} type={'link'}/>
                    </div>
                </div>
                )
            }
            footer = {
                (
                    <div className={styles.footerWrapper}>
                        {settings?.buttons?.map((button, index) => (
                            <Button
                                key={index}
                                type={button?.actionButtonType || 'default'}
                                onClick={() => button.actionButtonUrl ? window.open(button.actionButtonUrl, '_blank') : handleClose()}
                            >
                                {button.actionButtonName || translations().ok}
                            </Button>
                        ))}
                    </div>
                )}
        >
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: notificationDescription(description) }}/>
        </Modal>
        </>
    )
}
