import React from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        noAssociatedSettlementsOutlets: string
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount: string
        youAreAboutToUpdateYourSettlementBankAccount: string
        youAreAboutToUpdateYourDirectDebitBankAccount: string
        currentBankAccountDetails: string
        newBankAccountDetails: string
        byConfirmingYouAuthoriseChange: string
        bankAccountChangeSubmitted: string
        accountWillBeUpdatedForOutlets: string
        accountWillBeUpdatedForSettlementOutlets: string
        accountWillBeUpdatedForDirectDebitOutlets: string
        couldNotUpdateBankAccount: string
        nextSteps: string
        tryAgain: string
        contactSupport: string
        pleaseSelectBankAccount: string
        pleasePaymentMethod: string
        applySettlementPayments: string
        applyDirectDebitPayments: string
        selectOption: string
        useBothPayments: string
        useAccountTypeOnly: string
        allAccountChangeAlert: () => React.ReactNode
        settlementAccountChangeAlert: string
        directDebitAccountChangeAlert: string
        associatedOutlets: string
        accountNumber: string
        sortCode: string
        selectBankAccount: string
        noBankAccount: string
        addBankAccountInstructions: string
        directDebit: string
    }
    buttons: {
        confirm: string
        cancel: string
        ok: string
        confirmChange: string
    }
    titles: {
        bankChangeConfirmation: string
        bankChangeFailed: string
        changeSettlementAccount: string
        changeDirectDebitAccount: string
        changeTo: string
    }
}>()
