import React from 'react'
import translations from './translations'

translations.add('ro', {
    bankAccountCreate: 'Adaugă cont bancar nou',
    infoTop: {
        title: 'Ghid:',
        topDescriptionSoleTrader: () => (
            <div>
                Cont Curent Personal sau de Afaceri – Eligibil pentru decontări și debite directe.
                <br />
                Cont de Economii Personal sau de Afaceri – Eligibil doar pentru decontări.
            </div>
        ),
        topDescription: () => (
            <div>
                Cont Curent de Afaceri – Eligibil pentru decontări și debite directe. <br />
                Cont de Economii de Afaceri – Eligibil doar pentru decontări.
            </div>
        ),
        description: () => (
            <div>
                Prin trimiterea detaliilor contului bancar, recunoașteți și consimțiți ca acest cont
                să fie folosit pentru tranzacții viitoare de debit direct, odată ce a fost
                verificat.
            </div>
        )
    },
    labels: {
        entityType: 'Tip entitate',
        legalName: 'Nume legal',
        accountType: 'Tip cont',
        bankAccountNumber: 'Număr cont bancar',
        sortCode: 'Cod de clasificare',
        accountName: 'Nume cont'
    },
    alerts: {
        validation: {
            checkDetails:
                'Vă rugăm să verificați codul de clasificare și numărul contului bancar și să încercați din nou'
        },
        verification: {
            title: 'Verificarea datelor bancare',
            results: 'Rezultatele verificării datelor bancare',
            failed: {
                title: 'Nu am putut verifica datele dumneavoastră',
                description: () => (
                    <div>
                        Din păcate, verificarea automată nu a avut succes. <br />
                        Vă rugăm să contactați echipa noastră de suport pentru a continua.
                    </div>
                )
            },
            success: {
                title: 'Excelent, am verificat datele dumneavoastră bancare!',
                description: 'Noile date bancare au fost adăugate în lista conturilor verificate.'
            }
        },
        nextSteps: {
            title: 'Pașii următori',
            changeAccount: {
                title: 'Pași pentru schimbarea datelor contului bancar',
                steps: {
                    proceedToOutlets: 'Accesați Setările - POS sau Magazinele online',
                    select: 'Faceți clic pe Punctul de vânzare pentru a vedea detaliile acestuia',
                    pressEdit:
                        'Apăsați butonul "Schimbare cont pentru debit direct sau decontări" și urmați instrucțiunile'
                }
            }
        }
    },
    options: {
        business: 'Afaceri',
        personal: 'Personal'
    },
    placeholders: {
        accountNumber: '-- -- -- -- --',
        sortCode: '-- -- --',
        accountName: 'Nume cont bancar'
    },
    validation: {
        required: {
            accountNumber: 'Vă rugăm să introduceți numărul contului bancar',
            sortCode: 'Vă rugăm să introduceți codul de clasificare',
            accountName: 'Vă rugăm să introduceți numele contului'
        },
        format: {
            accountNumberTooShort: 'Numărul contului este prea scurt',
            sortCodeFormat: 'Codul de clasificare trebuie să aibă 6 cifre'
        }
    },
    buttons: {
        startVerification: 'Începe verificarea',
        cancel: 'Anulează',
        proceedToOutlets: 'Accesați secțiunea de puncte de vânzare',
        close: 'Închide',
        contactSupport: 'Contactați suportul'
    }
})
