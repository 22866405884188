import React from 'react'
import {Collapse, Grid} from 'antd'
import {observer} from 'mobx-react'
import {CaretRightOutlined} from '@ant-design/icons'
import {DrawerContainer} from '@/components/containers'
import {Description} from '@/components/dumb/Description'
import {TransactionDetailsProps} from './props'
import styles from './styles.scss'

const {Panel} = Collapse
const {useBreakpoint} = Grid

export const TransactionDetails: React.FC<TransactionDetailsProps> = observer(
    ({open, title, details, drawerInjectableId}: TransactionDetailsProps) => {
        const screens = useBreakpoint()
        const isMobile = screens.xs

        return (
            <DrawerContainer
                injectableIdentifier={drawerInjectableId}
                placement='right'
                title={title}
                open={open}
                className={styles.drawer}
            >
                <Collapse
                    bordered={false}
                    ghost
                    defaultActiveKey={['0', '1']}
                    expandIcon={({isActive}) => (
                        <CaretRightOutlined
                            className={styles.expandIcon}
                            rotate={isActive ? 270 : 90}
                        />
                    )}
                    expandIconPosition={'end'}
                    className={styles.accordion}
                >
                    {details.map((detail, index) => (
                        <Panel className={styles.panel} header={detail.title} key={index}>
                            <Description
                                className={isMobile && styles.description}
                                dataSource={detail.fields}
                            />
                        </Panel>
                    ))}
                </Collapse>
            </DrawerContainer>
        )
    }
)
