import React from 'react'
import {Typography} from 'antd'
import {observer} from 'mobx-react'

import {formatSortCode} from '@/services/utils'

import translations from '../../translations'
import type {BankAccountDetailsProps} from './props'

export const BankAccountDetails = observer(
    ({accountNumber, sortCode, label}: BankAccountDetailsProps) => (
        <>
            {label && (
                <>
                    <Typography.Text>{label}</Typography.Text>
                    <br />
                </>
            )}
            <Typography.Text>
                {translations().labels.accountNumber}: {accountNumber}
            </Typography.Text>
            <br />
            <Typography.Text>
                {translations().labels.sortCode}: {formatSortCode(sortCode)}
            </Typography.Text>
        </>
    )
)
