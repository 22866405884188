import React from 'react'
import classNames from 'classnames'
import { Icon } from '@/components/dumb/Icon'
import { BurgerProps } from './props'
import styles from './styles.scss'
import { MENU_BURGER } from '@/constants/playwright-ids'

export const Burger: React.FC<BurgerProps> = ({ className, ...rest }) => (
    <div className={styles.burger} data-test-id={MENU_BURGER}>
        <Icon
            type='burger_menu'
            className={classNames(styles.burger, className)}
            {...rest}
        />
    </div>
)
