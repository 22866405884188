export const themeConfig = {
    token: {
        fontFamily: 'Poppins, serif',
        colorBgContainer: '#fff',
        colorPrimary: '#317e72',
        controlHeight: 40,
        borderRadius: 12
    },
    components: {
        Tabs: {
            verticalItemMargin: '0',
            horizontalMargin: '16px 0 0 0'
        },
        Card: {
            padding: 0
        },
        Button: {
            fontFamily: 'Poppins, serif',
            defaultHoverColor: null,
            defaultHoverBg: null,
            defaultHoverBorderColor: null,
            primaryColor: '#FACF1A',
            borderRadius: 12,
            ghostBg: null,
            defaultGhostColor: null,
            defaultGhostBorderColor: null
        },

        Select: {
            singleItemHeightLG: 50
        },

        Typography: {
            fontSize: null,
            titleMarginTop: 0
        }
    }
}
