import translations from './translations'

translations.add('en', {
    labels: {
        bank: 'Bank',
        sortCode: 'Sort-code',
        accountNumber: 'Account number',
        associatedOutlets: 'Associated outlets',
        changeSettlementsBankDetails: 'Change Settlements account',
        changeDirectDebitBankDetails: 'Change Direct Debit account',
        changeInProgress: 'Change in progress',
        noAssociatedOutlets: 'No associated outlets'
    }
})
