import React from 'react'
import {Col, Divider, Row, Typography} from 'antd'
import {Button, Card} from '@/components'
import {SuccessResultProps} from './props'
import styles from './styles.scss'

export const SuccessResult = ({text, createNewTransferClick, seeInTransactionsClick}: SuccessResultProps) => {
    return (
        <Card>
            <Typography.Text>{text}</Typography.Text>
            <Divider className={styles.divider} type={'horizontal'}/>

            <Row justify={'space-between'}>
                <Col>
                    <Button type={'link'} onClick={createNewTransferClick}>Create new transfer</Button>
                </Col>
                <Col>
                    <Button ghost onClick={seeInTransactionsClick}>See in transactions list</Button>
                </Col>
            </Row>
        </Card>
    )
}
