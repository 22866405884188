import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {FilterContainer, IFilterContainerStore, RefreshButton} from '@/components'
import {SettlementsRangePicker} from '../SettlementsRangePicker'
import {SettlementsFiltersStoreSymbol} from '../../Settlements'
import {SettlementsTableFilterProps} from './props'

import styles from './styles.scss'

export const SettlementsTableFilter: React.FC<SettlementsTableFilterProps> = observer(
    ({isLoading, onReloadClick}) => {
        const filterStore = useInjection<IFilterContainerStore>(SettlementsFiltersStoreSymbol)

        const onRefreshButtonClick = async () => await onReloadClick()

        return (
            <div className={classNames(styles.settlementsTableFilter)}>
                <>
                    <RefreshButton onClick={onRefreshButtonClick} disabled={isLoading} />
                    {filterStore.isFilterButtonVisible && (
                        <div className={styles.filterButtonContainer}>
                            <FilterContainer
                                store={filterStore}
                                formId={'settlements-popover-filter-form'}
                            />
                        </div>
                    )}
                    <SettlementsRangePicker />
                </>
            </div>
        )
    }
)
