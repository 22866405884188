import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'

import {Icon} from '@/components'
import {useScreenType} from '@/hooks'

import {AlertProps, AlertType} from './props'
import styles from './Alert.scss'

export const Alert: React.FC<AlertProps> = observer(
    ({title, description, type, className, extra}) => {
        const {isMobile} = useScreenType()

        const getIconType = (type: AlertType) => {
            switch (type) {
                case 'warning':
                    return 'attempted'
                case 'default':
                    return 'attempted'
                case 'success':
                    return 'info'
                default:
                    return 'info'
            }
        }

        return (
            <div
                className={classNames(styles.alert, className, {
                    [styles.alertWarning]: type === 'warning'
                })}
            >
                <div className={styles.alertHeader}>
                    <Icon
                        type={getIconType(type)}
                        className={classNames(styles.alertIcon, {
                            [styles.alertIconWarning]: type === 'warning'
                        })}
                    />
                    <div
                        className={classNames(styles.alertContent, {
                            [styles.alertContentMobile]: isMobile
                        })}
                    >
                        <div className={styles.alertDetails}>
                            {title && <span className={styles.alertTitle}>{title}</span>}
                            {description && <div className={styles.alertText}>{description}</div>}
                        </div>
                        {extra && extra}
                    </div>
                </div>
            </div>
        )
    }
)
