import translations from './translations'

translations.add('es', {
    labels: {
        bank: 'Banco',
        sortCode: 'Código de Clasificación',
        accountNumber: 'Número de Cuenta',
        associatedOutlets: 'Establecimientos Asociados',
        changeSettlementsBankDetails: 'Cambiar cuenta de liquidaciones',
        changeDirectDebitBankDetails: 'Cambiar cuenta de débito directo',
        changeInProgress: 'Cambio en curso',
        noAssociatedOutlets: 'No se utiliza para liquidaciones'
    }
})
