import React, {useMemo} from 'react'
import {observer} from 'mobx-react'
import {Button, Space} from 'antd'

import {CreateBankAccountStep} from '@/stores/bank-account-create/models'

import translations from '../../translations'
import {FormActionsButtonProps} from './props'

export const FormActionButtons = observer(
    ({
        step,
        onBack,
        openChat,
        isValidationPassed,
        onSuccessAction,
        startVerificationTag,
        proceedToOutletsTag,
        contactSupportTag,
        initialFormCancelTag,
        successCancelTag,
        failureCancelTag
    }: FormActionsButtonProps) => {
        const t = translations().buttons

        const buttons = useMemo(
            () => ({
                [CreateBankAccountStep.initialForm]: (
                    <Space>
                        <Button
                            className={startVerificationTag}
                            type='primary'
                            htmlType='submit'
                            disabled={!isValidationPassed}
                        >
                            <span className={startVerificationTag}>{t.startVerification}</span>
                        </Button>
                        <Button className={initialFormCancelTag} onClick={onBack}>
                            <span className={initialFormCancelTag}>{t.cancel}</span>
                        </Button>
                    </Space>
                ),
                [CreateBankAccountStep.success]: (
                    <Space>
                        <Button
                            className={proceedToOutletsTag}
                            type='primary'
                            htmlType={'button'}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                onSuccessAction()
                            }}
                        >
                            <span className={proceedToOutletsTag}>{t.proceedToOutlets}</span>
                        </Button>
                        <Button htmlType={'button'} className={successCancelTag} onClick={onBack}>
                            <span className={successCancelTag}>{t.close}</span>
                        </Button>
                    </Space>
                ),
                [CreateBankAccountStep.failure]: (
                    <Space>
                        <Button
                            htmlType={'button'}
                            className={contactSupportTag}
                            type='primary'
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                openChat()
                            }}
                        >
                            <span className={contactSupportTag}>{t.contactSupport}</span>
                        </Button>
                        <Button className={failureCancelTag} onClick={onBack}>
                            <span className={failureCancelTag}>{t.cancel}</span>
                        </Button>
                    </Space>
                )
            }),
            [t, isValidationPassed, onBack, openChat, onSuccessAction]
        )

        return (
            <div>
                <br />
                <br />
                {buttons[step] || null}
            </div>
        )
    }
)
