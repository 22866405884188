import React from 'react'
import translations from './translations'

translations.add('en', {
    labels: {
        noAssociatedSettlementsOutlets: 'Not used for settlements',
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount:
            'You are about to update your Settlement and Direct Debit bank account. Please review the details below:',
        youAreAboutToUpdateYourSettlementBankAccount:
            'You are about to update your Settlement bank account. Please review the details below',
        youAreAboutToUpdateYourDirectDebitBankAccount:
            'You are about to update your Direct Debit bank account. Please review the details below',
        currentBankAccountDetails: 'Current bank account details:',
        newBankAccountDetails: 'New bank account details:',
        byConfirmingYouAuthoriseChange: 'By confirming, you authorise this change.',
        bankAccountChangeSubmitted:
            'Your bank account change request has been submitted successfully.',
        accountWillBeUpdatedForOutlets:
            'The Settlement and Direct Debit account will be updated for all associated outlets.',
        accountWillBeUpdatedForSettlementOutlets:
            'The Settlement account will be updated for all associated outlets.',
        accountWillBeUpdatedForDirectDebitOutlets:
            'The Direct Debit account will be updated for all associated outlets.',
        couldNotUpdateBankAccount: "We couldn't update your bank account.",
        nextSteps: 'Next steps:',
        tryAgain: 'Please try again;',
        contactSupport: 'If the issue persists, contact our Support Team for assistance.',
        pleaseSelectBankAccount: 'Please select a bank account',
        pleasePaymentMethod: 'Please select an option',
        applySettlementPayments: 'Apply this account for Settlement payments?',
        applyDirectDebitPayments: 'Apply this account for Direct Debit payments?',
        selectOption: 'Select an option',
        useBothPayments: 'Yes, apply to both Direct Debit and Settlements',
        useAccountTypeOnly: 'No, apply to {accountType} only',
        allAccountChangeAlert: () => (
            <>
                The settlement and Direct Debit account will be updated for all associated outlets.
                <ul>
                    <li>Settlements will be sent to the new bank account.</li>
                    <li>Direct Debit payments will now be collected from this account.</li>
                </ul>
            </>
        ),
        settlementAccountChangeAlert:
            'The settlement account will be updated for all associated outlets.\n' +
            'Future settlements will be sent to the new bank account.',
        directDebitAccountChangeAlert:
            'The Direct Debit account will be updated for all associated outlets.\n' +
            'Direct Debit payments will now be collected from this account.',
        associatedOutlets: 'Associated outlets',
        accountNumber: 'Account number',
        sortCode: 'Sort-code',
        selectBankAccount: 'Select bank account listed in the "Bank accounts"',
        noBankAccount: "You haven't added a new bank account yet",
        addBankAccountInstructions:
            'Please go to Settings > Bank Accounts and add new bank account to proceed',
        directDebit: 'Direct Debit'
    },
    buttons: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        ok: 'Ok',
        confirmChange: 'Confirm change'
    },
    titles: {
        bankChangeConfirmation: 'Bank change confirmation',
        bankChangeFailed: 'Bank change failed',
        changeSettlementAccount: 'Change Settlement bank account',
        changeDirectDebitAccount: 'Change Direct Debit bank account',
        changeTo: 'Change to'
    }
})
