import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Empty, Space, Spin} from 'antd'

import {
    Card,
    PageContainer,
    IOutletsContainerStore,
    PaginationContainer,
    Container,
    RefreshButton
} from '@/components'
import {OutletCard} from '@/components/containers'

import {OutletsContainerProps} from './props'
import styles from './OutletsContainer.scss'

export const OutletsContainer: React.FC<OutletsContainerProps> = observer(
    ({
        injectableIdentifier,
        paginationInjectableIdentifier,
        containerTitle,
        tooltipText,
        extra
    }) => {
        const {isLoading, onOutletCardClick, outlets, reload, onBack} =
            useInjection<IOutletsContainerStore>(injectableIdentifier)

        return (
            <Container className={styles.container}>
                <PageContainer
                    title={containerTitle}
                    isLoading={false}
                    extra={
                        <Spin spinning={isLoading} indicator={null}>
                            <Space>
                                <RefreshButton className={styles.reload} onClick={reload} />
                                {extra}
                            </Space>
                        </Spin>
                    }
                    tooltipText={tooltipText}
                    onBack={onBack}
                >
                    {isLoading && <Card loading={isLoading} />}
                    {!isLoading &&
                        outlets.map((outlet, index) => (
                            <OutletCard
                                key={`${outlet.id}-${index}`}
                                mid={outlet.mid}
                                address={outlet.address}
                                isActive={outlet.isActive}
                                storeName={outlet.storeName}
                                latitude={outlet.latitude}
                                longitude={outlet.longitude}
                                onClick={() => onOutletCardClick(outlet.id)}
                            />
                        ))}
                    {outlets.length === 0 && !isLoading && <Empty description={false} />}
                    <PaginationContainer injectableIdentifier={paginationInjectableIdentifier} />
                </PageContainer>
            </Container>
        )
    }
)
