import React from 'react'
import {Logo, LanguageSelect} from '@/components'
import {Theme} from '@/constants'
import styles from './styles.scss'

export const AuthLayout: React.FC<{children: React.ReactNode}> = ({ children }) => {

    return <div className={styles.layout}>
        <LanguageSelect className={styles.language} />
        <div className={styles.content}>
            <Logo type={Theme.LIGHT} className={styles.logo} />
            {children}
        </div>
    </div>
}
