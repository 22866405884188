import React from 'react'
import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    bankAccountCreate: string
    infoTop: {
        title: string
        topDescriptionSoleTrader: () => React.ReactNode
        topDescription: () => React.ReactNode
        description: () => React.ReactNode
    }
    labels: {
        entityType: string
        legalName: string
        accountType: string
        bankAccountNumber: string
        sortCode: string
        accountName: string
    }
    alerts: {
        validation: {
            checkDetails: string
        }
        verification: {
            title: string
            results: string
            failed: {
                title: string
                description: () => React.ReactNode
            }
            success: {
                title: string
                description: string
            }
        }
        nextSteps: {
            title: string
            changeAccount: {
                title: string
                steps: {
                    proceedToOutlets: string
                    select: string
                    pressEdit: string
                }
            }
        }
    }
    options: {
        business: string
        personal: string
    }
    placeholders: {
        accountNumber: string
        sortCode: string
        accountName: string
    }
    validation: {
        required: {
            accountNumber: string
            sortCode: string
            accountName: string
        }
        format: {
            accountNumberTooShort: string
            sortCodeFormat: string
        }
    }
    buttons: {
        startVerification: string
        cancel: string
        proceedToOutlets: string
        close: string
        contactSupport: string
    }
}>()
