import React from 'react'
import {debounce} from 'lodash'

import {IBankAccountCreateStore} from '@/pages/BankAccountCreate'
import {CONFIGS, DEBOUNCE_DELAY} from '@/stores/bank-account-create/services/configs'

export const useBankAccountValidation = (store: IBankAccountCreateStore) => {
    return React.useMemo(
        () =>
            debounce(async (accountNumber: string, sortCode: string) => {
                if (
                    accountNumber.length >= CONFIGS.minAccountNumberLength &&
                    sortCode.length === CONFIGS.sortCodeLength
                ) {
                    try {
                        const result = await store.validateBankAccount(accountNumber, sortCode)
                        return result.isValid
                    } catch (error) {
                        console.error('Bank account validation error:', error)
                        return false
                    }
                }
                return false
            }, DEBOUNCE_DELAY),
        [store]
    )
}
