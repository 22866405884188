import translations from './translations'
import React from 'react'

translations.add('en', {
    bankAccountCreate: 'Add new bank account',
    infoTop: {
        title: 'Guide:',
        topDescriptionSoleTrader: () => (
            <div>
                Personal or Business Current Account - Eligible for Settlements and Direct Debits.{' '}
                <br />
                Personal or Business Saving Account - Eligible for Settlements only.
            </div>
        ),
        topDescription: () => (
            <div>
                Business Current Account – Eligible for Settlements and Direct Debits. <br />
                Business Savings Account – Eligible for Settlements only.
            </div>
        ),
        description: () => (
            <div>
                By submitting your bank account details, you acknowledge and consent to this account
                being used for future direct debit transactions once verified.
            </div>
        )
    },
    labels: {
        entityType: 'Entity Type',
        legalName: 'Legal name',
        accountType: 'Account type',
        bankAccountNumber: 'Bank account number',
        sortCode: 'Sort code',
        accountName: 'Account name'
    },
    alerts: {
        validation: {
            checkDetails: 'Please check the Sort code and Bank account number and try again'
        },
        verification: {
            title: 'Bank details verification',
            results: 'Bank details verification results',
            failed: {
                title: 'We couldn’t verify your details',
                description: () => (
                    <div>
                        Unfortunately, the automated verification was not successful. <br />
                        Please contact our Support Team to proceed.
                    </div>
                )
            },
            success: {
                title: 'Great, we’ve verified your bank details!',
                description:
                    'Your new bank details have been added to the list of verified bank accounts.'
            }
        },
        nextSteps: {
            title: 'Next steps',
            changeAccount: {
                title: 'Steps to change bank account details',
                steps: {
                    proceedToOutlets: 'Proceed to the Settings - POS or Online Outlets',
                    select: 'Click on the Outlet to see the Outlet details',
                    pressEdit:
                        'Press “Change Direct Debit or Settlements account” button and follow the instructions'
                }
            }
        }
    },
    options: {
        business: 'Business',
        personal: 'Personal'
    },
    placeholders: {
        accountNumber: '-- -- -- -- --',
        sortCode: '-- -- --',
        accountName: 'Bank account name'
    },
    validation: {
        required: {
            accountNumber: 'Please enter bank account number',
            sortCode: 'Please enter sort code',
            accountName: 'Please enter account name'
        },
        format: {
            accountNumberTooShort: 'Account number too short',
            sortCodeFormat: 'Sort code must be 6 digits'
        }
    },
    buttons: {
        startVerification: 'Start verification',
        cancel: 'Cancel',
        proceedToOutlets: 'Proceed to Outlets section',
        close: 'Close',
        contactSupport: 'Contact support'
    }
})
