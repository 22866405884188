import React, {useCallback, useState} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Moment} from 'moment'
import {Input, Select, Typography, Space} from 'antd'
import DatePicker from '~/code/components/dumb/DatePicker/DatePicker'
import {RANGE_DATE_FORMAT, TIME_FORMAT} from '@/constants'
import {Button, Icon} from '@/components'
import {useScreenType} from '@/hooks'
import {PeriodType} from '@/stores'
import translations from './translations'
import {RangePickerProps} from './props'
import styles from './styles.scss'
import {RANGE_DATE_PICKER, RANGE_SELECT_FILTER} from '@/constants/playwright-ids'

const {RangePicker: AntRangePicker} = DatePicker
const {Text} = Typography

const renderMobileFooter = (showRangePickerTimeFlag: boolean) => {
    return (
        <>
            <Text className={styles.mobileFooter_title}>{translations().period}</Text>
            {showRangePickerTimeFlag ? (
                <Button type={'primary'} className={styles.mobileFooter_button}>
                    {translations().submit}
                </Button>
            ) : null}
        </>
    )
}

export const RangePicker = observer(
    ({
        showRangePickerTime = false,
        withLeftMenu = false,
        isLoading = false,
        disabledDate,
        disabled,
        onChange,
        onOpenChange,
        onCalendarChange,
        startDate,
        endDate,
        value,
        setPeriod,
        periodTitle,
        ranges = {},
        className
    }: RangePickerProps) => {
        const {isMobile} = useScreenType()

        const onDatesChange = (dates) => {
            onChange && onChange(dates)
        }

        const selectRanges = Object.entries(ranges).map((item) => ({
            label: item[0],
            value: item[0]
        }))

        const _disabledDate = useCallback(
            (date: Moment) => {
                if (!disabledDate) return false

                return disabledDate(date, value || [startDate, endDate])
            },
            [disabledDate, value, startDate, endDate]
        )

        return (
            <>
                <Space
                    size='small'
                    className={classNames(styles.group, className, {[styles.loading]: isLoading})}
                >
                    {withLeftMenu ? (
                        <Select
                            data-test-id={RANGE_SELECT_FILTER}
                            className={classNames(styles.select, {[styles.selectMobile]: isMobile})}
                            value={periodTitle}
                            options={selectRanges}
                            defaultValue={translations().presets.today}
                            onChange={(value) => setPeriod(ranges[value] as PeriodType)}
                            suffixIcon={
                                <Icon className={styles.calendarIconDisabled} type={'caret_down'} />
                            }
                        />
                    ) : null}
                    <AntRangePicker
                        data-test-id={RANGE_DATE_PICKER}
                        disabled={disabled}
                        className={styles.rangePicker}
                        popupClassName={
                            isMobile ? styles.antMobilePickerDropdown : styles.antPickerDropdown
                        }
                        value={value || [startDate, endDate]}
                        picker={'date'}
                        allowClear={false}
                        format={RANGE_DATE_FORMAT}
                        suffixIcon={null}
                        showTime={
                            showRangePickerTime
                                ? {
                                      format: TIME_FORMAT
                                  }
                                : null
                        }
                        renderExtraFooter={
                            isMobile ? () => renderMobileFooter(showRangePickerTime) : null
                        }
                        disabledDate={disabledDate ? _disabledDate : null}
                        onChange={onDatesChange}
                        onOpenChange={onOpenChange}
                        onCalendarChange={onCalendarChange}
                    />
                </Space>
            </>
        )
    }
)
