import {register} from 'dna-react-ioc'
import {SettlementsStore} from '@/stores/settlements/SettlementsStore'
import {TSettlementsStore} from '@/pages/Settlements/TSettlementsStore'
import {
    SettlementsFiltersStoreSymbol,
    SettlementsPaginationStoreSymbol,
    SettlementsPerDayPaginationStoreSymbol,
    SettlementsStoreSymbol
} from '@/pages/Settlements/Settlements'
import {SettlementsFilterRangePickerContainerStoreSymbol} from '@/pages/Settlements/components/SettlementsRangePicker'
import {PaginationStore, PeriodType, RangePickerStore} from '@/stores'
import {
    IFilterContainerStore,
    PaginationContainerStoreType,
    TRangePickerContainerStore
} from '@/components'
import {Settlement} from '@/stores/settlements/models/Settlement'
import rangePickerTranslations from '@/components/dumb/RangePicker/translations/translations'
import {SettlementsFiltersStore} from '@/stores/settlements/stores'

export const settlementsState = () => {
    register<TSettlementsStore<Settlement>>(SettlementsStoreSymbol)
        .to(SettlementsStore)
        .inSingletonScope()

    register<IFilterContainerStore>(SettlementsFiltersStoreSymbol)
        .to(SettlementsFiltersStore)
        .inSingletonScope()

    register<TRangePickerContainerStore>(SettlementsFilterRangePickerContainerStoreSymbol)
        .toDynamicValue(
            () =>
                new RangePickerStore({
                    period: PeriodType.Yesterday,
                    rangePresets: {
                        [rangePickerTranslations().presets.yesterday]: PeriodType.Yesterday,
                        [rangePickerTranslations().presets.lastWeek]: PeriodType.LastWeek,
                        [rangePickerTranslations().presets.lastMonth]: PeriodType.LastMonth
                    }
                })
        )
        .inSingletonScope()
    register<PaginationContainerStoreType>(SettlementsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(SettlementsPerDayPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
}
