import React from 'react'
import translations from './translations'

translations.add('sl', {
    labels: {
        noAssociatedSettlementsOutlets: 'Nepoužíva sa pre zúčtovania',
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount:
            'Chystáte sa aktualizovať svoj bankový účet pre zúčtovania (/priame inkaso). Prosím,\nskontrolujte nižšie uvedené detaily:',
        youAreAboutToUpdateYourSettlementBankAccount:
            'Chystáte sa aktualizovať svoj bankový účet pre zúčtovania. Prosím, skontrolujte nižšie uvedené detaily',
        youAreAboutToUpdateYourDirectDebitBankAccount:
            'Chystáte sa aktualizovať svoj bankový účet pre priame inkaso. Prosím, skontrolujte nižšie uvedené detaily',
        currentBankAccountDetails: 'Aktuálne údaje bankového účtu:',
        newBankAccountDetails: 'Nové údaje bankového účtu:',
        byConfirmingYouAuthoriseChange: 'Potvrdením schvaľujete túto zmenu.',
        bankAccountChangeSubmitted: 'Žiadosť o zmenu bankového účtu bola úspešne odoslaná.',
        accountWillBeUpdatedForOutlets:
            'Bankový účet pre zúčtovania (/priame inkaso) bude aktualizovaný pre všetky priradené predajne.',
        accountWillBeUpdatedForSettlementOutlets:
            'Účet pre zúčtovania bude aktualizovaný pre všetky priradené predajne.',
        accountWillBeUpdatedForDirectDebitOutlets:
            'Účet pre priame inkaso bude aktualizovaný pre všetky priradené predajne.',
        couldNotUpdateBankAccount: 'Nepodarilo sa nám aktualizovať váš bankový účet.',
        nextSteps: 'Ďalšie kroky:',
        tryAgain: 'Skúste to prosím znova;',
        contactSupport: 'Ak problém pretrváva, kontaktujte náš tím podpory pre pomoc.',
        pleaseSelectBankAccount: 'Prosím, vyberte bankový účet',
        pleasePaymentMethod: 'Prosím, vyberte možnosť',
        applySettlementPayments: 'Použiť tento účet pre platby zúčtovaní?',
        applyDirectDebitPayments: 'Použiť tento účet pre platby priamym inkasom?',
        selectOption: 'Vyberte možnosť',
        useBothPayments: 'Použite tento účet pre obidve platby, zúčtovania aj priame inkaso',
        useAccountTypeOnly: 'Použite tento účet iba pre {accountType}',
        allAccountChangeAlert: () => (
            <>
                Účet pre zúčtovania a priame inkaso bude aktualizovaný pre všetky priradené
                predajne.
                <ul>
                    <li>Zúčtovania budú odoslané na nový bankový účet.</li>
                    <li>Platby priameho inkasa budú teraz inkasované z tohto účtu.</li>
                </ul>
            </>
        ),
        settlementAccountChangeAlert:
            'Účet pre zúčtovania bude zmenený pre všetky priradené predajne.\nZúčtovania po aktualizácii budú odoslané na nový bankový účet.',
        directDebitAccountChangeAlert:
            'Účet pre priame inkaso bude zmenený pre všetky priradené predajne.',
        associatedOutlets: 'Priradené predajne',
        accountNumber: 'Číslo účtu',
        sortCode: 'Kód pobočky',
        selectBankAccount: 'Vyberte bankový účet uvedený v "Bankové účty"',
        noBankAccount: 'Ešte ste nepridali nový bankový účet',
        addBankAccountInstructions:
            'Pre pokračovanie prejdite do Nastavení > Bankové účty a pridajte nový bankový účet',
        directDebit: 'Priame Inkaso'
    },
    buttons: {
        confirm: 'Potvrdiť',
        cancel: 'Zrušiť',
        ok: 'Ok',
        confirmChange: 'Potvrdiť zmenu'
    },
    titles: {
        bankChangeConfirmation: 'Potvrdenie zmeny bankového účtu',
        bankChangeFailed: 'Zmena bankového účtu zlyhala',
        changeSettlementAccount: 'Zmeniť bankový účet pre zúčtovania',
        changeDirectDebitAccount: 'Zmeniť bankový účet pre priame inkaso',
        changeTo: 'Zmeniť na'
    }
})
