import React from 'react'
import translations from './translations'

translations.add('sl', {
    bankAccountCreate: 'Pridať nový bankový účet',
    infoTop: {
        title: 'Sprievodca:',
        topDescriptionSoleTrader: () => (
            <div>
                Osobný alebo Firemný bežný účet – Vhodný pre zúčtovania a priame inkaso.
                <br />
                Osobný alebo Firemný sporiaci účet – Vhodný iba pre zúčtovania.
            </div>
        ),
        topDescription: () => (
            <div>
                Firemný bežný účet – Vhodný pre zúčtovania a priame inkaso. <br />
                Firemný sporiaci účet – Vhodný iba pre zúčtovania.
            </div>
        ),
        description: () => (
            <div>
                Odovzdaním údajov vášho bankového účtu potvrdzujete a súhlasíte s tým, že tento účet
                bude po overení použitý pre budúce transakcie priameho inkasa.
            </div>
        )
    },
    labels: {
        entityType: 'Typ subjektu',
        legalName: 'Právne meno',
        accountType: 'Typ účtu',
        bankAccountNumber: 'Číslo bankového účtu',
        sortCode: 'Kód pobočky',
        accountName: 'Názov účtu'
    },
    alerts: {
        validation: {
            checkDetails:
                'Prosím, skontrolujte kód pobočky a číslo bankového účtu a skúste to znova'
        },
        verification: {
            title: 'Overenie bankových údajov',
            results: 'Výsledky overenia bankových údajov',
            failed: {
                title: 'Nepodarilo sa nám overiť vaše údaje',
                description: () => (
                    <div>
                        Bohužiaľ, automatické overenie nebolo úspešné. <br />
                        Prosím, kontaktujte náš tím podpory, aby ste mohli pokračovať.
                    </div>
                )
            },
            success: {
                title: 'Výborne, overili sme vaše bankové údaje!',
                description:
                    'Vaše nové bankové údaje boli pridané do zoznamu overených bankových účtov.'
            }
        },
        nextSteps: {
            title: 'Ďalšie kroky',
            changeAccount: {
                title: 'Kroky na zmenu bankových údajov',
                steps: {
                    proceedToOutlets: 'Prejdite do časti Nastavenia - POS alebo Online predajne',
                    select: 'Kliknite na predajňu a zobrazte si jej údaje',
                    pressEdit:
                        'Stlačte tlačidlo "Zmeniť účet pre priame inkaso alebo zúčtovania" a postupujte podľa pokynov'
                }
            }
        }
    },
    options: {
        business: 'Firemné',
        personal: 'Osobné'
    },
    placeholders: {
        accountNumber: '-- -- -- -- --',
        sortCode: '-- -- --',
        accountName: 'Názov bankového účtu'
    },
    validation: {
        required: {
            accountNumber: 'Prosím, zadajte číslo bankového účtu',
            sortCode: 'Prosím, zadajte kód pobočky',
            accountName: 'Prosím, zadajte názov účtu'
        },
        format: {
            accountNumberTooShort: 'Číslo účtu je príliš krátke',
            sortCodeFormat: 'Kód pobočky musí mať 6 číslic'
        }
    },
    buttons: {
        startVerification: 'Spustiť overenie',
        cancel: 'Zrušiť',
        proceedToOutlets: 'Prejdite do sekcie predajní',
        close: 'Zatvoriť',
        contactSupport: 'Kontaktovať podporu'
    }
})
