import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'

import {RouterStore, RouterStoreSymbol} from '@/router/RouterStore'
import {IBankAccountsStore} from '@/pages/BankAccounts'
import {
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'
import {LoadingState} from '@/types'
import {DossierBankAccount} from '@/stores/bank-accounts/models'
import {getDossierBankAccounts} from '@/stores/bank-accounts/services'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'

@injectable()
export class BankAccountsStore implements IBankAccountsStore {
    routerStore: RouterStore
    dossierMerchantInfoStore: DossierMerchantInfoStore
    loadingState: LoadingState = LoadingState.IDLE
    _bankAccounts: DossierBankAccount[] = null

    public constructor(
        @inject(RouterStoreSymbol) routerStore: RouterStore,
        @inject(DossierMerchantInfoStoreSymbol)
        dossierMerchantInfoStore: DossierMerchantInfoStore
    ) {
        this.routerStore = routerStore
        this.dossierMerchantInfoStore = dossierMerchantInfoStore

        makeObservable(this, {
            loadingState: observable,
            _bankAccounts: observable,

            bankAccounts: computed,
            isLoading: computed,

            loadDossierBankAccounts: action,
            initStore: action,
            onBack: action
        })
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    get bankAccounts(): DossierBankAccount[] {
        if (this._bankAccounts === null && this.loadingState === LoadingState.IDLE) {
            this.loadDossierBankAccounts()
        }

        return this._bankAccounts || []
    }

    loadDossierBankAccounts = async () => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        this._bankAccounts = []

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await getDossierBankAccounts()

            if (error) {
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._bankAccounts = result
                })

                if (!result) return
            }
        } catch (error) {
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }

    public onBankCardClick = (index: number) => {
        this.routerStore.push(`${ROUTES.settings.bankAccounts}/${index}`)
    }

    public onAddNewBankAccountClick = () => {
        this.routerStore.push(`${ROUTES.settings.bankAccountCreate}`)
    }

    public getAccountByAccountNumber = (index: number) => {
        return this.bankAccounts[index]
    }

    public onBack = () => {
        getRouterStore().push(ROUTES.settings.base)
    }

    public initStore = () => {
        this._bankAccounts = null
        this.loadingState = LoadingState.IDLE
    }
}
