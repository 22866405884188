import React from 'react'
import translations from './translations'

translations.add('es', {
    bankAccountCreate: 'Agregar nueva cuenta bancaria',
    infoTop: {
        title: 'Guía:',
        topDescriptionSoleTrader: () => (
            <div>
                Cuenta Corriente Personal o Empresarial – Válida para liquidaciones y débitos
                directos.
                <br />
                Cuenta de Ahorros Personal o Empresarial – Válida solo para liquidaciones.
            </div>
        ),
        topDescription: () => (
            <div>
                Cuenta Corriente Empresarial – Válida para liquidaciones y débitos directos. <br />
                Cuenta de Ahorros Empresarial – Válida solo para liquidaciones.
            </div>
        ),
        description: () => (
            <div>
                Al enviar los detalles de su cuenta bancaria, usted reconoce y da su consentimiento
                para que esta cuenta se utilice en futuras transacciones de débito directo una vez
                verificada.
            </div>
        )
    },
    labels: {
        entityType: 'Tipo de entidad',
        legalName: 'Nombre legal',
        accountType: 'Tipo de cuenta',
        bankAccountNumber: 'Número de cuenta bancaria',
        sortCode: 'Código de clasificación',
        accountName: 'Nombre de la cuenta'
    },
    alerts: {
        validation: {
            checkDetails:
                'Por favor, verifique el código de clasificación y el número de cuenta bancaria e inténtelo de nuevo'
        },
        verification: {
            title: 'Verificación de datos bancarios',
            results: 'Resultados de la verificación de datos bancarios',
            failed: {
                title: 'No pudimos verificar sus datos',
                description: () => (
                    <div>
                        Desafortunadamente, la verificación automática no fue exitosa. <br />
                        Por favor, contacte a nuestro equipo de soporte para proceder.
                    </div>
                )
            },
            success: {
                title: '¡Genial, hemos verificado sus datos bancarios!',
                description:
                    'Sus nuevos datos bancarios han sido añadidos a la lista de cuentas verificadas.'
            }
        },
        nextSteps: {
            title: 'Próximos pasos',
            changeAccount: {
                title: 'Pasos para cambiar los detalles de la cuenta bancaria',
                steps: {
                    proceedToOutlets:
                        'Vaya a Configuración - Puntos de Venta (POS) o Tiendas en línea',
                    select: 'Haga clic en el Punto de Venta para ver los detalles',
                    pressEdit:
                        'Presione el botón "Cambiar cuenta de Débito directo o de Liquidaciones" y siga las instrucciones'
                }
            }
        }
    },
    options: {
        business: 'Empresarial',
        personal: 'Personal'
    },
    placeholders: {
        accountNumber: '-- -- -- -- --',
        sortCode: '-- -- --',
        accountName: 'Nombre de la cuenta bancaria'
    },
    validation: {
        required: {
            accountNumber: 'Por favor, introduzca el número de cuenta bancaria',
            sortCode: 'Por favor, introduzca el código de clasificación',
            accountName: 'Por favor, introduzca el nombre de la cuenta'
        },
        format: {
            accountNumberTooShort: 'El número de cuenta es demasiado corto',
            sortCodeFormat: 'El código de clasificación debe tener 6 dígitos'
        }
    },
    buttons: {
        startVerification: 'Iniciar verificación',
        cancel: 'Cancelar',
        proceedToOutlets: 'Proceda a la sección de puntos de venta',
        close: 'Cerrar',
        contactSupport: 'Contactar soporte'
    }
})
