import React from 'react'
import { createRoot } from 'react-dom/client';
import 'reflect-metadata'
import '~/assets/styles/main.scss'
import '@/constants'

import {App} from './App'

const root = createRoot(document.getElementById("dna-content"));

root.render(<App />);

