import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {Divider, Table} from 'antd'

import {withTitle} from '@/router/utils'
import {
    Button,
    PageContainer,
    PaginationContainer,
    RefreshButton,
    SelectWithTitleContainer,
    TabsContainer
} from '@/components'
import {useScreenType} from '@/hooks'
import {LoadingState} from '@/types'
import {EditTeammate} from '@/pages/TeamManagement/components/InviteEditTeammate/EditTeammate'
import {InviteTeammate} from '@/pages/TeamManagement/components/InviteEditTeammate/InviteTeammate'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {hasPermissions} from '@/stores/auth/services/utils'
import {USER_GUIDE_SELECTORS} from '@/constants'
import {TeamManagementFilter} from '@/pages/TeamManagement/components/TeamManagementFilter'
import {TeammateStatus} from '@/stores/team-management/constants'
import {TTeamManagementStore} from './TTeamManagementStore'
import translations from './translations'
import styles from './styles.scss'
import {INVITE_TEAMMATE_BUTTON} from '@/constants/playwright-ids'

export const TeamManagementTabsContainerStoreSymbol = Symbol('TeamManagementTabsContainerStore')
export const TeamManagementRoleSelectContainerStoreSymbol = Symbol(
    'TeamManagementRoleSelectContainerStore'
)
export const TeamManagementPaginationContainerStoreSymbol = Symbol(
    'TeamManagementPaginationContainerStore'
)
export const TeamManagementInvitedPaginationContainerStoreSymbol = Symbol(
    'TeamManagementInvitedPaginationContainerStore'
)
export const TeamManagementStoreSymbol = Symbol('TeamManagementStore')
export const TeamManagementFiltersStoreSymbol = Symbol('TeamManagementFiltersStore')

const TeamManagement = observer(() => {
    const {isMobile, isTablet} = useScreenType()
    const {
        loadingState,
        columns,
        teammates,
        onInviteTeammateButtonClick,
        selectStoreIdentifier,
        paginationStoreIdentifier,
        reload,
        isStatusAvailable,
        tabType
    } = useInjection<TTeamManagementStore>(TeamManagementStoreSymbol)

    const isInvitedTabActive = tabType === TeammateStatus.invited
    const isLoading = loadingState === LoadingState.LOADING

    return (
        <PageContainer title={translations().title} isLoading={isLoading}>
            <TabsContainer
                injectableIdentifier={TeamManagementTabsContainerStoreSymbol}
                className={classNames({
                    [styles.headerTabs]: isMobile || isTablet,
                    [styles.headerTabsMobile]: isMobile
                })}
                tabBarExtraContent={{
                    right: (
                        <div className={styles.tabsExtraContent}>
                            <RefreshButton onClick={reload} />
                            <TeamManagementFilter
                                isStatusAvailable={isStatusAvailable}
                                isInvitedTabActive={isInvitedTabActive}
                            />
                            <SelectWithTitleContainer
                                displayValue={translations().role}
                                injectableIdentifier={selectStoreIdentifier}
                            />
                            <Divider type={'vertical'} />
                            {hasPermissions([PermissionsMap.teammates.full]) ? (
                                <Button
                                    className={USER_GUIDE_SELECTORS.teamManagementButton}
                                    type={'primary'}
                                    onClick={onInviteTeammateButtonClick}
                                    data-test-id={INVITE_TEAMMATE_BUTTON}
                                >
                                    {translations().inviteTeammate}
                                </Button>
                            ) : null}
                        </div>
                    )
                }}
            />

            <Table
                scroll={{x: 500}}
                columns={columns}
                dataSource={teammates}
                pagination={false}
                rowKey={'id'}
            />
            <PaginationContainer injectableIdentifier={paginationStoreIdentifier} />
            <InviteTeammate />
            <EditTeammate />
        </PageContainer>
    )
})

export default withTitle(TeamManagement)
