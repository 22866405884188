import moment, {Moment} from 'moment-timezone'

const momentUK: Moment = moment.tz('Europe/London')

export const isDateDisabled = (
    currentDate: Moment,
    selectedDates: Moment[],
    rangeType: 'start' | 'end'
) => {
    const momentUK = moment().utcOffset(0, true) // Используем UTC с оффсетом
    const _isNowAfter6am = momentUK.hour() > 6
    const weekDay = momentUK.day()
    const prevFriday = momentUK.clone().day(weekDay >= 5 ? 5 : -2)

    // Do not allow any dates before 01.01.2022
    const isBeforeMinAllowedDate = currentDate.isBefore(
        momentUK.clone().set({year: 2022, month: 0, date: 1}).startOf('day')
    )

    // Disable dates before 3 years from the current month
    const isBefore3Years = currentDate.isBefore(
        momentUK.clone().subtract(3, 'years').startOf('day')
    )

    const isTodayOrFuture = currentDate.isSameOrAfter(momentUK.clone().startOf('day'))
    const isYesterdayAndNotAfter6am =
        currentDate.isSame(momentUK.clone().startOf('day').subtract(1, 'day'), 'day') &&
        !_isNowAfter6am
    const isSundayOrSaturdayOrMondayNotAfter6am =
        weekDay === 6 || weekDay === 0 || (weekDay === 1 && !_isNowAfter6am)

    // Dynamic restriction of up to 1 year forward or backward
    if (selectedDates && selectedDates[0]) {
        const startDate = selectedDates[0]
        const maxAllowedEndDate = startDate.clone().add(1, 'year')

        if (currentDate.isAfter(maxAllowedEndDate, 'day')) {
            return true
        }
    }

    if (rangeType === 'end' && selectedDates && selectedDates[1]) {
        const endDate = selectedDates[1]
        const minAllowedStartDate = endDate.clone().subtract(1, 'year')

        if (currentDate.isBefore(minAllowedStartDate, 'day')) {
            return true
        }
    }

    return (
        isBefore3Years ||
        isBeforeMinAllowedDate ||
        isTodayOrFuture ||
        isYesterdayAndNotAfter6am ||
        (isSundayOrSaturdayOrMondayNotAfter6am && currentDate.isSameOrAfter(prevFriday, 'day'))
    )
}

export const isDatesAreInRange = (selectedDates: Moment[]) => {
    return selectedDates[1] <= getRangeDate(selectedDates[0], 'end')
}

export const getRangeDate = (date: Moment, rangeType: 'start' | 'end') => {
    if (rangeType === 'end') {
        return date.clone().add(1, 'year').endOf('day')
    }

    return date.clone().subtract(1, 'year').startOf('day')
}

export const getLastFullMonth = () => moment().subtract(1, 'month').endOf('month')

export const disabledDateForPdf = (date: moment.Moment) => {
    // Do not allow any dates before 01.01.2022
    const isBeforeMinAllowedDate =
        date < momentUK.clone().set({year: 2022, month: 0, date: 1}).startOf('day')

    // Disable dates before 3 years from the current month
    const isBefore3Year = date < momentUK.clone().startOf('month').subtract(3, 'year')

    // Disable the current month until it has fully completed.
    const isAfterLastFullMonth = date > getLastFullMonth()

    return isBeforeMinAllowedDate || isBefore3Year || isAfterLastFullMonth
}
