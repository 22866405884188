import React from 'react'
import {Typography} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'

import {Alert} from '@/components'
import {useScreenType} from '@/hooks'

import DirectDebitLogo from '~/assets/img/bank-accounts/direct-debit.svg'

import {CreateBankAccountStep} from '@/stores/bank-account-create/models'
import {StepsDescription} from '@/pages/BankAccountCreate/components'

import translations from '../../translations'
import {AlertSectionProps} from './props'
import styles from './AlertSection.scss'

export const AlertSection = observer(({step, isValidationPassed}: AlertSectionProps) => {
    const {isMobile} = useScreenType()
    const t = translations().alerts

    switch (step) {
        case CreateBankAccountStep.initialForm:
            return (
                <>
                    {!isValidationPassed ? (
                        <Alert type='warning' description={t.validation.checkDetails} />
                    ) : (
                        <div className={styles.emptyBlock} />
                    )}
                    <Alert
                        type='info'
                        description={translations().infoTop.description()}
                        extra={
                            <img
                                src={DirectDebitLogo}
                                alt='Direct debit'
                                className={classNames(styles.alertImage, {
                                    [styles.alertImageMobile]: isMobile
                                })}
                            />
                        }
                    />
                </>
            )

        case CreateBankAccountStep.failure:
            return (
                <>
                    <Typography.Title level={4}>{t.verification.results}</Typography.Title>
                    <Alert
                        type='warning'
                        title={t.verification.failed.title}
                        description={t.verification.failed.description()}
                    />
                </>
            )

        case CreateBankAccountStep.success:
            return (
                <>
                    <Typography.Title level={4}>{t.verification.title}</Typography.Title>
                    <Alert
                        type='success'
                        title={t.verification.success.title}
                        description={t.verification.success.description}
                    />
                    <Typography.Title level={4}>{t.nextSteps.title}</Typography.Title>
                    <Alert
                        type='default'
                        title={t.nextSteps.changeAccount.title}
                        description={<StepsDescription />}
                    />
                </>
            )

        default:
            return null
    }
})
