import React from 'react'
import {observer} from 'mobx-react'

import translations from '../../translations'

export const StepsDescription = observer(() => {
    const t = translations().alerts.nextSteps.changeAccount.steps

    return (
        <div>
            1. {t.proceedToOutlets} <br />
            2. {t.select} <br />
            3. {t.pressEdit}
        </div>
    )
})
