import React from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {getRouterStore, withTitle} from '@/router/utils'
import {Container, PageContainer} from '@/components'

import {BankAccountCreateForm} from './components'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {ROUTES} from '@/router/routes'

import {IBankAccountCreateStore} from './IBankAccountCreateStore'
import translations from './translations'
import styles from './styles.scss'

export const BankAccountCreateStoreSymbol = Symbol.for('BankAccountCreateStoreSymbol')

const BankAccountCreate: React.FC<null> = observer(() => {
    const store = useInjection<IBankAccountCreateStore>(BankAccountCreateStoreSymbol)

    if (!hasPermissions([PermissionsMap.merchant.bank_accounts.full])) {
        getRouterStore().push(ROUTES.noPermissions)
    }

    return (
        <Container className={styles.container}>
            <PageContainer
                title={translations().bankAccountCreate}
                isLoading={store.isLoading}
                onBack={store.onBack}
            >
                <BankAccountCreateForm store={store} />
            </PageContainer>
        </Container>
    )
})

export default withTitle(BankAccountCreate, translations().bankAccountCreate)
