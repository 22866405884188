import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Button, ButtonProps} from 'antd'

import {THasGoogleTag} from '@/types'

import styles from './LinkButton.scss'

export const LinkButton: React.FC<ButtonProps & THasGoogleTag> = observer(
    ({title, onClick, icon, tagClassName}) => {
        return (
            <Button
                onClick={onClick}
                type='link'
                className={classNames(styles.linkButton, tagClassName)}
                icon={icon}
            >
                <span className={tagClassName}>{title}</span>
            </Button>
        )
    }
)
