import React from 'react'
import {observer} from 'mobx-react'
import {Col, Divider, Row, Statistic} from 'antd'
import {Link} from 'react-router-dom'

import {Button, Card} from '@/components'
import {getAmountWithCurrency} from '@/utils'
import translations from './translations'
import {AccountInfoHeaderCardProps} from './props'
import styles from './styles.scss'
import {CardTitle} from '@/components/composite/TransactionsCardWithBarChart/components'

export const AccountInfoHeaderCard: React.FC<AccountInfoHeaderCardProps> = observer(
    ({name, accountNumber, available, current, onClick}) => {
        return (
            <Card
                title={
                    <CardTitle title={name}>
                        <Divider type={'vertical'} className={styles.divider} />
                        IBAN: {accountNumber}
                    </CardTitle>
                }
                extra={<Link to={'#'}>{translations().monthlyStatement}</Link>}
                className={styles.card}
            >
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <Row align={'middle'}>
                            <Col>
                                <Statistic
                                    className={styles.statistic}
                                    title={translations().available}
                                    value={getAmountWithCurrency(available)}
                                />
                            </Col>
                            <Divider className={styles.divider} type={'vertical'} />
                            <Col>
                                <Statistic
                                    className={styles.statistic}
                                    title={translations().current}
                                    value={getAmountWithCurrency(current)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button className={styles.button} ghost onClick={onClick}>
                            {translations().payAndTransfer}
                        </Button>
                    </Col>
                </Row>
            </Card>
        )
    }
)
