import React from 'react'
import {Grid, Typography} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {Button} from '@/components/dumb/Button'
import {ModalContainer} from '@/components/containers'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import translations from './translations'
import {ConfirmationModalProps} from './props'
import {CANCEL_CONFIRMATION_BUTTON, SUBMIT_CONFIRM_EMAIL} from '@/constants/playwright-ids'

const {Text} = Typography
const {useBreakpoint} = Grid

export const ConfirmationModal = ({children, modalStoreSymbol, open}: ConfirmationModalProps) => {
    const screens = useBreakpoint()
    const isMobile = screens.xs
    const store = useInjection<TModalContainerStore>(modalStoreSymbol)

    return (
        <ModalContainer
            injectableIdentifier={modalStoreSymbol}
            open={open}
            title={translations().chargeConfirmation}
            footer={[
                !isMobile && (
                    <Button
                        role={'cancel-button'}
                        ghost
                        key={'cancel'}
                        onClick={store?.onCancel}
                        data-test-id={CANCEL_CONFIRMATION_BUTTON}
                    >
                        {translations().cancel}
                    </Button>
                ),
                <Button
                    type={'primary'}
                    key={'charge'}
                    onClick={store?.onCancel}
                    data-test-id={SUBMIT_CONFIRM_EMAIL}
                >
                    {translations().confirm}
                </Button>
            ]}
        >
            <Text>{translations().confirmationText(children)}</Text>
        </ModalContainer>
    )
}
