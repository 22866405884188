import {FileType} from '@/constants'

export const GoogleManagerTagClassNames = {
    header: {
        createPaymentLink: 'create-payment-link-button',
        newPaymentLink: 'new-payment-link-button',
        mobileAppStoreAdSliderCloseIcon: 'mobile-app-store-ad-slider-close-icon',
        redirectToMobileAppStoreGetButton: 'redirect-to-mobile-app-store-get-button',
        redirectToMobileAppStoreGetTheAppButton: 'redirect-to-mobile-app-store-get-the-app-button'
    },
    settlements: {
        downloadButtons: (type: FileType) => {
            switch (type) {
                case FileType.PDF:
                    return 'settlements-download-pdf'
                case FileType.EXCEL:
                    return 'settlements-download-excel'
                case FileType.CSV:
                    return 'settlements-download-csv'
            }
        }
    },
    posPayments: {
        exportButton: 'pos-payments-export-button'
    },
    onlinePayments: {
        exportButton: 'online-payments-export-button'
    },
    settings: {
        bankAccounts: {
            addNewBankAccount: {
                addButton: 'settings-bank-accounts-add-new-bank-account-button',
                startVerification:
                    'settings-bank-accounts-add-new-bank-account-start-verification-button',
                proceedToOutlets:
                    'settings-bank-accounts-add-new-bank-account-proceed-to-outlets-button',
                contactSupport:
                    'settings-bank-accounts-add-new-bank-account-contact-support-button',
                initialFormCancel:
                    'settings-bank-accounts-add-new-bank-account-initial-form-cancel-button',
                successCancel: 'settings-bank-accounts-add-new-bank-account-success-cancel-button',
                failureCancel: 'settings-bank-accounts-add-new-bank-account-failure-cancel-button'
            }
        },
        outlets: {
            changeBankDetails: {
                changeDirectDebitButton:
                    'settings-outlets-change-bank-details-change-direct-debit-button',
                changeSettlementsButton:
                    'settings-outlets-change-bank-details-change-settlements-button',
                settlements: {
                    confirmChange:
                        'settings-outlets-change-bank-details-settlements-confirm-change',
                    cancel: 'settings-outlets-change-bank-details-settlements-cancel',
                    modal: {
                        confirm:
                            'settings-outlets-change-bank-details-settlements-confirm-change-modal-confirm',
                        cancel: 'settings-outlets-change-bank-details-settlements-confirm-change-modal-cancel'
                    }
                },
                directDebit: {
                    confirmChange:
                        'settings-outlets-change-bank-details-direct-debit-confirm-change',
                    cancel: 'settings-outlets-change-bank-details-direct-debit-cancel',
                    modal: {
                        confirm:
                            'settings-outlets-change-bank-details-direct-debit-confirm-change-modal-confirm',
                        cancel: 'settings-outlets-change-bank-details-direct-debit-confirm-change-modal-cancel'
                    }
                },
                all: {
                    confirmChange: 'settings-outlets-change-bank-details-all-confirm-change',
                    cancel: 'settings-outlets-change-bank-details-all-cancel',
                    modal: {
                        confirm:
                            'settings-outlets-change-bank-details-all-confirm-change-modal-confirm',
                        cancel: 'settings-outlets-change-bank-details-all-confirm-change-modal-cancel'
                    }
                }
            }
        }
    },
    zashEpos: {
        upgradeNowButton: 'zash_epos_upgrade_now_button',
        takeMeToZashButton: 'zash_epos_take_me_to_zash_button',
        learnAboutZashButton: 'zash_epos_learn_about_zash_button'
    }
}
