import React from 'react'
import translations from './translations'

translations.add('ro', {
    labels: {
        noAssociatedSettlementsOutlets: 'Nu este utilizat pentru decontări',
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount:
            'Sunteți pe cale să actualizați contul bancar pentru decontări (/debit direct). Vă rugăm\nsă revizuiți detaliile de mai jos:',
        youAreAboutToUpdateYourSettlementBankAccount:
            'Sunteți pe cale să actualizați contul bancar pentru decontări. Vă rugăm să revizuiți detaliile de mai jos',
        youAreAboutToUpdateYourDirectDebitBankAccount:
            'Sunteți pe cale să actualizați contul bancar pentru debit direct. Vă rugăm să revizuiți detaliile de mai jos',
        currentBankAccountDetails: 'Detalii actuale ale contului bancar:',
        newBankAccountDetails: 'Detalii noi ale contului bancar:',
        byConfirmingYouAuthoriseChange: 'Confirmând, autorizați această modificare.',
        bankAccountChangeSubmitted:
            'Cererea de schimbare a contului bancar a fost trimisă cu succes.',
        accountWillBeUpdatedForOutlets:
            'Contul bancar pentru decontări debit direct va fi actualizat pentru toate punctele asociate.',
        accountWillBeUpdatedForSettlementOutlets:
            'Contul de decontări va fi actualizat pentru toate punctele asociate.',
        accountWillBeUpdatedForDirectDebitOutlets:
            'Contul de debit direct va fi actualizat pentru toate punctele asociate.',
        couldNotUpdateBankAccount: 'Nu am putut actualiza contul bancar.',
        nextSteps: 'Pașii următori:',
        tryAgain: 'Vă rugăm să încercați din nou;',
        contactSupport:
            'Dacă problema persistă, contactați echipa noastră de suport pentru asistență.',
        pleaseSelectBankAccount: 'Vă rugăm să selectați un cont bancar',
        pleasePaymentMethod: 'Vă rugăm să selectați o opțiune',
        applySettlementPayments: 'Aplicați acest cont pentru plățile de decontări?',
        applyDirectDebitPayments: 'Aplicați acest cont pentru plățile de debit direct?',
        selectOption: 'Selectați o opțiune',
        useBothPayments: 'Utilizați acest cont pentru ambele, decontări și plăți prin debit direct',
        useAccountTypeOnly: 'Utilizați acest cont doar pentru {accountType}',
        allAccountChangeAlert: () => (
            <>
                Contul de decontări și debit direct va fi actualizat pentru toate punctele asociate.
                <ul>
                    <li>Decontările vor fi trimise către noul cont bancar.</li>
                    <li>Plățile prin debit direct vor fi acum colectate din acest cont.</li>
                </ul>
            </>
        ),
        settlementAccountChangeAlert:
            'Contul de decontări va fi schimbat pentru toate punctele asociate.\nPlățile de decontări după actualizare vor fi trimise către noul cont bancar.',
        directDebitAccountChangeAlert:
            'Contul de debit direct va fi schimbat pentru toate punctele asociate.',
        associatedOutlets: 'Puncte asociate',
        accountNumber: 'Număr cont',
        sortCode: 'Cod de sortare',
        selectBankAccount: 'Selectați contul bancar listat în "Conturi bancare"',
        noBankAccount: 'Nu ați adăugat încă un nou cont bancar',
        addBankAccountInstructions:
            'Vă rugăm să accesați Setări > Conturi bancare și să adăugați un nou cont pentru a continua',
        directDebit: 'Debit Direct'
    },
    buttons: {
        confirm: 'Confirmă',
        cancel: 'Anulează',
        ok: 'Ok',
        confirmChange: 'Confirmă schimbarea'
    },
    titles: {
        bankChangeConfirmation: 'Confirmare schimbare cont bancar',
        bankChangeFailed: 'Schimbare cont bancar eșuată',
        changeSettlementAccount: 'Schimbă contul bancar pentru decontări',
        changeDirectDebitAccount: 'Schimbă contul bancar pentru debit direct',
        changeTo: 'Schimbă în'
    }
})
