import React from 'react'
import { PageHeader as AntPageHeader } from '@ant-design/pro-layout'
import classNames from 'classnames'
import { PageHeaderProps } from './props'
import styles from './styles.scss'
import {useScreenType} from '@/hooks'

export const PageHeader: React.FC<PageHeaderProps> =
    ({border = 'bottom', noMargin, ...rest}) => {
        const { isMobile } = useScreenType()
        return <div className={classNames(styles.pageHeader, noMargin && styles.noMargin, {[styles.pageHeaderMobile]: isMobile})}>
            <AntPageHeader {...rest} />
            { !isMobile && border === 'bottom' && <div className={styles.bottomBorder}/>}
        </div>
    }
