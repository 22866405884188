import React from 'react'
import {observer} from 'mobx-react'
import {Avatar, Card as AntCard, Descriptions, Typography} from 'antd'
import {RightOutlined} from '@ant-design/icons'
import classnames from 'classnames'

import {useScreenType} from '@/hooks'
import {Card, Status} from '@/components'

import {AccountNumberView, AccountTypeView} from '@/pages/BankAccounts/components'
import {GenericBank} from '~/assets/icons'
import {formatSortCode} from '@/services/utils'
import {TagType} from '@/components/dumb/Tag/props'
import {DossierBankAccountType} from '@/stores/bank-accounts'
import {GoogleManagerTagClassNames} from '@/constants'
import { BANK_ACCOUNT_CARD } from '@/constants/playwright-ids'

import translations from './translations'
import {BankAccountCardProps} from './props'
import styles from './BankAccountCard.scss'

export const BankAccountCard: React.FC<BankAccountCardProps> = observer(
    ({
        bank,
        accountName,
        accountNumber,
        associatedSettlementsOutlets,
        accountTypes,
        sortCode,
        logo,
        onClick,
        onChangeBankDetailsClick,
        isSettlements,
        isDirectDebit,
        loading,
        className,
        hasSettlementBlocks,
        hasAllBlocks,
        hasDirectDebitBlocks,
        hasFullPermissions,
        hasChangeBankDetails = false
    }) => {
        const {isMobile} = useScreenType()

        const type: DossierBankAccountType = isSettlements ? 'settlements' : 'direct-debit'

        const renderChangeBankDetailsButton = (text: string, tagClassName: string) => {
            return (
                <Status
                    className={styles.changeBankDetails}
                    tagClassName={tagClassName}
                    key={'changeBankDetails'}
                    status={text}
                    type={TagType.Processing}
                    iconType={'settings'}
                    clickable
                    onClick={() => onChangeBankDetailsClick(type)}
                />
            )
        }

        const renderWaitingStatus = () => {
            return (
                <Status
                    className={styles.changeBankDetails}
                    key={'changeInProgress'}
                    status={translations().labels.changeInProgress}
                    type={TagType.Default}
                    iconType={'clock'}
                />
            )
        }

        return (
            <Card
                loading={loading}
                hoverable={!!onClick}
                className={classnames(styles.card, className)}
                onClick={onClick && onClick}
                data-test-id={BANK_ACCOUNT_CARD}>
                {isMobile && <RightOutlined className={styles.rightIcon} />}
                <AntCard.Meta
                    avatar={<Avatar className={styles.avatar} src={logo || <GenericBank />} />}
                    title={accountName}
                    description={
                        <>
                            <Descriptions
                                className={styles.descriptionLabel}
                                title=''
                                layout={'vertical'}
                                column={1}
                            >
                                {bank && (
                                    <Descriptions.Item label={translations().labels.bank}>
                                        {bank}
                                    </Descriptions.Item>
                                )}
                                {sortCode && (
                                    <Descriptions.Item label={translations().labels.sortCode}>
                                        {formatSortCode(sortCode)}
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label={translations().labels.accountNumber}>
                                    <AccountNumberView accountNumber={accountNumber} />
                                </Descriptions.Item>
                                {associatedSettlementsOutlets && (
                                    <Descriptions.Item
                                        label={translations().labels.associatedOutlets}
                                    >
                                        {associatedSettlementsOutlets.length > 0 ? (
                                            <Typography.Text className={styles.truncate}>
                                                {associatedSettlementsOutlets
                                                    .map((outlet) => outlet.trim())
                                                    .join(', ')}
                                            </Typography.Text>
                                        ) : (
                                            <Typography.Text type={'secondary'}>
                                                {translations().labels.noAssociatedOutlets}
                                            </Typography.Text>
                                        )}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            {accountTypes && <AccountTypeView accountTypes={accountTypes} />}
                            {hasFullPermissions &&
                                hasChangeBankDetails &&
                                isSettlements &&
                                !(hasSettlementBlocks || hasAllBlocks) &&
                                renderChangeBankDetailsButton(
                                    translations().labels.changeSettlementsBankDetails,
                                    GoogleManagerTagClassNames.settings.outlets.changeBankDetails
                                        .changeSettlementsButton
                                )}
                            {hasFullPermissions &&
                                hasChangeBankDetails &&
                                isDirectDebit &&
                                !(hasDirectDebitBlocks || hasAllBlocks) &&
                                renderChangeBankDetailsButton(
                                    translations().labels.changeDirectDebitBankDetails,
                                    GoogleManagerTagClassNames.settings.outlets.changeBankDetails
                                        .changeDirectDebitButton
                                )}
                            {isSettlements &&
                                (hasSettlementBlocks || hasAllBlocks) &&
                                renderWaitingStatus()}
                            {isDirectDebit &&
                                (hasDirectDebitBlocks || hasAllBlocks) &&
                                renderWaitingStatus()}
                        </>
                    }
                />
            </Card>
        )
    }
)
