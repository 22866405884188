import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {inject, injectable} from 'inversify'
import {CallBackProps} from 'react-joyride'

import {TUserGuideStore} from '@/components/containers/UserGuideContainer/TUserGuideStore'
import {getSteps} from '@/stores/user-guide/services/utils'
import {UserGuideStep} from '@/stores/user-guide/models/UserGuideStep'
import {AuthStoreInterface, AuthStoreSymbol, VirtualTerminalStoreSymbol} from '@/stores'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import {updateUserGuideReadStatus} from '@/stores/user-guide/services/fetchers'
import {UpdateUserGuiderRequest} from '@/stores/user-guide/models/UpdateUserGuiderRequest'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {LoadingState} from '@/types'
import {TVirtualTerminalStore} from '@/pages/VirtualTerminal'

@injectable()
export class UserGuideStore implements TUserGuideStore {
    private _authStore: AuthStoreInterface
    private _profileStore: ProfileStoreInterface
    private _virtualTerminalStore: TVirtualTerminalStore

    constructor(
        @inject(AuthStoreSymbol) authStore: AuthStoreInterface,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface,
        @inject(VirtualTerminalStoreSymbol) virtualTerminalStore: TVirtualTerminalStore
    ) {
        this._authStore = authStore
        this._profileStore = profileStore
        this._virtualTerminalStore = virtualTerminalStore

        makeAutoObservable(this)

        reaction(
            () => this._virtualTerminalStore.isVirtualTerminalsLoaded,
            () =>
                (this.steps = getSteps(
                    this._virtualTerminalStore.isVirtualTerminalAvailable,
                    this.onSkipPress
                ))
        )

        reaction(
            () => ({
                permissions: this._authStore.permissions
            }),
            () => {
                this.steps = getSteps(
                    this._virtualTerminalStore.isVirtualTerminalAvailable,
                    this.onSkipPress
                )
            }
        )

        reaction(
            () => ({
                guidePassed: this._profileStore?.portalGuideViewed,
                loadingState: this._profileStore?.loadingState
            }),
            ({guidePassed, loadingState}) => {
                if (!!!guidePassed && loadingState === LoadingState.DONE) {
                    runInAction(() => {
                        this.isModalVisible = true
                    })
                } else {
                    runInAction(() => {
                        this.isModalVisible = false
                    })
                }
            }
        )
    }

    run = false
    tourActive = false
    isModalVisible = false
    stepIndex = 0

    setRun = (run: boolean) => {
        this.run = run
    }

    setStepIndex = (stepIndex: number) => {
        this.stepIndex = stepIndex
    }

    handleCallback = async (data: CallBackProps) => {
        const {action, index, lifecycle, type} = data

        const nextStep = this.steps[index + 1]

        const goNextStep = () => {
            if (nextStep) {
                setTimeout(() => {
                    this.setStepIndex(nextStep.step)
                }, 300)
                if (nextStep.link) {
                    getRouterStore().push(nextStep.link)
                }
            }
        }

        if (type === 'step:after' && index !== this.steps?.length - 1) {
            if (action === 'next') {
                //setTimeout fixes the issue where the step card was displayed in the wrong position
                setTimeout(() => goNextStep(), 0)
            }
        } else if (index === this.steps?.length - 1 && lifecycle === 'complete') {
            await this.onSkipPress()
            getRouterStore().push(ROUTES.home)
        }
    }

    setTourActive = (tourActive: boolean) => {
        this.tourActive = tourActive
    }

    setIsModalVisible = (isModalVisible: boolean) => {
        this.isModalVisible = isModalVisible
    }

    onGotItClick = () => {
        this.setIsModalVisible(false)
        this.setRun(true)
    }

    onSkipPress = async () => {
        const request: UpdateUserGuiderRequest = {
            portalGuideViewed: true,
            merchantId: this._profileStore?.profileData?.merchantId
        }

        await updateUserGuideReadStatus(request)

        this.setIsModalVisible(false)
        this.setRun(false)
        this.setTourActive(false)
        this.setStepIndex(0)
    }

    steps: UserGuideStep[] = []
}
