import {Form} from 'antd'
import React from 'react'
import {Button} from '@/components'
import translations from './translations'
import {POSFilterFooterProps} from './props'
import styles from './styles.scss'
import {APPLY_FILTER, CLOSE_FILTER} from '@/constants/playwright-ids'

export const FilterFooter: React.FC<POSFilterFooterProps> = ({formId, onClose}) => {
    return (
        <>
            <Form.Item className={styles.footer}>
                <Button
                    onClick={onClose}
                    className={styles.close}
                    htmlType='button'
                    data-test-id={CLOSE_FILTER}
                    ghost
                >
                    {translations().close}
                </Button>
                <Button type='primary' form={formId} htmlType='submit' data-test-id={APPLY_FILTER}>
                    {translations().apply}
                </Button>
            </Form.Item>
        </>
    )
}
