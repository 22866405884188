import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('en', {
    columns: {
        paymentMethod: 'Payment method',
        transactionDate: 'Transaction date',
        settlementDate: 'Settlement date',
        address: 'Address',
        postcode: 'Postcode',
        mid: 'MID',
        terminalNumber: 'Terminal',
        amount: 'Amount',
        acquirerFee: 'Acquirer fee',
        totalFee: 'Total fee',
        interchangeFee: 'Interchange fee',
        schemeFee: 'Scheme fee',
        processingFee: 'Processing fee',
        gatewayFee: 'Gateway fee',
        authorisationFee: 'Authorisation fee',
        blendedFee: 'Blended fee',
        refundFee: 'Refund fee',
        amountToSettle: 'Payable',
        sortCode: 'Sort code',
        accountNumber: 'Account number',
        operation: 'Operation',
        transactionType: 'Transaction type',
        transactionId: 'Transaction ID',
        merchantReference: 'Merchant reference',
        cardScheme: 'Card scheme',
        cardMask: 'Card mask',
        cardholderName: 'Cardholder name',
        cardType: 'Card type',
        isEuropeanCard: 'European card',
        issuerRegion: 'Issuer region',
        issuerCountry: 'Issuer country',
        isCorporateCard: 'Corporate card',
        captureMethod: 'Capture method',
        settlementType: 'Settlement type'
    },
    columnsPerDay: {
        transactionDate: 'Transaction date',
        count: 'Number',
        amount: 'Amount',
        settlementType: 'Settlement type',
        totalFee: 'Total fee',
        amountToMerchant: 'Payable',
        settlementDate: 'Settlement date'
    },
    captureMethod: {
        posContactless: 'POS Contactless'
    },
    columnValues: {
        european: 'European',
        nonEuropean: 'non-European',
        corporate: 'Corporate',
        nonCorporate: 'non-Corporate',
        today: 'Today'
    },
    messages: {
        successExportMessage: (
            <span>
                Your download request has been queued for processing. Once the document is ready, it
                can be downloaded from the <Link to={ROUTES.exports}>Exports</Link> section.
            </span>
        )
    }
})
