import {useEffect} from 'react'
import {useMap} from 'react-leaflet'
import {LatLngExpression} from 'leaflet'

export const MapCenterUpdater = ({center}: {center: LatLngExpression}) => {
    const map = useMap()

    useEffect(() => {
        if (center) {
            map.setView(center, map.getZoom(), {animate: true})
        }
    }, [center, map])

    return null
}
