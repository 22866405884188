import React from 'react'
import {observer} from 'mobx-react'
import {Button, Input, Modal, ModalFormFooter} from '@/components'
import {Col, Form, Row, Typography} from 'antd'
import {urls} from '@/constants'
import {AddNewDomainModalProps} from './props'
import translations from './translations'
import styles from './styles.scss'
import {DOMAIN_INPUT} from '@/constants/playwright-ids'

export const AddNewDomainModal: React.FC<AddNewDomainModalProps> = observer((
    {open, onOk, onCancel, confirmLoading, shouldShowDownloadStep}
) => {
    const [form] = Form.useForm()

    const onFinish = async (values) => {
        await onOk(values)
        form.resetFields()
    }
    return (
        <Modal
            title={translations().title}
            open={open}
            onCancel={onCancel}
            footer={<ModalFormFooter
                formId={'add-new-domain'}
                onCancel={onCancel}
                cancelText={translations().cancel}
                confirmLoading={confirmLoading}
                okText={translations().add}
            />}
        >
            <Form
                form={form}
                id={'add-new-domain'}
                layout={'vertical'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Row gutter={[16, 16]}>
                    <Col span={2}>
                        <Typography.Title level={3}>1.</Typography.Title>
                    </Col>
                    <Col span={22}>
                        <Typography.Title level={4}>
                            {translations().provideDomainWhereTheFile}
                        </Typography.Title>
                        <Typography.Text>
                            {translations().inputTheTopLevelDomain}
                        </Typography.Text>
                        <br/><br/>
                        <Form.Item
                            name={'domain'}
                            rules={[
                                { required: true, message: translations().errors.domainRequired }
                            ]}
                        >
                            <Input
                                data-test-id={DOMAIN_INPUT}
                                className={styles.linkInput}
                                prefix={'https://'}
                                placeholder={'example.com'}
                            />
                        </Form.Item>
                    </Col>
                    {
                        shouldShowDownloadStep && (
                            <>
                                <Col span={2}>
                                    <Typography.Title level={3}>2.</Typography.Title>
                                </Col>
                                <Col span={22}>
                                    <Button
                                        onClick={() => window.open(urls.domainAssociationFile, '_blank')}
                                        ghost
                                    >
                                        {translations().downloadDomainAssociationFile}
                                    </Button>
                                </Col>
                                <Col span={2}>
                                    <Typography.Title level={3}>3.</Typography.Title>
                                </Col>
                                <Col span={22}>
                                    <Typography.Title level={4}>
                                        {translations().hostDownloadedDomainAssociationFile}
                                    </Typography.Title>
                                    <Typography.Text>
                                        {translations().youNeedToHostVerificationFileYouDownloaded}
                                    </Typography.Text>
                                    <br/><br/>
                                    <Typography.Text>
                                        https://example.com/.well-known/apple-developer-merchantid-domain-association
                                    </Typography.Text>
                                </Col>
                            </>
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
})
