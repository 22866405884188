import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction} from 'mobx'

import {IBankAccountCreateStore} from '@/pages/BankAccountCreate'
import {
    AddBankAccountRequest,
    CreateBankAccountStep,
    ValidationResult
} from '@/stores/bank-account-create/models'
import {
    addBankAccount,
    validateBankAccountDetails
} from '@/stores/bank-account-create/services/fetchers'
import {
    DossierBusinessStructureEnum,
    DossierMerchantCompanyInfo,
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'
import {LoadingState} from '@/types'
import {getRouterStore} from '@/router/utils'
import {ROUTES} from '@/router/routes'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {SendbirdChatContainerStoreSymbol} from '@/components'
import {SendbirdChatStore} from '@/stores'
import {ProfileStoreSymbol} from '@/pages/Profile'
import {ProfileStore} from '@/stores/profile/ProfileStore'
import {POSOutletsStoreSymbol} from '@/pages/POSOutlets/POSOutlets'
import {OutletModel, OutletsStore} from '@/stores/outlets'
import {OnlineOutletsStoreSymbol} from '@/pages/OnlineOutlets/OnlineOutlets'
import {BankAccountsStoreSymbol} from '@/pages/BankAccounts/BankAccounts'
import {BankAccountsStore} from '@/stores/bank-accounts'

@injectable()
export class BankAccountCreateStore implements IBankAccountCreateStore {
    private dossierMerchantInfoStore: DossierMerchantInfoStore
    loadingState: LoadingState = LoadingState.IDLE
    validationState: LoadingState = LoadingState.IDLE
    step = CreateBankAccountStep.initialForm
    sendbirdChatStore: SendbirdChatStore
    profileStore: ProfileStore
    posOutletsStore: OutletsStore
    onlineOutletsStore: OutletsStore
    bankAccountsStore: BankAccountsStore

    constructor(
        @inject(DossierMerchantInfoStoreSymbol) dossierMerchantInfoStore: DossierMerchantInfoStore,
        @inject(SendbirdChatContainerStoreSymbol) sendbirdChatStore: SendbirdChatStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStore,
        @inject(POSOutletsStoreSymbol) posOutletsStore: OutletsStore,
        @inject(OnlineOutletsStoreSymbol) onlineOutletsStore: OutletsStore,
        @inject(BankAccountsStoreSymbol) bankAccountsStore: BankAccountsStore
    ) {
        makeObservable(this, {
            step: observable,
            loadingState: observable,
            validationState: observable,

            dossierData: computed,
            isLoading: computed,
            isValidationPassed: computed,
            isValidating: computed,
            isSoleTrader: computed,
            isCompany: computed,
            isAllowedToToggleAccountType: computed,

            addBankAccount: action,
            resetStore: action,
            onBack: action,
            onSuccessActionButtonClick: action,
            setStep: action,
            waitForOutletsLoading: action
        })

        this.dossierMerchantInfoStore = dossierMerchantInfoStore
        this.sendbirdChatStore = sendbirdChatStore
        this.profileStore = profileStore
        this.posOutletsStore = posOutletsStore
        this.onlineOutletsStore = onlineOutletsStore
        this.bankAccountsStore = bankAccountsStore
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    get isValidating(): boolean {
        return this.validationState === LoadingState.LOADING
    }

    get isValidationPassed(): boolean {
        return this.validationState !== LoadingState.FAILED
    }

    get dossierData(): DossierMerchantCompanyInfo {
        return this.dossierMerchantInfoStore.dossierMerchantData?.mainInfo
    }

    get isSoleTrader(): boolean {
        return (
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.soleTrader ||
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.charity ||
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.partnership ||
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.other
        )
    }

    get isAllowedToToggleAccountType(): boolean {
        return this.dossierData?.businessStructure === DossierBusinessStructureEnum.soleTrader
    }

    get isCompany(): boolean {
        return (
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.company ||
            this.dossierData?.businessStructure === DossierBusinessStructureEnum.llp
        )
    }

    addBankAccount = async (data: AddBankAccountRequest) => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        this.loadingState = LoadingState.LOADING

        const bankAccountData: AddBankAccountRequest = {
            initiator: this.profileStore.profileData?.email,
            dossierId: this.dossierData?.dsrId.toString(),
            bankName: null,
            bankAccountType: data.bankAccountType,
            bankAccountName: data.bankAccountName,
            bankAccountNumber: data.bankAccountNumber,
            sortCode: data.sortCode
        }

        try {
            const result = await addBankAccount(bankAccountData)

            if (result.status === 200) {
                this.loadingState = LoadingState.DONE
                this.setStep(CreateBankAccountStep.success)
            } else {
                this.loadingState = LoadingState.FAILED
                this.setStep(CreateBankAccountStep.failure)
            }
        } catch (error) {
            this.loadingState = LoadingState.FAILED
            console.error('Error adding bank account:', error)
        }
    }

    validateBankAccount = async (
        bankAccountNumber: string,
        sortCode: string
    ): Promise<ValidationResult> => {
        try {
            this.validationState = LoadingState.LOADING

            const response = await validateBankAccountDetails({
                bankAccountNumber,
                sortCode
            })

            if (response.status === 200) {
                this.validationState = LoadingState.DONE
            } else {
                this.validationState = LoadingState.FAILED
                throw new Error(response?.error?.message)
            }

            return response.result
        } catch (error) {
            this.validationState = LoadingState.FAILED
            return {
                isValid: false,
                warnings: ['']
            }
        }
    }

    setStep = (step: CreateBankAccountStep) => {
        this.step = step
    }

    resetStore = () => {
        this.loadingState = LoadingState.IDLE
        this.validationState = LoadingState.IDLE
        this.setStep(CreateBankAccountStep.initialForm)
    }

    onBack = () => {
        this.resetStore()
        getRouterStore().back()
    }

    onSuccessActionButtonClick = async () => {
        this.resetStore()

        await Promise.all([
            this.waitForOutletsLoading(this.posOutletsStore),
            this.waitForOutletsLoading(this.onlineOutletsStore),
            this.bankAccountsStore.loadDossierBankAccounts()
        ])
        if (
            hasPermissions([
                PermissionsMap.merchant.pos_stores.read,
                PermissionsMap.merchant.pos_stores.full
            ]) &&
            this.posOutletsStore?.outlets?.length > 0
        ) {
            getRouterStore().push(ROUTES.settings.posOutlets)
            return
        }

        if (
            hasPermissions([
                PermissionsMap.merchant.ecom_stores.read,
                PermissionsMap.merchant.ecom_stores.full
            ]) &&
            this.onlineOutletsStore?.outlets?.length > 0
        ) {
            getRouterStore().push(ROUTES.settings.onlineOutlets)
            return
        }

        getRouterStore().push(ROUTES.settings.base)
    }

    waitForOutletsLoading = async (store: {outlets: OutletModel[]; loadingState: LoadingState}) => {
        if (!store) return

        if (store.outlets && store.loadingState !== LoadingState.LOADING) {
            return
        }

        return new Promise<void>((resolve) => {
            const disposer = reaction(
                () => store.loadingState,
                (loadingState) => {
                    if (loadingState !== LoadingState.LOADING) {
                        disposer()
                        resolve()
                    }
                },
                {fireImmediately: true}
            )
        })
    }

    openChat = () => {
        this.sendbirdChatStore.setOpen(true)
    }
}
