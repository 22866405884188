import React from 'react'
import {notificationDescription} from '@/utils'
import blockNotificationImage from '~/assets/img/notifications/block.png'
import logo from '~/assets/img/logos/dna_payments_logo_dark.svg'
import {BlockNotificationProps} from './props'
import styles from './styles.scss'

export const BlockNotification: React.FC<BlockNotificationProps> = ({notification}) => {
    const {description} = notification || {}
    const renderContent = (notification: {title: string, description: string} = {title: '', description: ''}) => {
        return notification && (
            <div className={styles.notification}>
                <div className={styles.notificationTitle}>{notification.title}</div>
                <div className={styles.notificationDescription} dangerouslySetInnerHTML={{__html: notificationDescription(description)}} />
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.contentWrapper}>
                <div className={styles.notificationWrapper}>
                    <img className={styles.logo} src={logo} alt="logo"/>
                    {renderContent(notification)}
                </div>
                <img className={styles.previewImg} src={blockNotificationImage} alt="block preview"/>
            </div>
        </div>
    )
}
