import React from 'react'
import translations from './translations'

translations.add('el', {
    labels: {
        noAssociatedSettlementsOutlets: 'Δεν χρησιμοποιείται για διακανονισμούς',
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount:
            'Πρόκειται να ενημερώσετε τον τραπεζικό σας λογαριασμό για διακανονισμούς (/άμεση χρέωση). Παρακαλώ\nελέγξτε τις παρακάτω λεπτομέρειες:',
        youAreAboutToUpdateYourSettlementBankAccount:
            'Πρόκειται να ενημερώσετε τον τραπεζικό σας λογαριασμό διακανονισμών. Παρακαλώ ελέγξτε τις παρακάτω λεπτομέρειες',
        youAreAboutToUpdateYourDirectDebitBankAccount:
            'Πρόκειται να ενημερώσετε τον τραπεζικό σας λογαριασμό άμεσης χρέωσης. Παρακαλώ ελέγξτε τις παρακάτω λεπτομέρειες',
        currentBankAccountDetails: 'Τρέχοντα στοιχεία τραπεζικού λογαριασμού:',
        newBankAccountDetails: 'Νέα στοιχεία τραπεζικού λογαριασμού:',
        byConfirmingYouAuthoriseChange: 'Με την επιβεβαίωση, εξουσιοδοτείτε αυτήν την αλλαγή.',
        bankAccountChangeSubmitted:
            'Το αίτημά σας για αλλαγή τραπεζικού λογαριασμού εστάλη επιτυχώς.',
        accountWillBeUpdatedForOutlets:
            'Ο λογαριασμός διακανονισμών (/άμεσης χρέωσης) θα ενημερωθεί για όλα τα συνδεδεμένα καταστήματα.',
        accountWillBeUpdatedForSettlementOutlets:
            'Ο λογαριασμός διακανονισμών θα ενημερωθεί για όλα τα συνδεδεμένα καταστήματα.',
        accountWillBeUpdatedForDirectDebitOutlets:
            'Ο λογαριασμός άμεσης χρέωσης θα ενημερωθεί για όλα τα συνδεδεμένα καταστήματα.',
        couldNotUpdateBankAccount: 'Δεν καταφέραμε να ενημερώσουμε τον τραπεζικό σας λογαριασμό.',
        nextSteps: 'Επόμενα βήματα:',
        tryAgain: 'Παρακαλώ δοκιμάστε ξανά;',
        contactSupport:
            'Εάν το πρόβλημα παραμένει, επικοινωνήστε με την Ομάδα Υποστήριξης για βοήθεια.',
        pleaseSelectBankAccount: 'Παρακαλώ επιλέξτε τραπεζικό λογαριασμό',
        pleasePaymentMethod: 'Παρακαλώ επιλέξτε μια επιλογή',
        applySettlementPayments: 'Να εφαρμοστεί αυτός ο λογαριασμός για πληρωμές διακανονισμών;',
        applyDirectDebitPayments: 'Να εφαρμοστεί αυτός ο λογαριασμός για πληρωμές άμεσης χρέωσης',
        selectOption: 'Επιλέξτε μία επιλογή',
        useBothPayments:
            'Χρησιμοποιήστε αυτόν τον λογαριασμό για πληρωμές διακανονισμών και άμεσης χρέωσης',
        useAccountTypeOnly: 'Χρησιμοποιήστε αυτόν τον λογαριασμό μόνο για {accountType}',
        allAccountChangeAlert: () => (
            <>
                Ο λογαριασμός διακανονισμών και άμεσης χρέωσης θα ενημερωθεί για όλα τα συνδεδεμένα
                καταστήματα.
                <ul>
                    <li>Οι διακανονισμοί θα αποστέλλονται στο νέο τραπεζικό λογαριασμό.</li>
                    <li>
                        Οι πληρωμές άμεσης χρέωσης θα συλλέγονται πλέον από αυτόν τον λογαριασμό.
                    </li>
                </ul>
            </>
        ),

        settlementAccountChangeAlert:
            'Ο λογαριασμός διακανονισμών θα αλλάξει για όλα τα συνδεδεμένα καταστήματα.\nΟι διακανονισμοί μετά την ενημέρωση θα σταλούν στο νέο τραπεζικό λογαριασμό.',
        directDebitAccountChangeAlert:
            'Ο λογαριασμός άμεσης χρέωσης θα αλλάξει για όλα τα συνδεδεμένα καταστήματα.',
        associatedOutlets: 'Συνδεδεμένα καταστήματα',
        accountNumber: 'Αριθμός λογαριασμού',
        sortCode: 'Κωδικός ταξινόμησης',
        selectBankAccount:
            'Επιλέξτε τραπεζικό λογαριασμό που αναφέρεται στις "Τραπεζικοί λογαριασμοί"',

        noBankAccount: 'Δεν έχετε προσθέσει νέο τραπεζικό λογαριασμό ακόμα',
        addBankAccountInstructions:
            'Παρακαλώ μεταβείτε στις Ρυθμίσεις > Τραπεζικοί Λογαριασμοί και προσθέστε νέο τραπεζικό λογαριασμό για να προχωρήσετε',
        directDebit: 'άμεση χρέωση'
    },
    buttons: {
        confirm: 'Επιβεβαίωση',
        cancel: 'Ακύρωση',
        ok: 'Εντάξει',
        confirmChange: 'Επιβεβαιώστε την αλλαγή'
    },
    titles: {
        bankChangeConfirmation: 'Επιβεβαίωση αλλαγής τραπεζικού λογαριασμού',
        bankChangeFailed: 'Αποτυχία αλλαγής τραπεζικού λογαριασμού',
        changeSettlementAccount: 'Αλλαγή τραπεζικού λογαριασμού διακανονισμών',
        changeDirectDebitAccount: 'Αλλαγή τραπεζικού λογαριασμού άμεσης χρέωσης',
        changeTo: 'Αλλαγή σε'
    }
})
