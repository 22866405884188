import React, {useMemo} from 'react'
import {MenuProps} from 'antd/lib/menu'

import {BankAccountCard} from '@/pages/BankAccounts/components'
import {DossierBankAccount} from '@/stores/bank-accounts'

export const useBankAccountItems = (
    accounts: DossierBankAccount[],
    onChange: (account: DossierBankAccount) => void
): MenuProps['items'] => {
    return useMemo(
        () =>
            accounts.map((account) => ({
                key: account.bankDetail?.id || '',
                label: (
                    <div onClick={() => onChange(account)}>
                        <BankAccountCard
                            bank={account.bankDetail?.bankName || ''}
                            logo={account.bankDetail?.logo || ''}
                            sortCode={account.bankDetail?.sortCode || ''}
                            accountNumber={account.bankDetail?.bankAccountNumber || ''}
                        />
                    </div>
                )
            })),
        [accounts, onChange]
    )
}
