import React, {useEffect, useState} from 'react'
import moment from 'moment'
import classNames from 'classnames'
import {Typography, Button, Divider} from 'antd'
import DatePicker from '~/code/components/dumb/DatePicker/DatePicker'
import {observer} from 'mobx-react'
import {SERVER_DATE_FORMAT} from '@/constants'
import {Modal} from '@/components'
import {DownloadOutlined} from '@ant-design/icons'
import {useScreenType} from '@/hooks'
import {disabledDateForPdf, getLastFullMonth} from '../../utils'

import {SettlementsPdfDownloadModalProps} from './props'
import translations from './translations'
import styles from './styles.scss'
import { CANCEL_BUTTON, DOWNLOAD_BUTTON } from '@/constants/playwright-ids'

const DATE_NEW = moment({year: 2023, month: 7}) // 2023-08
const DATE_PERIOD_FORMAT = 'YYYY-MM'

export const SettlementsPdfDownloadModal = observer((props: SettlementsPdfDownloadModalProps) => {
    const {isOpen, onCancel, onSubmit} = props
    const {isMobile} = useScreenType()

    const [date, setDate] = useState(getLastFullMonth())
    const isOld = date.isBefore(DATE_NEW)

    const changeDate = (value: moment.Moment) => {
        if (disabledDateForPdf(value)) {
            setDate(getLastFullMonth)
        } else {
            setDate(value)
        }
    }

    const download = () => {
        onSubmit(
            isOld
                ? {
                      from: moment(date).startOf('month').format(SERVER_DATE_FORMAT),
                      to: moment(date).endOf('month').format(SERVER_DATE_FORMAT)
                  }
                : {period: date.format(DATE_PERIOD_FORMAT)}
        )
    }

    useEffect(() => {
        if (isOpen) {
            setDate(getLastFullMonth())
        }
    }, [isOpen, setDate])

    return (
        <Modal
            title={translations().title}
            width={'580px'}
            open={isOpen}
            footer={null}
            onCancel={onCancel}
        >
            <Typography.Text>{translations().text}</Typography.Text>

            <div className={classNames(styles.datepickers, {[styles.isMobile]: isMobile})}>
                <DatePicker
                    value={date}
                    picker='year'
                    disabledDate={disabledDateForPdf}
                    className={styles.yearDatePicker}
                    onChange={changeDate}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                />
                <DatePicker
                    value={date}
                    format='MMMM'
                    picker='month'
                    disabledDate={disabledDateForPdf}
                    className={styles.monthDatePicker}
                    popupClassName={styles.monthDatePickerPopup}
                    onChange={changeDate}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                />
            </div>

            <Divider />

            <div className={styles.buttons}>
                <Button type='primary' onClick={download} icon={<DownloadOutlined />} data-test-id={DOWNLOAD_BUTTON}>
                    {translations().download}
                </Button>
                <Button onClick={onCancel} data-test-id={CANCEL_BUTTON}> {translations().cancel}</Button>
            </div>
        </Modal>
    )
})
