import {Badge, Menu, MenuItemProps} from 'antd'
import React from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import {MenuDataItem} from '@/types'
import {isAbsoluteUrl} from '@/utils'
import {Icon} from '@/components/dumb/Icon'
import {SiderMenuProps} from './props'
import styles from './styles.scss'

export const SiderMenu: React.FC<SiderMenuProps> = props => {
    const {menuItems, selectedKeys, openKeys, onSelect, className} = props

    const renderMenuItems = (items: MenuDataItem[]) => {
        return items.map(
            item =>
                ({
                    key: item.path,
                    icon: item.icon,
                    title: item.title,
                    label: item.path ? (
                        <Link
                            className={item.className}
                            to={item.path || '#'}
                            target={isAbsoluteUrl(item.path) ? '_blank' : undefined}
                        >
                            {item.title}
                            {item.badge && <Badge.Ribbon text={item.badge} color='var(--color-primary)'/>}
                        </Link>                        
                    ) : item.title,
                    children:
                        item.children && item.children.length
                            ? renderMenuItems(item.children)
                            : null
                } as MenuItemProps)
        )
    }

    return (
        <Menu
            className={classNames(styles.menu, className)}
            selectedKeys={selectedKeys}
            defaultOpenKeys={openKeys}
            expandIcon={ExpandIcon}
            onSelect={onSelect}
            inlineIndent={0}
            mode={'inline'}
            items={renderMenuItems(menuItems)}
        />
    )
}

const ExpandIcon: React.FC<{isOpen: boolean}> = ({isOpen}) => {
    return isOpen ? <Icon type={'up_outlined'} /> : <Icon type={'down_outlined'} />
}
