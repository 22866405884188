import translations from './translations'

translations.add('el', {
    labels: {
        bank: 'Τράπεζα',
        sortCode: 'Κωδικός Ταξινόμησης',
        accountNumber: 'Αριθμός Λογαριασμού',
        associatedOutlets: 'Συνδεδεμένα Καταστήματα',
        changeSettlementsBankDetails: 'Αλλαγή λογαριασμού διακανονισμών',
        changeDirectDebitBankDetails: 'Αλλαγή λογαριασμού άμεσης χρέωσης',
        changeInProgress: 'Αλλαγή σε εξέλιξη',
        noAssociatedOutlets: 'Δεν χρησιμοποιείται για διακανονισμούς'
    }
})
