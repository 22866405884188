import translations from './translations'
import React from 'react'

translations.add('el', {
    bankAccountCreate: 'Προσθήκη νέου τραπεζικού λογαριασμού',
    infoTop: {
        title: 'Οδηγός:',
        topDescriptionSoleTrader: () => (
            <div>
                Προσωπικός ή Επιχειρηματικός Τρεχούμενος Λογαριασμός – Καταλληλός για διακανονισμούς
                και άμεσες χρεώσεις.
                <br />
                Προσωπικός ή Επιχειρηματικός Λογαριασμός Ταμιευτηρίου – Καταλληλός μόνο για
                διακανονισμούς.
            </div>
        ),
        topDescription: () => (
            <div>
                Επιχειρηματικός Τρεχούμενος Λογαριασμός – Καταλληλός για διακανονισμούς και άμεσες
                χρεώσεις. <br />
                Επιχειρηματικός Λογαριασμός Ταμιευτηρίου – Καταλληλός μόνο για διακανονισμούς.
            </div>
        ),
        description: () => (
            <div>
                Με την υποβολή των στοιχείων του τραπεζικού σας λογαριασμού, αναγνωρίζετε και
                συμφωνείτε ότι αυτός ο λογαριασμός θα χρησιμοποιηθεί για μελλοντικές συναλλαγές
                άμεσης χρέωσης, μόλις επαληθευτεί.
            </div>
        )
    },
    labels: {
        entityType: 'Τύπος οντότητας',
        legalName: 'Νομική ονομασία',
        accountType: 'Τύπος λογαριασμού',
        bankAccountNumber: 'Αριθμός τραπεζικού λογαριασμού',
        sortCode: 'Κωδικός ταξινόμησης',
        accountName: 'Όνομα λογαριασμού'
    },
    alerts: {
        validation: {
            checkDetails:
                'Παρακαλώ ελέγξτε τον κωδικό ταξινόμησης και τον αριθμό τραπεζικού λογαριασμού και δοκιμάστε ξανά'
        },
        verification: {
            title: 'Επαλήθευση τραπεζικών στοιχείων',
            results: 'Αποτελέσματα επαλήθευσης τραπεζικών στοιχείων',
            failed: {
                title: 'Δεν καταφέραμε να επαληθεύσουμε τα στοιχεία σας',
                description: () => (
                    <div>
                        Δυστυχώς, η αυτόματη επαλήθευση δεν ήταν επιτυχής. <br />
                        Παρακαλώ επικοινωνήστε με την Ομάδα Υποστήριξης μας για να προχωρήσετε.
                    </div>
                )
            },
            success: {
                title: 'Τέλεια, τα τραπεζικά σας στοιχεία επαληθεύτηκαν!',
                description:
                    'Τα νέα τραπεζικά σας στοιχεία προστέθηκαν στη λίστα των επαληθευμένων λογαριασμών.'
            }
        },
        nextSteps: {
            title: 'Επόμενα βήματα',
            changeAccount: {
                title: 'Βήματα για την αλλαγή των στοιχείων τραπεζικού λογαριασμού',
                steps: {
                    proceedToOutlets: 'Μεταβείτε στις Ρυθμίσεις - POS ή Διαδικτυακά Καταστήματα',
                    select: 'Κάντε κλικ στο Κατάστημα για να δείτε τις λεπτομέρειες του',
                    pressEdit:
                        'Πατήστε το κουμπί "Αλλαγή λογαριασμού άμεσης χρέωσης ή διακανονισμών" και ακολουθήστε τις οδηγίες'
                }
            }
        }
    },
    options: {
        business: 'Επιχειρηματικό',
        personal: 'Προσωπικό'
    },
    placeholders: {
        accountNumber: '-- -- -- -- --',
        sortCode: '-- -- --',
        accountName: 'Όνομα τραπεζικού λογαριασμού'
    },
    validation: {
        required: {
            accountNumber: 'Παρακαλώ εισάγετε τον αριθμό τραπεζικού λογαριασμού',
            sortCode: 'Παρακαλώ εισάγετε τον κωδικό ταξινόμησης',
            accountName: 'Παρακαλώ εισάγετε το όνομα του λογαριασμού'
        },
        format: {
            accountNumberTooShort: 'Ο αριθμός λογαριασμού είναι πολύ σύντομος',
            sortCodeFormat: 'Ο κωδικός ταξινόμησης πρέπει να αποτελείται από 6 ψηφία'
        }
    },
    buttons: {
        startVerification: 'Έναρξη επαλήθευσης',
        cancel: 'Ακύρωση',
        proceedToOutlets: 'Μεταβείτε στο τμήμα Καταστημάτων',
        close: 'Κλείσιμο',
        contactSupport: 'Επικοινωνήστε με την υποστήριξη'
    }
})
