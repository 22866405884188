import {createBrowserHistory} from 'history'
import {register, DNAIoCInit} from 'dna-react-ioc'
import {IMainLayoutStore} from '@/layouts/MainLayout/IMainLayoutStore'
import {TRangePickerContainerStore} from '@/components/containers/RangePickerContainer/TRangePickerContainerStore'
import {
    AuthStore,
    AuthStoreSymbol,
    MainLayoutStore,
    ModalStore,
    RangePickerStore,
    PaginationStore,
    HeaderStore,
    VirtualTerminalStore,
    VirtualTerminalStoreSymbol,
    AuthStoreInterface,
    InvoicesStore
} from '@/stores'
import {
    ModalContainerStoreSymbol,
    PaginationContainerStoreSymbol,
    PaginationContainerStoreType,
    RangePickerStoreSymbol
} from '@/components/containers'
import {
    AuthNotificationStoreSymbol,
    MainLayoutStoreSymbol,
    NotificationStoreSymbol
} from '@/layouts'
import {TModalContainerStore} from '@/components/containers/ModalContainer/TModalContainerStore'
import {HeaderStoreSymbol, THeaderStore} from '@/components'
import {TVirtualTerminalStore} from '@/pages/VirtualTerminal'
import {onlinePaymentsState} from './online-payments-state'
import {chargebacksState} from './chargebacks-state'
import {paymentMethodsState} from './payment-methods-state'
import {reportsState} from './reports-state'
import {exportsState} from './exports-state'
import {settlementsState} from './settlements-state'
import {overviewState} from './overview-state'
import {posPaymentsState} from './pos-payments-state'
import {posAmexPaymentsState} from './pos-amex-payments-state'
import {payByLinkState} from './pay-by-link-state'
import {teamManagementState} from './team-management-state'
import {resetPasswordState} from '@/services/app-state-init/reset-password-state'
import {businessAccountInitState} from '@/services/app-state-init/business-account-init-state'
import {profileState} from '@/services/app-state-init/profile-state'
import {footerInitState} from '@/services/app-state-init/footer-state'
import {userGuideInitState} from '@/services/app-state-init/user-guide-init-state'
import {ecospendInitState} from '@/services/app-state-init/ecospend-state-init'
import {
    InvoicesPaginationStoreSymbol,
    InvoicesRangePickerContainerStoreSymbol,
    InvoicesStoreSymbol,
    TInvoicesStore
} from '@/pages/Billing/pages/Invoices'
import {merchantSelectState} from '@/services/app-state-init/merchant-select-state'
import {RouterStore, RouterStoreSymbol} from '@/router/RouterStore'
import {storeAndTerminalsState} from './store-and-terminals-state'
import {NotificationStore} from '@/stores/notification/NotificationStore'
import {settingsState} from '@/services/app-state-init/settings-state'
import {AuthNotificationStore} from '@/stores/notification/AuthNotificationStore'
import {sendbirdInitState} from '@/services/app-state-init/sendbird-init-state'
import {bankAccountCreateState} from '@/services/app-state-init/bank-account-create-state'

export const appState = () => {
    DNAIoCInit()

    register<AuthStoreInterface>(AuthStoreSymbol).to(AuthStore).inSingletonScope()

    register<RouterStore>(RouterStoreSymbol)
        .toDynamicValue(() => new RouterStore(createBrowserHistory({window})))
        .inSingletonScope()

    register<IMainLayoutStore>(MainLayoutStoreSymbol).to(MainLayoutStore).inSingletonScope()
    register<THeaderStore>(HeaderStoreSymbol).to(HeaderStore).inSingletonScope()
    register<TRangePickerContainerStore>(RangePickerStoreSymbol)
        .to(RangePickerStore)
        .inSingletonScope()

    /**
     *  Notification Store
     */

    register<NotificationStore>(NotificationStoreSymbol).to(NotificationStore).inSingletonScope()
    register<AuthNotificationStore>(AuthNotificationStoreSymbol)
        .to(AuthNotificationStore)
        .inSingletonScope()

    /** All, Ecom, Pos terminals stores */
    storeAndTerminalsState()

    /**
     * Virtual terminal Page Stores
     */
    {
        register<TVirtualTerminalStore>(VirtualTerminalStoreSymbol)
            .to(VirtualTerminalStore)
            .inSingletonScope()
    }

    /**
     * Profile Page Stores
     */
    profileState()

    /**
     * Overview Page Stores
     */
    overviewState()

    /**
     * Online Payments Page Stores
     */
    onlinePaymentsState()

    /**
     * POS Payments Page Stores
     */
    posPaymentsState()

    posAmexPaymentsState()

    /**
     * Settlements Page Stores
     */
    settlementsState()

    /**
     * Pay By Link Page Stores
     */
    payByLinkState()

    /**
     * Chargebacks Page Stores
     */
    chargebacksState()

    /**
     * Reports Page Stores
     */
    reportsState()

    /**
     * Exports Page Stores
     */
    exportsState()

    /**
     * Payment Methods Page Stores
     */
    paymentMethodsState()

    /**
     * Team Management Page Stores
     */
    teamManagementState()

    /**
     * Reset Password Page Stores
     */
    resetPasswordState()

    /**
     * Business Account Page Stores
     */
    businessAccountInitState()

    /**
     * Footer Stores
     */
    footerInitState()

    /**
     * User Guide Store
     */
    userGuideInitState()

    /**
     * Merchant Select Store
     */
    merchantSelectState()

    /**
     * Ecospend Store
     */
    ecospendInitState()

    /**
     * Default Containers stores
     */
    register<TModalContainerStore>(ModalContainerStoreSymbol).to(ModalStore).inSingletonScope()
    register<PaginationContainerStoreType>(PaginationContainerStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()

    /**
     * Invoices Store
     */
    register<TRangePickerContainerStore>(InvoicesRangePickerContainerStoreSymbol)
        .to(RangePickerStore)
        .inSingletonScope()
    register<PaginationContainerStoreType>(InvoicesPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()
    register<TInvoicesStore>(InvoicesStoreSymbol).to(InvoicesStore).inSingletonScope()

    /**
     * Sendbird chat
     */
    sendbirdInitState()

    /**
     * Settings Stores
     */
    settingsState()

    /**
     * Bank account create
     */
    bankAccountCreateState()
}
