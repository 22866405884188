import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {useInjection} from 'dna-react-ioc'
import {Descriptions, Empty, Typography} from 'antd'

import {useScreenType} from '@/hooks'
import {Card, Container, OutletStatus, PageContainer} from '@/components'

import {BankAccountCard} from '@/pages/BankAccounts/components'
import {DossierBankDetail} from '@/stores/bank-accounts'

import {IOutletDetailsContainerStore} from './IOutletDetailsContainerStore'
import translations from './translations'
import {OutletDetailsContainerProps} from './props'
import styles from './OutletDetailsContainer.scss'

export const OutletDetailsContainer: React.FC<OutletDetailsContainerProps> = observer(
    ({injectableIdentifier, containerTitle, id}) => {
        const {
            isLoading,
            details,
            onChangeBankDetailsClick,
            hasSettlementBlocks,
            hasAllBlocks,
            hasDirectDebitBlocks,
            loadDossierMerchantOutletDetails,
            onBack,
            hasFullPermissions
        } = useInjection<IOutletDetailsContainerStore>(injectableIdentifier)
        const {isMobile} = useScreenType()

        useEffect(() => {
            loadDossierMerchantOutletDetails(id)
        }, [id])

        const renderBankAccount = ({
            bankAccount,
            isSettlements,
            isDirectDebit
        }: {
            bankAccount: DossierBankDetail
            isDirectDebit: boolean
            isSettlements: boolean
        }) => (
            <>
                <BankAccountCard
                    key={bankAccount.id}
                    bank={bankAccount.bankName}
                    logo={bankAccount.logo}
                    sortCode={bankAccount.sortCode}
                    accountNumber={bankAccount.bankAccountNumber}
                    hasChangeBankDetails
                    onChangeBankDetailsClick={onChangeBankDetailsClick}
                    isSettlements={isSettlements}
                    isDirectDebit={isDirectDebit}
                    hasSettlementBlocks={hasSettlementBlocks}
                    hasAllBlocks={hasAllBlocks}
                    hasDirectDebitBlocks={hasDirectDebitBlocks}
                    hasFullPermissions={hasFullPermissions}
                />
                <br />
            </>
        )

        return (
            <Container className={styles.container}>
                <PageContainer title={containerTitle} isLoading={false} onBack={onBack}>
                    {isLoading ? (
                        <Card loading={isLoading} />
                    ) : !details ? (
                        <Empty description={false} />
                    ) : (
                        <>
                            <Descriptions
                                className={classNames(styles.descriptionLabel, {
                                    [styles.descriptionLabelMobile]: isMobile
                                })}
                                title=''
                                layout={isMobile ? 'vertical' : 'horizontal'}
                                column={1}
                                colon={false}
                            >
                                <Descriptions.Item label={translations().labels.outletStatus}>
                                    <OutletStatus isActive={details?.isActive} />
                                </Descriptions.Item>
                                <Descriptions.Item label={translations().labels.address}>
                                    {details?.address}
                                </Descriptions.Item>
                                <Descriptions.Item label={translations().labels.mid}>
                                    <Typography.Paragraph copyable>
                                        {details?.mid}
                                    </Typography.Paragraph>
                                </Descriptions.Item>
                            </Descriptions>
                            {details?.directDebitAccounts?.length > 0 ? (
                                <Typography.Title level={4} className={styles.bankAccount}>
                                    {translations().titles.directDebitAccount}
                                </Typography.Title>
                            ) : null}
                            {details?.directDebitAccounts?.map((bankAccount) =>
                                renderBankAccount({
                                    bankAccount,
                                    isDirectDebit: true,
                                    isSettlements: false
                                })
                            )}
                            {details?.settlementAccounts?.length > 0 ? (
                                <Typography.Title level={4} className={styles.bankAccount}>
                                    {translations().titles.settlementsAccount}
                                </Typography.Title>
                            ) : null}
                            {details?.settlementAccounts?.map((bankAccount) =>
                                renderBankAccount({
                                    bankAccount,
                                    isDirectDebit: false,
                                    isSettlements: true
                                })
                            )}
                        </>
                    )}
                </PageContainer>
            </Container>
        )
    }
)
