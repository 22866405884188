import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    title: string
    inviteTeammate: string
    editTeammate: string
    cancel: string
    submit: string
    invite: string
    formFields: {
        email: string
        store: string
        firstName: string
        lastName: string
        teammatePermissions: string
        inviteAsAdmin: string
        inviteAsRestrictedAccess: string
        accountantAccess: string
        customAccess: string
        grantedPermissions: string
        accessLevels: string
        sectionName: string
        noAccess: string
        readAccess: string
        fullAccess: string
        sections: {
            overview: string
            online_payments: string
            pos_payments: string
            pos_amex_payments: string
            payment_links: string
            virtual_terminal: string
            zash_epos: string
            settlements: string
            invoices: string
            reports: string
            payment_methods: string
            teammates: string
            chargebacks: string
            'merchant.profile': string
            'merchant.ecom_stores': string
            'merchant.pos_stores': string
            'merchant.bank_accounts': string
        }
    }
    formFieldsPlaceholders: {
        role: string
    }
    formErrors: {
        emailRequired: string
        roleRequired: string
        shopRequired: string
        enterValidEmail: string
        firstNameRequired: string
        lastNameRequired: string
    }
}>()
