import React from 'react'
import {Typography} from 'antd'
import classNames from 'classnames'
import {paginationOptions} from '@/services/mocks'
import {Select} from '@/components'
import {useScreenType} from '@/hooks'
import {PaginationOptionsSelectProps} from './props'
import translations from './translations'
import styles from './styles.scss'
import {PAGINATION_LABEL, PAGINATION_SELECTED} from '@/constants/playwright-ids'

export const PageSizeSelect: React.FC<PaginationOptionsSelectProps> = ({
    total,
    className,
    pageSize,
    onChange
}) => {
    const {isMobile, isTablet} = useScreenType()

    const renderOutOfTotal = () =>
        pageSize < total ? (
            <span className={styles.outOfTotal}>
                {translations().of} {total}
            </span>
        ) : null

    return (
        <div
            className={classNames(styles.pagination, className, {
                [styles.paginationMobile]: isMobile
            })}
        >
            <span className={styles.paginationLabel} data-test-id={PAGINATION_LABEL}>
                {isMobile || isTablet ? (
                    <Typography.Text type={'secondary'}>{translations().items}:</Typography.Text>
                ) : (
                    translations().itemsOnAPage
                )}
            </span>
            <Select
                data-test-id={PAGINATION_SELECTED}
                variant={'borderless'}
                value={pageSize}
                defaultValue={paginationOptions[2]}
                placeholder={translations().select}
                options={paginationOptions}
                onChange={onChange}
            />
            {isMobile || isTablet ? null : renderOutOfTotal()}
        </div>
    )
}
