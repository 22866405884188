import React from 'react'
import {Col, Layout, Result, Row, Typography} from 'antd'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

import {TerminalType} from '@/constants'
import {ROUTES} from '@/router/routes'
import {withTitle} from '@/router/utils'
import {useScreenType, useWindowSize} from '@/hooks'
import {PageHeader} from '@/components/dumb/PageHeader'
import {
    RangePickerContainer,
    SelectWithTitleContainer,
    TabsContainer,
    TTabsContainerStore
} from '@/components/containers'
import {StoreSelectContainer} from '@/components/containers/transaction'
import OverviewOnlinePaymentsAnalytics from '@/pages/Overview/components/OverviewOnlinePaymentsAnalytics/OverviewOnlinePaymentsAnalytics'
import OverviewPosPaymentsAnalytics from '@/pages/Overview/components/OverviewPosPaymentsAnalytics/OverviewPosPaymentsAnalytics'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import translations from './translations'
import styles from './styles.scss'

export const OverviewTabsContainerStoreSymbol = Symbol('OverviewTabsContainerStore')
export const EcomOverviewRangePickerStoreSymbol = Symbol('EcomOverviewRangePickerStore')
export const PosOverviewRangePickerStoreSymbol = Symbol('PosOverviewRangePickerStore')
export const OverviewECOMCurrencySelectStoreSymbol = Symbol('OverviewECOMCurrencySelectStore')
export const OverviewPOSCurrencySelectStoreSymbol = Symbol('OverviewPOSCurrencySelectStore')
export const OverviewEcomStoreSelectStoreSymbol = Symbol('OverviewEcomStoreSelectStore')
export const OverviewPosStoreSelectStoreSymbol = Symbol('OverviewPosStoreSelectStore')
export const OverviewEcomTransactionsStatusStoreSymbol = Symbol(
    'OverviewEcomTransactionsStatusStore'
)

const Overview: React.FC = observer(() => {
    const tabsStore = useInjection<TTabsContainerStore>(OverviewTabsContainerStoreSymbol)

    const [windowWidth] = useWindowSize()
    const {isMobile, isDesktop} = useScreenType()

    return (
        <>
            <PageHeader
                className={styles.header}
                title={
                    <Row gutter={isMobile ? [16, 16] : [32, 32]}>
                        <Col>
                            <Typography.Title className={styles.title} level={4}>
                                {translations().title}
                            </Typography.Title>
                        </Col>
                        {hasPermissions([PermissionsMap.reports.read]) && (
                            <Col>
                                <Link className={styles.link} to={ROUTES.reports}>
                                    {translations().detailedReport}
                                </Link>
                            </Col>
                        )}
                    </Row>
                }
                border={'none'}
            >
                {!isDesktop &&
                hasPermissions([
                    PermissionsMap.online_payments.read,
                    PermissionsMap.online_payments.full,
                    PermissionsMap.pos_payments.read,
                    PermissionsMap.pos_payments.full
                ]) ? (
                    <div
                        className={classNames(styles.tabBarExtra, {
                            [styles.tabBarExtraMobile]: isMobile
                        })}
                    >
                        <StoreSelectContainer
                            className={classNames(styles.store, {
                                [styles.storeMobile]: isMobile
                            })}
                            type={
                                tabsStore.value === 'onlinePayments'
                                    ? TerminalType.ECOM
                                    : TerminalType.POS
                            }
                            ecomInjectableIdentifier={OverviewEcomStoreSelectStoreSymbol}
                            posInjectableIdentifier={OverviewPosStoreSelectStoreSymbol}
                        />
                        <SelectWithTitleContainer
                            className={classNames(styles.currency, {
                                [styles.currencyMobile]: isMobile
                            })}
                            injectableIdentifier={
                                tabsStore.value === 'onlinePayments'
                                    ? OverviewECOMCurrencySelectStoreSymbol
                                    : OverviewPOSCurrencySelectStoreSymbol
                            }
                            displayValue={translations().currency}
                        />
                        <RangePickerContainer
                            key={
                                tabsStore.value === 'onlinePayments'
                                    ? 'onlinePayments'
                                    : 'PosPayments'
                            }
                            showRangePickerTime
                            injectableIdentifier={
                                tabsStore.value === 'onlinePayments'
                                    ? EcomOverviewRangePickerStoreSymbol
                                    : PosOverviewRangePickerStoreSymbol
                            }
                        />
                    </div>
                ) : null}

                {hasPermissions([
                    PermissionsMap.online_payments.read,
                    PermissionsMap.online_payments.full,
                    PermissionsMap.pos_payments.read,
                    PermissionsMap.pos_payments.full
                ]) ? (
                    <TabsContainer
                        injectableIdentifier={OverviewTabsContainerStoreSymbol}
                        className={classNames(
                            {
                                [styles.overviewTabs]: windowWidth < 1480
                            },
                            styles.tabs
                        )}
                        tabBarExtraContent={
                            isDesktop ? (
                                <div
                                    className={classNames(styles.tabBarExtra, {
                                        [styles.tabBarExtraMobile]: isMobile
                                    })}
                                >
                                    <StoreSelectContainer
                                        className={classNames(styles.store, {
                                            [styles.storeMobile]: isMobile
                                        })}
                                        type={
                                            tabsStore.value === 'onlinePayments'
                                                ? TerminalType.ECOM
                                                : TerminalType.POS
                                        }
                                        ecomInjectableIdentifier={
                                            OverviewEcomStoreSelectStoreSymbol
                                        }
                                        posInjectableIdentifier={OverviewPosStoreSelectStoreSymbol}
                                    />
                                    <SelectWithTitleContainer
                                        className={classNames(styles.currency, {
                                            [styles.currencyMobile]: isMobile
                                        })}
                                        injectableIdentifier={
                                            tabsStore.value === 'onlinePayments'
                                                ? OverviewECOMCurrencySelectStoreSymbol
                                                : OverviewPOSCurrencySelectStoreSymbol
                                        }
                                        displayValue={translations().currency}
                                    />
                                    <RangePickerContainer
                                        key={
                                            tabsStore.value === 'onlinePayments'
                                                ? 'onlinePayments'
                                                : 'PosPayments'
                                        }
                                        className={styles.picker}
                                        showRangePickerTime
                                        injectableIdentifier={
                                            tabsStore.value === 'onlinePayments'
                                                ? EcomOverviewRangePickerStoreSymbol
                                                : PosOverviewRangePickerStoreSymbol
                                        }
                                    />
                                </div>
                            ) : null
                        }
                    />
                ) : (
                    <Result status='404' title={translations().noContentTitle} />
                )}
            </PageHeader>

            <Layout.Content className={styles.body}>
                {tabsStore.value === 'onlinePayments'
                    ? hasPermissions([
                          PermissionsMap.online_payments.read,
                          PermissionsMap.online_payments.full
                      ]) && <OverviewOnlinePaymentsAnalytics />
                    : hasPermissions([
                          PermissionsMap.pos_payments.read,
                          PermissionsMap.pos_payments.full
                      ]) && <OverviewPosPaymentsAnalytics />}
            </Layout.Content>
        </>
    )
})

export default withTitle(Overview)
