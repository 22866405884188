import {register} from 'dna-react-ioc'
import {BankAccountCreateStoreSymbol} from '@/pages/BankAccountCreate/BankAccountCreate'
import {BankAccountCreateStore} from '@/stores/bank-account-create/BankAccountCreateStore'
import {IBankAccountCreateStore} from '@/pages/BankAccountCreate'

export const bankAccountCreateState = () => {
    register<IBankAccountCreateStore>(BankAccountCreateStoreSymbol)
        .to(BankAccountCreateStore)
        .inSingletonScope()
}
