import React from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {LeftOutlined} from '@ant-design/icons'
import classNames from 'classnames'

import translations from './translations'
import {BackButtonProps} from './props'
import styles from './BackButton.scss'
import { BACK_ARROW } from '@/constants/playwright-ids'

export const BackButton: React.FC<BackButtonProps> = observer(({className, onClick, to, title}) => {
    return (
        <Link
            className={classNames(styles.backButton, className)}
            to={to}
            onClick={(event) => {
                if (onClick) {
                    event.preventDefault()
                    onClick && onClick(event)
                }
            }}
            data-test-id={BACK_ARROW}
        >
            <LeftOutlined className={styles.icon} />
            {title || translations().back}
        </Link>
    )
})
