export const PATHS = {
    POS_OUTLETS: '/pos-outlets/',
    ONLINE_OUTLETS: '/online-outlets/',
    DIRECT_DEBIT: '/direct-debit',
    SETTLEMENTS: '/settlements'
} as const

export const MERCHANT_CONFIG = {
    UBER_ID: 'UBER_ID',
    DD: 'DD'
} as const
