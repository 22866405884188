import React, {useState} from 'react'
import {useMount} from 'react-use'
import {observer} from 'mobx-react'
import {Card, Divider, Spin, Table, Tabs} from 'antd'
import {useInjection} from 'dna-react-ioc'
import {PageHeader, PaginationContainer, UserGuideStoreSymbol} from '@/components'
import {useScreenType} from '@/hooks/useScreenType'
import {LoadingState} from '@/types'
import {USER_GUIDE_SELECTORS} from '@/constants'
import {withTitle} from '@/router/utils'
import {TSettlementsStore} from '@/pages/Settlements/TSettlementsStore'
import {SettlementsTableFilter} from '@/pages/Settlements/components/SettlementsTableFilter/SettlementsTableFilter'
import {TUserGuideStore} from '@/components/containers/UserGuideContainer/TUserGuideStore'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {hasPermissions} from '@/stores/auth/services/utils'
import {DownloadButtons} from '@/components/composite/DownloadButtons'
import {SettlementsPdfDownloadModal} from './components/SettlementsPdfDownloadModal'
import {SettlementsPdfDownloadLoadingModal} from './components/SettlementsPdfDownloadLoadingModal'

import translations from './translations'
import styles from './styles.scss'

export const SettlementsStoreSymbol = Symbol('SettlementsStore')
export const SettlementsFiltersStoreSymbol = Symbol('SettlementsFiltersStore')
export const SettlementsPaginationStoreSymbol = Symbol('SettlementsPaginationStore')
export const SettlementsPerDayPaginationStoreSymbol = Symbol('SettlementsPerDayPaginationStore')

const {TabPane} = Tabs
const {LOADING} = LoadingState

const Settlements = observer(() => {
    const {
        isLoading,
        getColumns,
        getPerDayColumns,
        settlements,
        settlementsPerDay,
        loadingState,
        loadingPerDayState,
        downloadingState,
        downloadableTypes,
        isPdfSatementModalOpen,
        isPdfSatementLoading,
        onDownloadItemSelect,
        loadSettlements,
        loadSettlementsPerDay,
        loadAllSettlements,
        setIsPdfSatementModalOpen,
        downloadPdfStatement
    } = useInjection<TSettlementsStore<any>>(SettlementsStoreSymbol)
    const {setRun, setStepIndex, tourActive} = useInjection<TUserGuideStore>(UserGuideStoreSymbol)
    const [tab, setTab] = useState<string>('per-day')
    const {isMobile} = useScreenType()

    const isPerDayLoading = loadingPerDayState === LOADING
    const isPerTransactionsLoading = loadingState === LOADING

    useMount(() => {
        if (tourActive) {
            setRun(true)
            setStepIndex(2)
        }
    })

    const renderDownloadButtons = () => {
        return (
            <>
                <DownloadButtons
                    title={translations().download}
                    menuItems={downloadableTypes}
                    onMenuItemClick={onDownloadItemSelect}
                    isLoading={downloadingState === LOADING}
                    className={USER_GUIDE_SELECTORS.settlementsDownload}
                />

                <SettlementsPdfDownloadModal
                    isOpen={isPdfSatementModalOpen}
                    onCancel={() => setIsPdfSatementModalOpen(false)}
                    onSubmit={async (data) => {
                        setIsPdfSatementModalOpen(false)

                        await downloadPdfStatement(data)
                    }}
                />

                <SettlementsPdfDownloadLoadingModal isOpen={isPdfSatementLoading} />
            </>
        )
    }

    const pageHeaderExtra = () => {
        if (isMobile) {
            return (
                <div className={styles.pageHeaderExtra}>
                    <SettlementsTableFilter
                        isLoading={isLoading}
                        onReloadClick={async () => await loadAllSettlements()}
                    />
                    <div className={styles.pageSizeControlContainer}>
                        <PaginationContainer
                            showOnlySizeChanger
                            injectableIdentifier={
                                tab === 'per-day'
                                    ? SettlementsPerDayPaginationStoreSymbol
                                    : SettlementsPaginationStoreSymbol
                            }
                        />
                        {hasPermissions([PermissionsMap.settlements.full]) && (
                            <>
                                <Divider type={'vertical'} />
                                {renderDownloadButtons()}
                            </>
                        )}
                    </div>
                </div>
            )
        }
        return (
            <div className={styles.pageHeaderExtra}>
                <SettlementsTableFilter
                    isLoading={isLoading}
                    onReloadClick={async () => await loadAllSettlements()}
                />
                {hasPermissions([PermissionsMap.settlements.full]) && (
                    <div className={styles.buttons}>
                        <Divider type={'vertical'} className={styles.divider} />
                        {renderDownloadButtons()}
                    </div>
                )}
            </div>
        )
    }

    const renderContent = () => {
        return (
            <>
                <PageHeader
                    onBack={null}
                    border={'none'}
                    title={<div className={'settlements'}>{translations().title}</div>}
                >
                    <Card>
                        {translations().description1}

                        {hasPermissions([
                            PermissionsMap.pos_amex_payments.read,
                            PermissionsMap.pos_amex_payments.full
                        ]) && (
                            <>
                                <br />
                                <br />
                                {translations().description2}
                            </>
                        )}
                    </Card>
                </PageHeader>

                <Tabs
                    activeKey={tab}
                    className={styles.tabs}
                    onChange={setTab}
                    tabBarExtraContent={pageHeaderExtra()}
                >
                    <TabPane
                        tab={
                            <Spin size='small' spinning={isPerDayLoading}>
                                {translations().perDay}
                            </Spin>
                        }
                        key={'per-day'}
                        disabled={isPerDayLoading}
                    >
                        <Spin spinning={isPerDayLoading}>
                            <Table
                                columns={getPerDayColumns(isMobile)}
                                dataSource={settlementsPerDay || []}
                                pagination={false}
                                scroll={{x: 1000}}
                                rowKey={(settlement) => settlement.rowKey}
                            />
                            <PaginationContainer
                                hideSizeChanger={isMobile}
                                injectableIdentifier={SettlementsPerDayPaginationStoreSymbol}
                            />
                        </Spin>
                    </TabPane>
                    <TabPane
                        tab={
                            <Spin size='small' spinning={isPerTransactionsLoading}>
                                {translations().perTransaction}
                            </Spin>
                        }
                        key={'per-transaction'}
                        disabled={isPerTransactionsLoading}
                    >
                        <Spin spinning={isPerTransactionsLoading}>
                            <Table
                                columns={getColumns(isMobile)}
                                dataSource={settlements || []}
                                pagination={false}
                                scroll={{x: 1000}}
                                rowKey={(settlement) => settlement.rowKey}
                            />
                            <PaginationContainer
                                hideSizeChanger={isMobile}
                                injectableIdentifier={SettlementsPaginationStoreSymbol}
                            />
                        </Spin>
                    </TabPane>
                </Tabs>
            </>
        )
    }

    return <>{renderContent()}</>
})

export default withTitle(Settlements)
