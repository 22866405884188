import React from 'react'
import {Descriptions, Typography} from 'antd'
import classNames from 'classnames'

import {useScreenType} from '@/hooks'

import translations from '../../translations'
import {AssociatedOutletsProps} from './props'
import styles from './AssociatedOutlets.scss'

export const AssociatedOutlets: React.FC<AssociatedOutletsProps> = ({outlets, className}) => {
    const {isMobile} = useScreenType()
    const hasOutlets = outlets.length > 0
    const formattedOutlets = hasOutlets ? outlets.map((outlet) => outlet.trim()).join(', ') : ''

    return (
        <Descriptions
            className={classNames(styles.descriptionLabel, {
                [styles.descriptionLabelMobile]: isMobile
            })}
            title=''
            layout={isMobile ? 'vertical' : 'horizontal'}
            column={1}
            colon={false}
        >
            <Descriptions.Item label={translations().labels.associatedOutlets}>
                {hasOutlets ? (
                    <Typography.Text className={styles.truncate}>
                        {formattedOutlets}
                    </Typography.Text>
                ) : (
                    <Typography.Text type='secondary'>
                        {translations().labels.noAssociatedSettlementsOutlets}
                    </Typography.Text>
                )}
            </Descriptions.Item>
        </Descriptions>
    )
}
