import translations from './translations'

translations.add('ro', {
    title: 'Gestionarea echipei',
    inviteTeammate: 'Adaugă utilizator',
    editTeammate: 'Editează utilizator',
    cancel: 'Anulează',
    submit: 'Finalizează',
    invite: 'Invită',
    formFields: {
        email: 'Email',
        store: 'Magazin',
        firstName: 'Prenume',
        lastName: 'Nume de familie',
        teammatePermissions: 'Acces utilizator',
        inviteAsAdmin: 'Administrator',
        inviteAsRestrictedAccess: 'Acces limitat',
        accountantAccess: 'Acces financiar',
        customAccess: 'Acces personalizat',
        grantedPermissions: 'Setări permisiuni',
        accessLevels: 'Nivele de acces',
        sectionName: 'Nume secțiune',
        noAccess: 'Fără acces',
        readAccess: 'Doar citire',
        fullAccess: 'Acces complet',
        sections: {
            overview: 'Prezentare generală',
            online_payments: 'Plăți online',
            pos_payments: 'Plăți POS',
            pos_amex_payments: 'POS American Express',
            payment_links: 'Link-uri de plată',
            virtual_terminal: 'Terminal virtual',
            zash_epos: 'Zash ePOS',
            settlements: 'Reglementări',
            invoices: 'Facturi',
            reports: 'Rapoarte',
            payment_methods: 'Setări. Metode de Plată Online',
            teammates: 'Setări. Gestionarea Echipei',
            chargebacks: 'Refuzuri la plată',
            'merchant.profile': 'Setări. Profilul companiei',
            'merchant.ecom_stores': 'Setări. Magazine Online',
            'merchant.pos_stores': 'Setări. Puncte de Vânzare (POS)',
            'merchant.bank_accounts': 'Setări. Conturi bancare'
        }
    },
    formFieldsPlaceholders: {
        role: 'Selectează rol'
    },
    formErrors: {
        emailRequired: 'Adresa de email este obligatorie',
        roleRequired: 'Rol este obligatoriu',
        shopRequired: 'Magazinul este obligatoriu',
        enterValidEmail: 'Vă rugăm să introduceți o adresă de email validă',
        firstNameRequired: 'Prenumele este obligatoriu',
        lastNameRequired: 'Numele de familie este obligatoriu'
    }
})
