import React from 'react'
import {Modal, Space, Button} from 'antd'
import {observer} from 'mobx-react'

import {BankAccountDetails} from '@/pages/ChangeBankAccountDetails/components/BankAccountDetails'

import translations from '../../translations'
import {BankChangeModalsProps} from './props'
import {GoogleManagerTagClassNames} from '@/constants'

export const BankChangeModals: React.FC<BankChangeModalsProps> = observer(
    ({currentAccount, selectedAccount, accountType, confirmModal, successModal, errorModal}) => {
        const confirmButtonTag =
            accountType === 'all'
                ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.all.modal.confirm
                : accountType === 'settlement'
                  ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.settlements.modal
                        .confirm
                  : GoogleManagerTagClassNames.settings.outlets.changeBankDetails.directDebit.modal
                        .confirm

        const cancelButtonTag =
            accountType === 'all'
                ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.all.modal.cancel
                : accountType === 'settlement'
                  ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.settlements.modal
                        .cancel
                  : GoogleManagerTagClassNames.settings.outlets.changeBankDetails.directDebit.modal
                        .cancel

        return (
            <>
                {selectedAccount && currentAccount && (
                    <Modal
                        title={translations().titles.bankChangeConfirmation}
                        open={confirmModal.isOpen}
                        onCancel={confirmModal.onClose}
                        footer={
                            <Space>
                                <Button
                                    loading={confirmModal.isLoading}
                                    type='primary'
                                    onClick={confirmModal.onConfirm}
                                    className={confirmButtonTag}
                                >
                                    <span className={confirmButtonTag}>
                                        {translations().buttons.confirm}
                                    </span>
                                </Button>
                                <Button className={cancelButtonTag} onClick={confirmModal.onClose}>
                                    <span className={cancelButtonTag}>
                                        {translations().buttons.cancel}
                                    </span>
                                </Button>
                            </Space>
                        }
                    >
                        <p>
                            {accountType === 'all'
                                ? translations().labels
                                      .youAreAboutToUpdateYourSettlementDirectDebitBankAccount
                                : accountType === 'settlement'
                                  ? translations().labels
                                        .youAreAboutToUpdateYourSettlementBankAccount
                                  : translations().labels
                                        .youAreAboutToUpdateYourDirectDebitBankAccount}
                        </p>
                        <BankAccountDetails
                            accountNumber={currentAccount.bankAccountNumber}
                            sortCode={currentAccount.sortCode}
                            label={translations().labels.currentBankAccountDetails}
                        />
                        <br />
                        <br />
                        <BankAccountDetails
                            accountNumber={selectedAccount.bankDetail?.bankAccountNumber || ''}
                            sortCode={selectedAccount.bankDetail?.sortCode || ''}
                            label={translations().labels.newBankAccountDetails}
                        />
                        <br />
                        <p>{translations().labels.byConfirmingYouAuthoriseChange}</p>
                    </Modal>
                )}

                <Modal
                    title={translations().titles.bankChangeConfirmation}
                    open={successModal.isOpen}
                    onCancel={successModal.onClose}
                    footer={
                        <Button onClick={successModal.onClose}>{translations().buttons.ok}</Button>
                    }
                >
                    <p>{translations().labels.bankAccountChangeSubmitted}</p>
                    <p>
                        {accountType === 'all'
                            ? translations().labels.accountWillBeUpdatedForOutlets
                            : accountType === 'settlement'
                              ? translations().labels.accountWillBeUpdatedForSettlementOutlets
                              : translations().labels.accountWillBeUpdatedForDirectDebitOutlets}
                    </p>
                </Modal>

                <Modal
                    title={translations().titles.bankChangeFailed}
                    open={errorModal.isOpen}
                    onCancel={errorModal.onClose}
                    footer={
                        <Button onClick={errorModal.onClose}>{translations().buttons.ok}</Button>
                    }
                >
                    <p>{translations().labels.couldNotUpdateBankAccount}</p>
                    <span>{translations().labels.nextSteps}</span>
                    <br />
                    <ul>
                        <li>{translations().labels.tryAgain}</li>
                        <li>{translations().labels.contactSupport}</li>
                    </ul>
                </Modal>
            </>
        )
    }
)
