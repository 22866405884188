import {PaymentMethodItem} from '@/stores/payment-methods/PaymentMethods/models'
import {PaymentMethodType} from '@/constants'
import {getPaymentMethodDetailsRoute, getRouterStore} from '@/router/utils'
import rootTranslations from '@/translations'
import translations from '@/pages/payment-methods/PaymentMethods/translations'

export const paymentMethods: PaymentMethodItem[] = [
    {
        link: getPaymentMethodDetailsRoute(PaymentMethodType.applepay),
        title: rootTranslations().constants.paymentMethodType.applepay,
        linkText: translations().viewDetails,
        onClick: () =>
            getRouterStore().push(getPaymentMethodDetailsRoute(PaymentMethodType.applepay)),
        type: PaymentMethodType.applepay
    },
    {
        link: getPaymentMethodDetailsRoute(PaymentMethodType.googlepay),
        title: rootTranslations().constants.paymentMethodType.googlepay,
        linkText: translations().viewDetails,
        onClick: () =>
            getRouterStore().push(getPaymentMethodDetailsRoute(PaymentMethodType.googlepay)),
        type: PaymentMethodType.googlepay
    },
    {
        link: getPaymentMethodDetailsRoute(PaymentMethodType.paypal),
        title: rootTranslations().constants.paymentMethodType.paypal,
        linkText: translations().viewDetails,
        onClick: () =>
            getRouterStore().push(getPaymentMethodDetailsRoute(PaymentMethodType.paypal)),
        type: PaymentMethodType.paypal
    },
    {
        link: getPaymentMethodDetailsRoute(PaymentMethodType.klarna),
        title: rootTranslations().constants.paymentMethodType.klarna,
        linkText: translations().viewDetails,
        onClick: () =>
            getRouterStore().push(getPaymentMethodDetailsRoute(PaymentMethodType.klarna)),
        type: PaymentMethodType.klarna
    }
]
