import translations from './translations'

translations.add('es', {
    title: 'Gestión de equipos',
    inviteTeammate: 'Añadir usuario',
    editTeammate: 'Editar usuario',
    cancel: 'Cancelar',
    submit: 'Completar',
    invite: 'Invitar',
    formFields: {
        email: 'Correo electrónico',
        store: 'Tienda',
        firstName: 'Nombre',
        lastName: 'Apellido',
        teammatePermissions: 'Acceso de usuarios',
        inviteAsAdmin: 'Admin',
        inviteAsRestrictedAccess: 'Acceso limitado',
        accountantAccess: 'Acceso financiero',
        customAccess: 'Acceso personalizado',
        grantedPermissions: 'Configuración de permisos',
        accessLevels: 'Niveles de acceso',
        sectionName: 'Nombre de la sección',
        noAccess: 'Sin acceso',
        readAccess: 'Sólo lectura',
        fullAccess: 'Acceso total',
        sections: {
            overview: 'Visión general',
            online_payments: 'Pagos en línea',
            pos_payments: 'Pagos TPV',
            pos_amex_payments: 'POS American Express',
            payment_links: 'Enlaces de pago',
            virtual_terminal: 'Terminal virtual',
            zash_epos: 'Zash ePOS',
            settlements: 'Liquidaciones',
            invoices: 'Facturas',
            reports: 'Informes',
            payment_methods: 'Configuraciones. Métodos de Pago en Línea',
            teammates: 'Configuraciones. Gestión del Equipo',
            chargebacks: 'Contracargos',
            'merchant.profile': 'Configuraciones. Perfil de la empresa',
            'merchant.ecom_stores': 'Configuraciones. Tiendas en línea',
            'merchant.pos_stores': 'Configuraciones. Puntos de Venta (POS)',
            'merchant.bank_accounts': 'Configuraciones. Cuentas bancarias'
        }
    },
    formFieldsPlaceholders: {
        role: 'Seleccione función'
    },
    formErrors: {
        emailRequired: 'Correo electrónico obligatorio',
        roleRequired: 'Rol obligatorio',
        shopRequired: 'Se requiere una tienda',
        enterValidEmail: 'Introduzca una dirección de correo electrónico válida',
        firstNameRequired: 'Nombre obligatorio',
        lastNameRequired: 'Apellido obligatorio'
    }
})
