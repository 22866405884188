import translations from './translations'

translations.add('en', {
    title: 'Team management',
    inviteTeammate: 'Add user',
    editTeammate: 'Edit user',
    cancel: 'Cancel',
    submit: 'Complete',
    invite: 'Invite',
    formFields: {
        email: 'Email',
        store: 'Store',
        firstName: 'First name',
        lastName: 'Last name',
        teammatePermissions: 'User access',
        inviteAsAdmin: 'Admin',
        inviteAsRestrictedAccess: 'Limited access',
        accountantAccess: 'Finance access',
        customAccess: 'Custom access',
        grantedPermissions: 'Permissions settings',
        accessLevels: 'access levels',
        sectionName: 'Section name',
        noAccess: 'No access',
        readAccess: 'Read only',
        fullAccess: 'Full access',
        sections: {
            overview: 'Overview',
            online_payments: 'Online payments',
            pos_payments: 'POS payments',
            pos_amex_payments: 'POS American Express',
            payment_links: 'Payment links',
            virtual_terminal: 'Virtual terminal',
            zash_epos: 'Zash ePOS',
            settlements: 'Settlements',
            invoices: 'Invoices',
            reports: 'Reports',
            payment_methods: 'Settings. Online payment methods',
            teammates: 'Settings. Team management',
            chargebacks: 'Chargebacks',
            'merchant.profile': 'Settings. Company profile',
            'merchant.ecom_stores': 'Settings. Online outlets',
            'merchant.pos_stores': 'Settings. POS outlets',
            'merchant.bank_accounts': 'Settings. Bank accounts'
        }
    },
    formFieldsPlaceholders: {
        role: 'Select role'
    },
    formErrors: {
        emailRequired: 'Email is required',
        roleRequired: 'Role is required',
        shopRequired: 'Store is required',
        enterValidEmail: 'Please enter valid email',
        firstNameRequired: 'First name is required',
        lastNameRequired: 'Last name is required'
    }
})
