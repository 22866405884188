import {TranslationBuilder} from '@/components/dumb/Translation'

export default TranslationBuilder.create<{
    labels: {
        bank: string
        sortCode: string
        accountNumber: string
        associatedOutlets: string
        changeSettlementsBankDetails: string
        changeDirectDebitBankDetails: string
        changeInProgress: string
        noAssociatedOutlets: string
    }
}>()
