import React from 'react'
import {Col, Row} from 'antd'
import classNames from 'classnames'
import {doughnutChartConfig} from '@/services/chart-config'
import {Chart, Icon, Square} from '@/components'
import {DoughnutChartProps} from './props'
import styles from './styles.scss'

export const DoughnutChart: React.FC<DoughnutChartProps> = (
    {
        className, data,
        customOptions, centerTitle, centerSubtitle, ...rest
    }
) => {
    const renderLegendTitle = (index) => {
        return <p className={styles.legendTitle}>
            <>
                {data.icons && <Icon className={classNames(styles.legendIcon, className)} type={data.icons[index]}/>}
                {data.labels && data.labels[index] || 'Unknown'}
            </>
        </p>
    }

    const renderLegend = (_chart) => {
        return (
            <ul className={styles.legend}>
                {_chart?.data?.datasets[0]?.data.map((item, i) => (
                    <li key={_chart.data.labels && _chart.data.labels[i]}>
                        <Row gutter={[8, 16]}>
                            <Col xs={4}>
                                <Square colour={_chart.data.datasets[0].backgroundColor[i]}/>
                            </Col>
                            <Col xs={20}>
                                {renderLegendTitle(i)}
                            </Col>
                        </Row>
                    </li>
                ))}
            </ul>
        )
    }

    const plugins = [...doughnutChartConfig.getDoughnutPlugins]
    const options = doughnutChartConfig.doughnutOptions(centerTitle, centerSubtitle, renderLegend)

    if (data?.datasets?.length <= 0) {
        return null
    }

    return <div className={classNames(styles.pieChart, styles.hasLegend)} {...rest}>
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <div className={styles.chart}>
                    <Chart
                        className={classNames(styles.pieChart, className)}
                        type="doughnut"
                        data={data}
                        options={customOptions ? customOptions : options}
                        plugins={plugins}
                    />
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} className={styles.legendWrapper}>
                <div id={`pie-${styles.pieChart}-legend`} style={{width: '100%'}}>
                    <ul className={styles.legend}>
                        {
                            data?.datasets?.map((dataset, index) => (
                                dataset?.data?.map((item, i) => (
                                    <li key={data.labels && data.labels[i] as string}>
                                        <div className={styles.legendContainer}>
                                            {data.datasets?.length && data.datasets[index] && <Square colour={data.datasets[index]?.backgroundColor[i]}/>}
                                            {renderLegendTitle(i)}
                                        </div>
                                    </li>
                                ))
                            ))
                        }
                    </ul>
                </div>
            </Col>
        </Row>
    </div>
}
