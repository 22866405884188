import React from 'react'
import {observer} from 'mobx-react'

import {Alert} from '@/components'
import {THasClassName} from '@/types'

import translations from '../../translations'

export const EmptyBankAccounts = observer(({className}: THasClassName) => (
    <Alert
        className={className}
        title={translations().labels.noBankAccount}
        description={translations().labels.addBankAccountInstructions}
        type='warning'
    />
))
