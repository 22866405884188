import React from 'react'
import {observer} from 'mobx-react'
import {Form, Input, Radio} from 'antd'

import {CONFIGS} from '@/stores/bank-account-create/services/configs'
import {SORT_CODE_PATTERN} from '@/constants/patterns'

import translations from '../../translations'
import type {BankAccountCreateDynamicFieldsProps} from './props'

export const DynamicFields = observer(
    ({
        isFormDisabled,
        isSoleTrader,
        isAllowedToToggleAccountType,
        isValidating,
        onFieldChange,
        form
    }: BankAccountCreateDynamicFieldsProps) => {
        const t = translations()
        const isFieldDisabled = isValidating || isFormDisabled

        return (
            <>
                <Form.Item label={t.labels.accountType} name='bankAccountType'>
                    <Radio.Group disabled={isFormDisabled || !isAllowedToToggleAccountType}>
                        <Radio value='business'>{t.options.business}</Radio>
                        {isSoleTrader && <Radio value='personal'>{t.options.personal}</Radio>}
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={t.labels.bankAccountNumber}
                    name='bankAccountNumber'
                    rules={[
                        {
                            required: true,
                            message: t.validation.required.accountNumber
                        },
                        {
                            min: CONFIGS.minAccountNumberLength,
                            message: t.validation.format.accountNumberTooShort
                        }
                    ]}
                >
                    <Input
                        placeholder={t.placeholders.accountNumber}
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '')
                            onFieldChange(e)
                        }}
                        maxLength={8}
                        disabled={isFieldDisabled}
                        onKeyPress={(e) => {
                            if (!/\d/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        onPaste={(e) => {
                            const pastedText = e.clipboardData.getData('text')

                            if (/[^0-9]/.test(pastedText)) {
                                e.preventDefault()
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label={t.labels.sortCode}
                    name='sortCode'
                    rules={[
                        {
                            required: true,
                            message: t.validation.required.sortCode
                        },
                        {
                            pattern: SORT_CODE_PATTERN,
                            message: t.validation.format.sortCodeFormat
                        }
                    ]}
                    validateTrigger={['onChange', 'onBlur']}
                >
                    <Input
                        placeholder={t.placeholders.sortCode}
                        onChange={(e) => {
                            const currentValue = e.target.value
                            const cleanValue = currentValue.replace(/[^0-9]/g, '')

                            form.setFieldsValue({
                                sortCode: cleanValue.substring(0, CONFIGS.sortCodeLength)
                            })

                            onFieldChange({
                                target: {
                                    value: cleanValue.substring(0, CONFIGS.sortCodeLength)
                                }
                            })
                        }}
                        maxLength={CONFIGS.sortCodeLength}
                        disabled={isFieldDisabled}
                        onKeyPress={(e) => {
                            if (!/\d/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        onPaste={(e) => {
                            e.preventDefault()

                            const pastedText = e.clipboardData.getData('text')
                            const cleanedText = pastedText.replace(/[^0-9]/g, '')

                            const input = e.target as HTMLInputElement
                            const startPos = input.selectionStart || 0
                            const endPos = input.selectionEnd || 0

                            const currentValue = input.value

                            const newValue =
                                currentValue.substring(0, startPos) +
                                cleanedText +
                                currentValue.substring(endPos)

                            const truncatedValue = newValue.substring(0, CONFIGS.sortCodeLength)

                            form.setFieldsValue({
                                sortCode: truncatedValue
                            })

                            const newCursorPos = Math.min(
                                startPos + cleanedText.length,
                                CONFIGS.sortCodeLength
                            )

                            setTimeout(() => {
                                input.setSelectionRange(newCursorPos, newCursorPos)
                            }, 0)

                            onFieldChange({
                                target: {
                                    value: truncatedValue
                                }
                            })
                        }}
                    />
                </Form.Item>
            </>
        )
    }
)
