import React, {useEffect, useState} from 'react'
import {Skeleton} from 'antd'
import classNames from 'classnames'
import {BarChart, Card, SelectWithTitle, Tabs} from '@/components'
import {CardTitle} from '@/components/composite/TransactionsCardWithBarChart/components'
import {LoadingState, TScreenType} from '@/types'
import {useScreenType} from '@/hooks/useScreenType'
import translations from './translations'
import {TransactionsCardWithBarChartProps} from './props'
import styles from './styles.scss'
import {storage} from '@/services/storage'
import {IS_SIDER_COLLAPSED_KEY} from '@/constants'

export const TransactionsCardWithBarChart: React.FC<TransactionsCardWithBarChartProps> = ({
    dataLoadingState,
    data,
    dataTypes,
    onDataTypeChange,
    title,
    selectedRanges,
    selectedStatus,
    onStatusChange,
    isCompare = false,
    mobileOptions,
    statuses,
    chartOptions,
    ...restOfTheProps
}) => {
    const [isCollapsed, setCollapsed] = useState(() => Boolean(storage.get(IS_SIDER_COLLAPSED_KEY)))
    const screenType: TScreenType = useScreenType()

    const sideBarListener = () => {
        setCollapsed(Boolean(storage.get(IS_SIDER_COLLAPSED_KEY)))
    }

    useEffect(() => {
        window.addEventListener('storage', sideBarListener)

        return () => window.removeEventListener('storage', sideBarListener)
    }, [])

    const renderTitleContent = () => {
        return <>
            { statuses && statuses.length > 1 && <SelectWithTitle
                value={selectedStatus}
                displayValue={translations().status}
                className={classNames(styles.status, { [styles.statusMobile]: screenType.isMobile })}
                options={statuses}
                onChange={(status) => {onStatusChange(status)}} />
            }
        </>
    }

    const renderTabs = (shouldRender: boolean) => {
        return shouldRender ? <Tabs className={styles.tabs} tabs={dataTypes} onChange={onDataTypeChange}/> : null
    }

    return (
        <Card
            className={classNames(
                styles.TransactionsCardWithBarChart,
                styles.header,
                { [styles.headerMobile]: screenType.isMobile },
                { [styles.headerCompare]: isCompare },
                { [styles.collapsed]: !isCollapsed}
            )}
            title={ <CardTitle title={title}>{ renderTitleContent() }</CardTitle> }
            extra={ renderTabs(!screenType.isMobile) }
            {...restOfTheProps}
        >
            {dataLoadingState === LoadingState.LOADING && <Skeleton active={true}/>}
            {
                dataLoadingState != LoadingState.LOADING &&
                <>
                    { screenType.isMobile && <Tabs tabs={dataTypes} onChange={onDataTypeChange}/> }
                    <BarChart
                        className={classNames(styles.barChart)}
                        data={data}
                        options={chartOptions}
                    />
                </>
            }
        </Card>
    )
}
