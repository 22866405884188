import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'

import {LoadingState} from '@/types'
import {IOutletDetailsContainerStore} from '@/components'
import {
    fetchDossierMerchantOutletDetails,
    OutletDetailsResponseModel,
    OutletPageType
} from '@/stores/outlets'
import {getRouterStore} from '@/router/utils'
import {ROUTES} from '@/router/routes'
import {DossierBankAccountType} from '@/stores/bank-accounts'
import {normalizeAccountType} from '@/pages/BankAccounts/components'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'

@injectable()
export class OutletDetailsStore implements IOutletDetailsContainerStore {
    loadingState: LoadingState = LoadingState.IDLE
    _details: OutletDetailsResponseModel = null
    pageType: OutletPageType = null

    constructor(type: OutletPageType) {
        makeObservable(this, {
            loadingState: observable,
            _details: observable,
            details: computed,
            isLoading: computed,
            hasSettlementBlocks: computed,
            hasDirectDebitBlocks: computed,
            hasAllBlocks: computed,
            loadDossierMerchantOutletDetails: action,
            onBack: action
        })

        this.pageType = type
    }

    get details(): OutletDetailsResponseModel {
        const pathSegments = window.location.pathname.split('/').filter((segment) => segment) || []

        if (!this._details) {
            this.loadDossierMerchantOutletDetails(pathSegments[2])
        }

        return this._details
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    get hasSettlementBlocks(): boolean {
        return (
            this.details?.blocks?.some((block) => block.id?.toLowerCase().includes('settlement')) ??
            false
        )
    }

    get hasDirectDebitBlocks(): boolean {
        return (
            this.details?.blocks?.some((block) =>
                block.id?.toLowerCase().includes('directdebit')
            ) ?? false
        )
    }

    get hasAllBlocks(): boolean {
        return (
            this.details?.blocks?.some((block) => block.id?.toLowerCase().includes('all')) ?? false
        )
    }

    get hasFullPermissions(): boolean {
        if (this.pageType === 'online') {
            return hasPermissions([PermissionsMap.merchant.ecom_stores.full])
        }

        if (this.pageType === 'pos') {
            return hasPermissions([PermissionsMap.merchant.pos_stores.full])
        }

        return false
    }

    loadDossierMerchantOutletDetails = async (id: string) => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        this.loadingState = LoadingState.LOADING

        try {
            const {result, error} = await fetchDossierMerchantOutletDetails(id)

            if (error) {
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._details = result
                })

                if (!result) return
            }
        } catch (error) {
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }

    onChangeBankDetailsClick = (type: DossierBankAccountType) => {
        const pathSegments = window.location.pathname.split('/')

        const lastSegment = pathSegments[pathSegments.length - 1]

        const isNumericString = /^\d+$/.test(lastSegment)

        const outletId = this._details?.id
        if (isNumericString) {
            pathSegments.pop()
        }

        const redirectUrl = pathSegments.join('/')

        let newPath = `${redirectUrl}/${outletId}/${ROUTES.settings.changeBankAccountDetails}`

        if (type === 'settlements') {
            newPath += '/settlements'
        }

        if (normalizeAccountType(type) === 'direct-debit') {
            newPath += '/direct-debit'
        }

        getRouterStore().push(newPath)
    }

    onBack = () => {
        const pathSegments = window.location.pathname.split('/')
        pathSegments.pop()

        const newPath = pathSegments.join('/')

        this._details = null
        this.loadingState = LoadingState.IDLE

        getRouterStore().push(newPath)
    }
}
