import React, {useEffect} from 'react'
import {Form, Spin} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'

import {useScreenType} from '@/hooks'
import {FilterButtonPopover} from '@/components'
import {FilterForm} from '@/components/composite'

import {IFilterContainerStore} from './store'
import {FilterContainerProps} from './props'
import styles from './styles.scss'
import {FILTER} from '@/constants/playwright-ids'
import {toJS} from 'mobx'

export const FilterContainer = observer((props: FilterContainerProps) => {
    const {injectableIdentifier, store: _store, testId, formId, isLoading} = props

    const [form] = Form.useForm()
    const store = _store || useInjection<IFilterContainerStore>(injectableIdentifier)
    const {isMobile} = useScreenType()

    const onClearFilters = () => {
        store.clear()
        store.submit()
    }

    useEffect(() => {
        form.setFieldsValue(store.filter)
    }, [form, store, store.filter])

    return (
        <Spin spinning={isLoading || store.isLoading || store.isDisabled} indicator={<></>}>
            <FilterButtonPopover
                data-test-id={FILTER}
                buttonClassName={classNames(styles.filter, {[styles.filterMobile]: isMobile})}
                count={store.count}
                formId={formId}
                onClearFiltersClick={onClearFilters}
                onClick={() => store.setOpen(true)}
                open={store.open}
                setOpen={store.setOpen}
                onCloseClick={() => store.setOpen(false)}
                popoverContent={
                    <FilterForm
                        formId={formId}
                        form={form}
                        fields={store.fields}
                        onFinish={() => {
                            store.submit()
                        }}
                        onValuesChange={(changedValues) => {
                            store.setFilter(changedValues)
                        }}
                    />
                }
            />
        </Spin>
    )
})
