import {makeAutoObservable, reaction} from 'mobx'
import {inject, injectable} from 'inversify'
import SendbirdChat, {MetaData, SendbirdChatWith, UserUpdateParams} from '@sendbird/chat'
import {
    GroupChannel,
    GroupChannelCreateParams,
    GroupChannelModule
} from '@sendbird/chat/groupChannel'

import {ProfileStore} from '@/stores/profile/ProfileStore'
import {ProfileStoreSymbol} from '@/pages/Profile'
import {ISendbirdChatStore} from '@/components/containers/SendbirdChatContainer/ISendbirdChatStore'
import {SendbirdPanel} from './models'
import {isEmpty} from 'dna-common'

@injectable()
export class SendbirdChatStore implements ISendbirdChatStore {
    profileStore: ProfileStore
    sb: SendbirdChatWith<GroupChannelModule[]> | null = null
    currentChannel: GroupChannel = null
    open = false
    currentChannelUrl = ''
    panel: SendbirdPanel = SendbirdPanel.CHANNEL_LIST
    isInitialized = false
    private cleanupFunctions: Array<() => void> = []

    constructor(@inject(ProfileStoreSymbol) profileStore: ProfileStore) {
        this.profileStore = profileStore
        makeAutoObservable(this)

        this.cleanupFunctions.push(
            reaction(() => this.merchantId, this.updateMetaData, {
                fireImmediately: false
            })
        )

        // Add global error handler for BroadcastChannel errors
        window.addEventListener('unhandledrejection', (event) => {
            if (
                event.reason instanceof DOMException &&
                event.reason.message?.includes('BroadcastChannel')
            ) {
                event.preventDefault()
            }
        })
    }

    get merchantId(): string {
        return this.profileStore.merchant?.merchantId || this.profileStore?._profileData?.merchantId
    }

    get publicId(): string {
        return this.profileStore._profileData.publicId
    }

    get nickname(): string {
        return this.profileStore.nickname
    }

    initializeSendbirdChat = async () => {
        try {
            if (this.isInitialized && this.sb) {
                await this.sb.connect(this.publicId)
                return true
            }

            this.sb = await SendbirdChat.init({
                appId: _SENDBIRD_APPLICATION_ID_,
                modules: [new GroupChannelModule()],
                newInstance: true,
                options: {
                    websocketPayloadDecompression: true,
                    websocketResponseTimeout: 15000,
                    useMemberInfoInMessage: true,
                    typingIndicatorInvalidateTime: 10000,
                    typingIndicatorThrottle: 1000,
                    sessionTokenRefreshTimeout: 300000
                },
                localCacheEnabled: true,
                debugMode: false
            })

            await this.sb?.connect(this.publicId)

            const profile: UserUpdateParams = {
                nickname: this.nickname
            }
            await this.sb?.updateCurrentUserInfo(profile)

            if (this.merchantId) {
                await this.createMetaData()
            }

            this.isInitialized = true
            return true
        } catch (error) {
            console.error('Failed to initialize Sendbird:', error)
            this.isInitialized = false
            this.sb = null
            throw error
        }
    }

    createMetaData = async () => {
        if (!this.sb?.currentUser) {
            console.error('Cannot create metadata: Sendbird not initialized or user not connected')
            return
        }

        try {
            const metaData: MetaData = {merchant_id: this.merchantId}

            if (isEmpty((this.sb?.currentUser?.metaData as MetaData)?.merchant_id)) {
                await this.sb.currentUser.createMetaData(metaData)
            } else {
                await this.sb.currentUser.updateMetaData(metaData)
            }
        } catch (error) {
            console.error('Failed to create metadata:', error)
            throw error
        }
    }

    updateMetaData = async () => {
        if (!this.sb?.currentUser || !this.merchantId) {
            return
        }

        try {
            const metaData: MetaData = {merchant_id: this.merchantId}
            await this.sb.currentUser.updateMetaData(metaData)
        } catch (error) {
            console.error('Failed to update metadata:', error)
            throw error
        }
    }

    createNewChannel = async () => {
        try {
            await this.createMetaData()

            const params: GroupChannelCreateParams = {} as GroupChannelCreateParams
            params.isDistinct = false
            params.invitedUserIds = ['onboarding_bot', this.publicId || '']
            params.isPublic = false

            const channel = await this.sb?.groupChannel?.createChannel(params)
            this.setCurrentChannel(channel)

            return channel
        } catch (error) {
            throw new Error(error)
        }
    }

    setPanel = (panel: SendbirdPanel) => {
        this.panel = panel
    }

    setCurrentChannelUrl = (currentChannelUrl: string) => {
        this.currentChannelUrl = currentChannelUrl
    }

    setCurrentChannel = (channel: GroupChannel) => {
        this.currentChannel = channel
    }

    setOpen = (open: boolean) => {
        this.open = open
    }

    dispose = async () => {
        if (this.sb) {
            await this.sb.disconnect()
            this.sb = null
            this.isInitialized = false
        }
    }
}
