import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Button, Divider, Spin} from 'antd'

import {Icon, RangePickerContainer, RefreshButton, SelectWithTitleContainer} from '@/components'
import {OnlinePaymentsFilterButton} from '@/pages/OnlinePayments/components/OnlinePaymentsFilterButton/OnlinePaymentsFilterButton'
import translations from './translations'
import styles from './styles.scss'
import {GoogleManagerTagClassNames} from '@/constants'
import {ONLINE_EXPORT} from '@/constants/playwright-ids'

export const OnlinePaymentsTableFilterRangePickerContainerStoreSymbol = Symbol(
    'OnlinePaymentsFilterRangePickerContainerStore'
)
export const OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol = Symbol(
    'OnlinePaymentsTableFilterStatusSelectContainerStore'
)

export const OnlinePaymentsTableFilter = observer(({openExportsModal, onReloadClick, loading}) => {
    return (
        <div className={classNames(styles.root)}>
            <Spin spinning={loading} indicator={<></>}>
                <div className={styles.dFlex}>
                    <RefreshButton onClick={onReloadClick} />
                    <OnlinePaymentsFilterButton />
                    <SelectWithTitleContainer
                        className={classNames(styles.statusSelect)}
                        displayValue={translations().status}
                        injectableIdentifier={
                            OnlinePaymentsTableFilterStatusSelectContainerStoreSymbol
                        }
                    />
                    <div className={styles.divider}></div>
                    <RangePickerContainer
                        showRangePickerTime
                        className={classNames(styles.rangePicker)}
                        injectableIdentifier={
                            OnlinePaymentsTableFilterRangePickerContainerStoreSymbol
                        }
                    />
                </div>
            </Spin>
            <div className='nowrap'>
                <Divider type={'vertical'} />
                <Button
                    data-test-id={ONLINE_EXPORT}
                    className={GoogleManagerTagClassNames.onlinePayments.exportButton}
                    icon={
                        <Icon
                            style={{paddingLeft: 5, pointerEvents: 'none'}}
                            type={'download_document'}
                        />
                    }
                    type={'link'}
                    onClick={() => openExportsModal(true)}
                >
                    <span className={GoogleManagerTagClassNames.onlinePayments.exportButton}>
                        {translations().export}
                    </span>
                </Button>
            </div>
        </div>
    )
})
