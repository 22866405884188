import React from 'react'
import classNames from 'classnames'
import {Icon, Tag} from '@/components'

import {StatusProps} from './props'
import styles from './style.scss'

export const Status: React.FC<StatusProps> = (props) => {
    const {
        status,
        type,
        className,
        iconClassName,
        iconType,
        tagClassName,
        onClick,
        clickable = false
    } = props

    return (
        <Tag
            type={type}
            className={classNames(styles.root, className, tagClassName, {
                [styles.clickable]: clickable
            })}
            data-test-id={`${status}`}
            onClick={onClick}
        >
            <Icon type={iconType} className={iconClassName} />
            <span className={classNames(styles.text, tagClassName)}>{status}</span>
        </Tag>
    )
}
