import React from 'react'
import classNames from 'classnames'
import {Divider, Row} from 'antd'
import {Button, Icon, Popover} from '@/components'
import {useScreenType} from '@/hooks'
import {FilterTitle, FilterFooter} from './components'
import translations from './translations'
import styles from './styles.scss'
import {FILTER_BUTTON} from '@/constants/playwright-ids'

type FilterButtonPopoverProps = {
    isLoading?: boolean
    count?: number
    open?: boolean
    setOpen?: (open: boolean) => void
    formId?: string
    onClick?: () => void
    onCloseClick?: () => void
    buttonClassName?: string
    popoverClassName?: string
    popoverContent?: string | JSX.Element
    onClearFiltersClick?: () => void
}

export const FilterButtonPopover = ({
    isLoading,
    count,
    onCloseClick,
    buttonClassName,
    popoverClassName,
    popoverContent,
    open,
    setOpen,
    onClearFiltersClick,
    formId,
    ...rest
}: FilterButtonPopoverProps) => {
    const {isMobile} = useScreenType()
    return (
        <Popover
            open={open}
            openClassName={popoverClassName}
            overlayClassName={styles.root}
            placement={'bottomRight'}
            content={
                <div className={styles.content}>
                    <FilterTitle onClearFilters={onClearFiltersClick} />
                    {popoverContent}
                    <FilterFooter formId={formId} onClose={onCloseClick} />
                </div>
            }
            trigger={'click'}
            onOpenChange={setOpen}
        >
            <Button
                data-test-id={FILTER_BUTTON}
                className={classNames(styles.filter, {[styles.mobile]: isMobile}, buttonClassName)}
                icon={
                    <Icon
                        type={'filter'}
                        className={classNames(styles.icon, {[styles.isLoading]: isLoading})}
                    />
                }
                disabled={isLoading}
                {...rest}
            >
                <Row
                    className={classNames(
                        {[styles.row]: count > 0},
                        {[styles.rowMobile]: isMobile && count > 0}
                    )}
                    justify={'space-between'}
                >
                    {isMobile ? null : translations().filter}
                    {count ? (
                        <>
                            <Divider className={styles.divider} type={'vertical'} />
                            <span>{count}</span>
                        </>
                    ) : null}
                </Row>
            </Button>
        </Popover>
    )
}
