import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import {TransactionType} from '@/types/transactions'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {ChargebackStatus} from '@/constants/chargeback-status'
import {PaymentCardType} from '@/constants/payment-card-type'
import {ResonCodeCategory} from '@/constants/reason-code-category'
import {CardSchemeType} from '@/constants/card-scheme'
import translations from './translations'

translations.add('ro', {
    siteTitle: 'Portalul comerciantului DNA Payments',
    pages: {
        home: 'Pagina principală',
        activation: 'Activare',
        overview: 'Prezentare generală',
        paymentLinks: 'Link-uri de plată',
        posPayments: 'Plăți POS',
        posAmexPayments: 'POS American Express',
        onlinePayments: 'Plăți online',
        chargebacks: 'Refuzuri la plată',
        virtualTerminal: 'Terminal virtual',
        settlements: 'Reglementări',
        zashEpos: 'Zash ePOS',
        billing: 'Facturare',
        invoices: 'Facturi',
        reports: 'Rapoarte',
        paymentMethods: 'Metode de plată',
        payPal: 'PayPal',
        payByBank: 'Pay by Bank',
        openBanking: 'Open Banking',
        settings: 'Setări',
        developers: 'Dezvoltatori',
        checkoutCustomization: 'Personalizare proces de plată',
        tariffs: 'Tarife',
        profile: 'Profil',
        login: 'Autentificare',
        resetPassword: 'Resetare Parolă',
        twoFA: 'Autentificare cu Două Factori',
        twoFAEnforced: 'Autentificarea în doi pași a fost aplicată',
        recoveryCode: 'Recovery code',
        chooseCompany: 'Choose Company',
        confirmEmail: 'Confirmare email',
        confirmTwoFA: 'Autentificare cu doi factori',
        cardsManagement: 'Gestionarea cardurilor',
        setPassword: 'Setare parolă',
        teamManagement: 'Gestionarea echipei',
        noPermissions: 'Fără permisiuni',
        help: 'Ajutor',
        ecospendReturn: 'Returnare Ecospend',
        noPage: 'Pagină inexistentă',
        accountInfo: 'Informații cont',
        exports: 'Exporturi',
        makeTransfer: 'Make transfer',
        companyProfile: 'Profilul Companiei',
        bankAccounts: 'Conturi bancare',
        bankAccountCreate: '+ Adăugare cont bancar nou',
        changeBankAccountDetails: 'Schimbă detaliile contului bancar',
        posOutlets: 'Puncte POS',
        onlineOutlets: 'Puncte de Vânzare Online'
    },
    constants: {
        onlinePaymentStatus: {
            [OnlinePaymentStatus.all]: 'Toate',
            [OnlinePaymentStatus.auth]: 'În așteptare',
            [OnlinePaymentStatus.created]: 'Creat',
            [OnlinePaymentStatus.credited]: 'Creditat',
            [OnlinePaymentStatus.charge]: 'Încărcat',
            [OnlinePaymentStatus.cancel]: 'Anulat',
            [OnlinePaymentStatus.refund]: 'Rambursat',
            [OnlinePaymentStatus.reject]: 'Respins',
            [OnlinePaymentStatus.failed]: 'Eșuat',
            [OnlinePaymentStatus.new]: 'Nou',
            [OnlinePaymentStatus.threeDSecure]: '3D Secure',
            [OnlinePaymentStatus.tokenized]: 'Tokenizat',
            [OnlinePaymentStatus.verified]: 'Verificat',
            [OnlinePaymentStatus.processing]: 'În procesare',
            [OnlinePaymentStatus.abandoned]: 'Abandonat',
            [OnlinePaymentStatus.other]: 'Altul'
        },
        chargebackStatus: {
            [ChargebackStatus.all]: 'Toate',
            [ChargebackStatus.chargeback]: 'Refuz la plată',
            [ChargebackStatus['chargeback-reversal']]: 'Reversare refuz la plată',
            [ChargebackStatus['second-chargeback-lost']]: 'Al doilea refuz la plată - Pierdut'
        },
        reasonCodeCategory: {
            [ResonCodeCategory['processing-errors']]: 'Erori de procesare',
            [ResonCodeCategory['fraud']]: 'Fraudă',
            [ResonCodeCategory['consumer']]: 'Consumator',
            [ResonCodeCategory['authorisation']]: 'Autorizare'
        },
        posPaymentStatus: {
            all: 'Toate',
            authorised: 'Autorizat',
            cancelled: 'Anulat',
            charged: 'Încărcat',
            declined: 'Respins',
            refunded: 'Rambursat',
            chargeback: 'Retragere',
            'chargeback-reversal': 'Inversare retragere',
            'second-chargeback-lost': 'A doua retragere - pierdut'
        },
        outletStatus: {
            active: 'Activ',
            inactive: 'Inactiv'
        },
        posPaymentStateStatus: {
            [POSPaymentStateStatus.all]: 'Toate',
            [POSPaymentStateStatus.successful]: 'Reușit',
            [POSPaymentStateStatus.failed]: 'Respins'
        },
        payByLinkStatus: {
            active: 'Activ',
            expired: 'Expirat',
            paid: 'Plătit',
            cancelled: 'Anulat',
            viewed: 'Vizualizat',
            attempted: 'Încercat',
            all: 'Toate'
        },
        paymentMethodType: {
            card: 'Card',
            paypal: 'PayPal',
            applepay: 'Apple Pay',
            googlepay: 'Google Pay',
            ecospend: 'Open Banking',
            paybybankapp: 'Pay by Bank',
            klarna: 'Klarna',
            clicktopay: 'ClickToPay',
            astropay: 'AstroPay',
            alipay: 'Alipay',
            wechatpay: 'WeChat Pay'
        },
        fileType: {
            PDF: 'PDF',
            EXCEL: 'EXCEL',
            CSV: 'CSV'
        },
        issuerRegion: {
            Domestic: 'Intern',
            Intra: 'În interior',
            International: 'Internațional'
        },
        cardType: {
            [PaymentCardType.Credit]: 'Credit',
            [PaymentCardType.Debit]: 'Debit'
        },
        cardScheme: {
            [CardSchemeType.Amex]: 'American Express',
            [CardSchemeType.Diners]: 'Diners Club',
            [CardSchemeType.Mastercard]: 'MasterCard',
            [CardSchemeType.UnionPay]: 'UnionPay',
            [CardSchemeType.Visa]: 'Visa'
        },
        captureMethods: {
            'ecom-paybybankapp': 'Pay by Bank app',
            'ecom-openbanking': 'Open Banking',
            'ecom-klarna': 'Klarna',
            'pos-contactless': 'POS fara contact',
            'pos-openbanking': 'Open Banking',
            'pos-alipay': 'Alipay',
            ecom: 'ECOM',
            pos: 'POS',
            stored: 'Stocat',
            moto: 'MOTO',
            recurring: 'Recurent'
        },
        terminalTypes: {
            all: 'Toate',
            ecom: 'ECOM',
            pos: 'POS'
        }
    },
    filter: {
        all: 'Toate',
        allAll: 'Toate/Toate'
    },
    success: {
        paymentChargedSuccessfully: 'Plata a fost încasată cu succes',
        paymentRefundedSuccessfully: 'Restituirea parțială a plății a fost realizată cu succes',
        paymentRefundedFullySuccessfully:
            'Restituirea integrală a plății a fost realizată cu succes',
        newPaymentProcessedSuccessfully: 'Procesarea plății noi a avut succes',
        sendReceiptSuccess: 'Bonul fiscal a fost trimis cu succes.',
        downloadReceiptSuccess: 'Chitanța a fost descărcată cu succes.'
    },
    errors: {
        refundProcessing: 'Tranzacția de rambursare este în curs de procesare.',
        unknown: 'Ups, a apărut o problemă. Echipa noastră deja investighează situația.',
        errorOccured: 'A apărut o eroare. Vă rugăm încercați din nou mai târziu.',
        general:
            'Ne pare rău, nu putem procesa cererea în acest moment. Vă rugăm să încercați din nou mai târziu.',
        maxAttempt: (count) => `Maxim ${count} încercări atinse.`,
        INVALID_CARD_NUMBER: 'Număr de card invalid',
        INVALID_CARDHOLDER_NAME: 'Nume titular card invalid',
        INVALID_SECURE_CODE: 'Cod de securitate invalid',
        INVALID_EXPIRY_DATE: 'Dată de expirare invalidă'
    },
    transactionTypes: {
        [TransactionType.REFUND]: 'Rambursare',
        [TransactionType.ADJUSTMENT]: 'Ajustare',
        [TransactionType.OTHER]: 'Altele',
        [TransactionType.PAYBYLINK]: 'Plătit prin link',
        [TransactionType.RETAIL]: 'Vânzare',
        [TransactionType.SALE]: 'Vânzare',
        [TransactionType.INIT]: 'Inițializare',
        [TransactionType.VERIFICATION]: 'Verificare',
        [TransactionType.TOKENIZATION]: 'Tokenizare',
        [TransactionType.P2P]: 'P2P',
        [TransactionType.RECURRING]: 'Recurent',
        [TransactionType.AUTH]: 'Doar autorizare',
        [TransactionType.PREAUTH as any]: 'Preautorizare'
    },
    transactionTypesTooltip: {
        [TransactionType.SALE]: '“Vânzare” - tranzacția va fi autorizată și compensată automat',
        [TransactionType.AUTH]:
            '“Doar autorizare” - rezervarea fondurilor cu compensare ulterioară manuală (funcția de încasare)',
        [TransactionType.VERIFICATION]: '“Doar verificare” - verificare doar a contului',
        [TransactionType.PREAUTH as any]:
            '“Pre-autorizare” - pre-autorizarea fondurilor cu compensare ulterioară manuală (funcția de încasare)'
    },
    languages: {
        english: 'English',
        spanish: 'Español',
        greek: 'Ελληνικά',
        romanian: 'Română',
        slovak: 'Slovenský'
    },
    action: {
        title: 'Acțiune',
        viewDetails: 'Vizualizare detalii'
    },
    fields: {
        accountNumber: 'Număr de cont',
        cardMask: 'Masca cardului',
        cardScheme: 'Tipul cardului',
        captureMethod: 'Metodă de captură',
        currency: 'Monedă',
        mid: 'MID',
        status: 'Status',
        store: 'Magazin',
        terminalId: 'ID terminal',
        terminalType: 'Tip de terminal',
        transactionId: 'ID tranzacție'
    },
    messages: {
        successExportMessage: (
            <span>
                Solicitarea de descărcare a fost adăugată în coadă pentru procesare. Odată ce
                documentul este pregătit, poate fi descărcat din secțiunea{' '}
                <Link to={ROUTES.exports}>Exporturi</Link> .
            </span>
        ),
        validation: {
            minLength: (n) => `Introduceți cel puțin ${n} caractere`
        }
    }
})
