import React from 'react'
import {Dropdown} from 'antd'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {DownOutlined} from '@ant-design/icons'

import {BankAccountCard} from '@/pages/BankAccounts/components'
import {EmptyBankAccounts} from '@/pages/ChangeBankAccountDetails/components'

import translations from '../../translations'
import {useBankAccountItems} from './useBankAccountItems'
import {BankAccountSelectProps} from './props'
import styles from './BankAccountSelect.scss'

export const BankAccountSelect: React.FC<BankAccountSelectProps> = observer(
    ({accounts, onChange, value, loading}) => {
        const items = useBankAccountItems(accounts, onChange)
        const hasAccounts = accounts?.length > 0
        const showEmptyState = !hasAccounts && !loading

        return (
            <div className={styles.container}>
                {showEmptyState && <EmptyBankAccounts className={styles.alert} />}

                <Dropdown
                    menu={{items}}
                    trigger={['click']}
                    className={styles.dropdown}
                    overlayClassName={classNames(styles.dropdownOverlay, {
                        [styles.displayNone]: !hasAccounts
                    })}
                    disabled={!hasAccounts || loading}
                >
                    <div className={styles.selectButton}>
                        {translations().labels.selectBankAccount}
                        <DownOutlined />
                    </div>
                </Dropdown>

                {value?.bankDetail && (
                    <div className={styles.selectedCard}>
                        <BankAccountCard
                            key={value.bankDetail.id}
                            bank={value.bankDetail.bankName}
                            logo={value.bankDetail.logo}
                            sortCode={value.bankDetail.sortCode}
                            accountNumber={value.bankDetail.bankAccountNumber}
                        />
                    </div>
                )}
            </div>
        )
    }
)
