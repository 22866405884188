import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import translations from './translations'

translations.add('sl', {
    columns: {
        paymentMethod: 'Spôsob platby',
        transactionDate: 'Dátum transakcie',
        settlementDate: 'Dátum zúčtovania',
        address: 'Adresa',
        postcode: 'PSČ',
        mid: 'MID',
        terminalNumber: 'Terminál',
        amount: 'Množstvo',
        acquirerFee: 'Poplatok pre Acquirera (zúčtovateľa)',
        totalFee: 'Poplatok celkom',
        interchangeFee: 'Výmenný poplatok',
        schemeFee: 'Poplatok schémy',
        processingFee: 'Poplatok za spracovanie',
        gatewayFee: 'Poplatok za platobnú bránu',
        authorisationFee: 'Poplatok za autorizáciu',
        blendedFee: 'Zmiešaný poplatok',
        refundFee: 'Poplatok za refundáciu',
        amountToSettle: 'Splatné',
        sortCode: 'Triediaci kód',
        accountNumber: 'Číslo účtu',
        operation: 'Operácia',
        transactionType: 'Typ transakcie',
        transactionId: 'ID transakcie',
        merchantReference: 'Referenčné číslo obchodníka',
        cardScheme: 'Kartová schéma',
        cardMask: 'Maska karty',
        cardholderName: 'Meno držiteľa karty',
        cardType: 'Typ karty',
        isEuropeanCard: 'Európska karta',
        issuerRegion: 'Región vydávateľa',
        issuerCountry: 'Krajina vydávateľa',
        isCorporateCard: 'Firemná karta',
        captureMethod: 'Metóda zachytávania',
        settlementType: 'Typ osídlenia'
    },
    columnsPerDay: {
        transactionDate: 'Dátum transakcie',
        count: 'Číslo',
        amount: 'Množstvo',
        settlementType: 'Typ vyrovnania',
        totalFee: 'Celkový poplatok',
        amountToMerchant: 'Splatné',
        settlementDate: 'Dátum zúčtovania'
    },
    captureMethod: {
        posContactless: 'POS Bezdotykový'
    },
    columnValues: {
        european: 'Európsky',
        nonEuropean: 'Mimo-Európsky',
        corporate: 'Firemný',
        nonCorporate: 'nefiremný',
        today: 'Dnes'
    },
    messages: {
        successExportMessage: (
            <span>
                Vaša žiadosť o stiahnutie bola zaradená do frontu na spracovanie. Keď bude dokument
                pripravený. Môžete si ho stiahnuť z časti <Link to={ROUTES.exports}>Exports</Link>.
            </span>
        )
    }
})
