import React from 'react'
import {observer} from 'mobx-react'
import {Form, Typography} from 'antd'

import translations from '@/pages/BankAccountCreate/translations'

import type {BankAccountCreateFormStaticFieldsProps} from './props'

export const StaticFields = observer(
    ({businessStructure, companyName}: BankAccountCreateFormStaticFieldsProps) => (
        <>
            <Form.Item label={translations().labels.entityType} name='entityType'>
                <Typography.Text>{businessStructure}</Typography.Text>
            </Form.Item>
            <Form.Item label={translations().labels.legalName} name='legalName'>
                <Typography.Text>{companyName}</Typography.Text>
            </Form.Item>
        </>
    )
)
