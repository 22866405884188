import translations from './translations'

translations.add('ro', {
    labels: {
        bank: 'Bancă',
        sortCode: 'Cod Sortare',
        accountNumber: 'Număr de Cont',
        associatedOutlets: 'Puncte Asociate',
        changeSettlementsBankDetails: 'Schimbă contul de decontări',
        changeDirectDebitBankDetails: 'Schimbă contul de debit direct',
        changeInProgress: 'Schimbare în curs',
        noAssociatedOutlets: 'Nu este utilizat pentru decontări'
    }
})
