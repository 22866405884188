import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {Select, Typography} from 'antd'
import {SearchOutlined} from '@ant-design/icons'
import {useScreenType} from '@/hooks'
import translations from './translations'
import {SearchInputProps} from './props'
import styles from './styles.scss'
import {SEARCH_BY_TEXT_FILTER} from '@/constants/playwright-ids'

const {Option} = Select

export const SearchSelect = observer(
    ({values, options, search, onSearch, setValues, ...rest}: SearchInputProps) => {
        const filteredOptions =
            options?.filter((o) => !values.find((item) => item.value === o.value)) || []

        const {isMobile} = useScreenType()

        const onChange = (value) => {
            setValues(value)
        }

        return (
            <Select
                className={classNames(styles.search, {[styles.searchMobile]: isMobile})}
                placeholder={translations().search}
                mode={'multiple'}
                labelInValue
                filterOption={false}
                searchValue={search}
                value={values}
                onChange={onChange}
                onSearch={onSearch}
                suffixIcon={
                    isMobile ? null : (
                        <SearchOutlined className={styles.searchIcon} onClick={null} />
                    )
                }
                open={!!search}
                data-test-id={SEARCH_BY_TEXT_FILTER}
                {...rest}
            >
                {filteredOptions?.map((option) => (
                    <Option key={option.value} value={option.value} data-test-id={option.value}>
                        <Typography.Text className={styles.optionLabel}>
                            {option.label}
                        </Typography.Text>
                        <Typography.Text strong>{search}</Typography.Text>
                    </Option>
                ))}
            </Select>
        )
    }
)
