import React, {useCallback} from 'react'
import {observer} from 'mobx-react'
import {useInjection} from 'dna-react-ioc'
import {Button, Form, Space, Typography} from 'antd'

import {getRouterStore, withTitle} from '@/router/utils'
import {Alert, Card, Container, PageContainer, Select} from '@/components'

import {BankAccountCard} from '@/pages/BankAccounts/components'
import {
    AssociatedOutlets,
    BankAccountSelect,
    BankChangeModals
} from '@/pages/ChangeBankAccountDetails/components'
import {ChangeBankAccountDetailsRequest} from '@/stores/change-bank-account-details/models'
import {GoogleManagerTagClassNames} from '@/constants'
import {hasPermissions} from '@/stores/auth/services'
import {PermissionsMap} from '@/stores/auth/constants/permissions-map'
import {ROUTES} from '@/router/routes'

import translations from './translations'
import {IChangeBankAccountDetails} from './IChangeBankAccountDetails'
import styles from './ChangeBankAccountDetails.scss'

export const ChangeBankAccountDetailsStoreSymbol = Symbol.for('ChangeBankAccountDetailsStoreSymbol')

const ChangeBankAccountDetails = observer(() => {
    const {
        currentBankAccount,
        bankAccounts,
        selectedBankAccount,
        accountType,
        selectBankAccount,
        isLoading,
        onBack,
        openConfirmBankDetailsModal,
        changeBankDetails,
        onCloseConfirmModal,
        modalState,
        onCloseSuccessModal,
        onCloseErrorModal,
        bankAccountsLoading,
        hasSettlementBlocks,
        hasDirectDebitBlocks,
        hasAllBlocks,
        associatedOutlets,
        selectAccountType,
        setSelectAccountType
    } = useInjection<IChangeBankAccountDetails>(ChangeBankAccountDetailsStoreSymbol)
    const [form] = Form.useForm()

    if (
        window.location.pathname.includes('online-outlets') &&
        !hasPermissions([PermissionsMap.merchant.ecom_stores.full])
    ) {
        getRouterStore().push(ROUTES.noPermissions)
    }

    if (
        window.location.pathname.includes('pos-outlets') &&
        !hasPermissions([PermissionsMap.merchant.pos_stores.full])
    ) {
        getRouterStore().push(ROUTES.noPermissions)
    }

    const handleSubmit = (values: Pick<ChangeBankAccountDetailsRequest, 'paymentMethod'>) => {
        openConfirmBankDetailsModal({paymentMethod: values.paymentMethod})
    }

    const isSettlementAccount = accountType === 'settlement'
    const t = translations()
    const labels = t.labels
    const buttons = t.buttons
    const disabled = bankAccounts?.length === 0

    const confirmButtonTag =
        accountType === 'all'
            ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.all.confirmChange
            : accountType === 'settlement'
              ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.settlements
                    .confirmChange
              : GoogleManagerTagClassNames.settings.outlets.changeBankDetails.directDebit
                    .confirmChange

    const cancelButtonTag =
        accountType === 'all'
            ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.all.cancel
            : accountType === 'settlement'
              ? GoogleManagerTagClassNames.settings.outlets.changeBankDetails.settlements.cancel
              : GoogleManagerTagClassNames.settings.outlets.changeBankDetails.directDebit.cancel

    const renderAlert = useCallback(() => {
        let description

        if (selectAccountType === 'all') {
            description = labels.allAccountChangeAlert()
        } else if (!selectAccountType) {
            description = isSettlementAccount
                ? labels.settlementAccountChangeAlert
                : labels.directDebitAccountChangeAlert
        } else {
            description =
                selectAccountType === 'settlement'
                    ? labels.settlementAccountChangeAlert
                    : labels.directDebitAccountChangeAlert
        }

        return <Alert description={description} />
    }, [selectAccountType, isSettlementAccount])

    return (
        <Container className={styles.container}>
            <PageContainer
                title={
                    isSettlementAccount
                        ? t.titles.changeSettlementAccount
                        : t.titles.changeDirectDebitAccount
                }
                isLoading={false}
                onBack={onBack}
            >
                {currentBankAccount ? (
                    <BankAccountCard
                        className={styles.selectedBankAccount}
                        key={currentBankAccount.id}
                        bank={currentBankAccount.bankName}
                        logo={currentBankAccount.logo}
                        sortCode={currentBankAccount.sortCode}
                        accountNumber={currentBankAccount.bankAccountNumber}
                    />
                ) : (
                    <Card loading={true} />
                )}

                <Typography.Title level={4}>{t.titles.changeTo}</Typography.Title>

                <Form form={form} onFinish={handleSubmit} layout='vertical'>
                    <Form.Item
                        name='bankAccount'
                        rules={[{required: true, message: labels.pleaseSelectBankAccount}]}
                    >
                        <BankAccountSelect
                            value={selectedBankAccount}
                            accounts={bankAccounts}
                            onChange={selectBankAccount}
                            loading={bankAccountsLoading}
                        />
                    </Form.Item>

                    <Form.Item
                        name='paymentMethod'
                        label={
                            isSettlementAccount
                                ? labels.applyDirectDebitPayments
                                : labels.applySettlementPayments
                        }
                        rules={[
                            {
                                required: true,
                                message: labels.pleaseSelectBankAccount
                            }
                        ]}
                    >
                        <Select
                            placeholder={labels.selectOption}
                            disabled={disabled}
                            options={[
                                {
                                    label: labels.useBothPayments,
                                    value: 'all',
                                    disabled:
                                        (accountType === 'directDebit' && hasSettlementBlocks) ||
                                        (accountType === 'settlement' && hasDirectDebitBlocks) ||
                                        hasAllBlocks
                                },
                                {
                                    label: labels.useAccountTypeOnly.replace(
                                        '{accountType}',
                                        accountType === 'directDebit'
                                            ? labels.directDebit
                                            : accountType
                                    ),
                                    value: accountType
                                }
                            ]}
                            onChange={setSelectAccountType}
                        />
                    </Form.Item>

                    {selectedBankAccount && (
                        <>
                            {renderAlert()}
                            <AssociatedOutlets outlets={associatedOutlets} />
                        </>
                    )}

                    <br />
                    <Form.Item>
                        <Space>
                            <Button
                                disabled={disabled}
                                type='primary'
                                htmlType='submit'
                                className={confirmButtonTag}
                            >
                                <span className={confirmButtonTag}>{buttons.confirmChange}</span>
                            </Button>
                            <Button className={cancelButtonTag} onClick={onBack}>
                                <span className={cancelButtonTag}>{buttons.cancel}</span>
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>

                <BankChangeModals
                    currentAccount={currentBankAccount}
                    selectedAccount={selectedBankAccount}
                    accountType={selectAccountType}
                    confirmModal={{
                        isOpen: modalState.confirm,
                        isLoading: isLoading,
                        onConfirm: changeBankDetails,
                        onClose: onCloseConfirmModal
                    }}
                    successModal={{
                        isOpen: modalState.success,
                        onClose: onCloseSuccessModal
                    }}
                    errorModal={{
                        isOpen: modalState.error,
                        onClose: onCloseErrorModal
                    }}
                />
            </PageContainer>
        </Container>
    )
})

export default withTitle(ChangeBankAccountDetails)
