import React from 'react'
import translations from './translations'

translations.add('es', {
    labels: {
        noAssociatedSettlementsOutlets: 'No se utiliza para liquidaciones',
        youAreAboutToUpdateYourSettlementDirectDebitBankAccount:
            'Está a punto de actualizar su cuenta bancaria de Liquidaciones (/Débito directo). Por favor,\nrevise los detalles a continuación:',
        youAreAboutToUpdateYourSettlementBankAccount:
            'Está a punto de actualizar su cuenta bancaria de liquidaciones. Por favor, revise los detalles a continuación',
        youAreAboutToUpdateYourDirectDebitBankAccount:
            'Está a punto de actualizar su cuenta bancaria de débito directo. Por favor, revise los detalles a continuación',
        currentBankAccountDetails: 'Detalles actuales de la cuenta bancaria:',
        newBankAccountDetails: 'Nuevos detalles de la cuenta bancaria:',
        byConfirmingYouAuthoriseChange: 'Al confirmar, autoriza este cambio.',
        bankAccountChangeSubmitted:
            'Su solicitud de cambio de cuenta bancaria se ha enviado con éxito.',
        accountWillBeUpdatedForOutlets:
            'La cuenta bancaria de Liquidaciones (/Débito directo) se actualizará para todos los puntos asociados.',
        accountWillBeUpdatedForSettlementOutlets:
            'La cuenta de liquidaciones se actualizará para todos los puntos asociados.',
        accountWillBeUpdatedForDirectDebitOutlets:
            'La cuenta de débito directo se actualizará para todos los puntos asociados.',
        couldNotUpdateBankAccount: 'No pudimos actualizar su cuenta bancaria.',
        nextSteps: 'Próximos pasos:',
        tryAgain: 'Por favor, inténtelo de nuevo;',
        contactSupport:
            'Si el problema persiste, contacte a nuestro equipo de soporte para obtener ayuda.',
        pleaseSelectBankAccount: 'Por favor, seleccione una cuenta bancaria',
        pleasePaymentMethod: 'Por favor, seleccione una opción',
        applySettlementPayments: '¿Aplicar esta cuenta para pagos de liquidaciones?',
        applyDirectDebitPayments: 'Aplicar esta cuenta para pagos por Débito directo?',
        selectOption: 'Seleccione una opción',
        useBothPayments: 'Utilice esta cuenta para pagos de liquidaciones y Débito directo',
        useAccountTypeOnly: 'Utilice esta cuenta solo para {accountType}',
        allAccountChangeAlert: () => (
            <>
                La cuenta de liquidaciones y débito directo se actualizará para todos los puntos
                asociados.
                <ul>
                    <li>Las liquidaciones se enviarán a la nueva cuenta bancaria.</li>
                    <li>Los pagos por débito directo se cobrarán ahora de esta cuenta.</li>
                </ul>
            </>
        ),
        settlementAccountChangeAlert:
            'La cuenta de liquidaciones se cambiará para todos los puntos asociados.\nLos pagos de liquidaciones después de la actualización se enviarán a la nueva cuenta bancaria.',
        directDebitAccountChangeAlert:
            'La cuenta de Débito directo se cambiará para todos los puntos asociados.',
        associatedOutlets: 'Puntos asociados',
        accountNumber: 'Número de cuenta',
        sortCode: 'Código de clasificación',
        selectBankAccount: 'Seleccione la cuenta bancaria listada en "Cuentas bancarias"',
        noBankAccount: 'Aún no ha agregado una nueva cuenta bancaria',
        addBankAccountInstructions:
            'Por favor, vaya a Configuración > Cuentas bancarias y agregue una nueva cuenta para continuar',
        directDebit: 'Débito directo'
    },
    buttons: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        ok: 'Aceptar',
        confirmChange: 'Confirmar cambio'
    },
    titles: {
        bankChangeConfirmation: 'Confirmación de cambio de cuenta bancaria',
        bankChangeFailed: 'Cambio de cuenta bancaria fallido',
        changeSettlementAccount: 'Cambiar cuenta bancaria de Liquidaciones',
        changeDirectDebitAccount: 'Cambiar cuenta bancaria de Débito directo',
        changeTo: 'Cambiar a'
    }
})
