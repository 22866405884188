import {Divider, Input} from 'antd'
import React, {useState, useEffect} from 'react'
import {FixedSizeList as List} from 'react-window'

import {MultiSelectItem} from '../MultiSelectItem'
import translations from '../../translations'
import {MultiSelectContentProps} from './props'
import styles from './styles.scss'
import { SEARCHED_STORE } from '@/constants/playwright-ids'

export const MultiSelectContent: React.FC<MultiSelectContentProps> = props => {
    const {value, options, isSearchable, isOpen, actions, handleMenuClick} = props
    const [searchText, setSearchText] = useState('')
    const [optionsForRender, setOptionsForRender] = useState([])

    const filterOptions = (filterText: string) =>
        options.filter(
            opt => opt.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >= 0
        )

    const filteredOptions = filterOptions(searchText)

    const handleClick = (func: () => void) => (e: any) => {
        e.preventDefault()
        func()
    }

    // we need this lifehack, because in the safari more than 6 items in a list in a popover will not render properly,
    // when the popover is placed at center or right
    const trigger = () => {
        const countOfOptionsForFirstRender = 6
        setOptionsForRender(filteredOptions?.slice(0, countOfOptionsForFirstRender))

        setTimeout(() => {
            setOptionsForRender(filteredOptions)
        }, 500)
    }

    useEffect(() => {
        if (isOpen) {
            trigger()
        }
    }, [isOpen])

    const itemSize = 32
    const height = optionsForRender.length * itemSize

    return (
        <div className={styles.MultiSelectContent}>
            {isSearchable && (
                <>
                    <div className={styles.selectItem}>
                        <Input
                            placeholder={translations().placeholder}
                            value={searchText}
                            onChange={e => {
                                const txt = e.target.value
                                setSearchText(txt)
                                setOptionsForRender(filterOptions(txt))
                            }}
                            data-test-id={SEARCHED_STORE}
                        />
                    </div>
                    <Divider />
                </>
            )}

            {optionsForRender.length > 0 && (
                <>
                    {actions && actions.length > 0 && (
                        <>
                            <div className={styles.selectItem}>
                                {actions.map((action, index) => (
                                    <React.Fragment key={action.text}>
                                        <a href='#' onClick={handleClick(action.onClick)}>
                                            {action.text}
                                        </a>
                                        {index !== actions.length - 1 && (
                                            <Divider type='vertical' />
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                            <Divider />
                        </>
                    )}

                    <List
                        height={height > 192 ? 192 : height}
                        itemCount={optionsForRender.length}
                        itemSize={itemSize}
                        width={300}
                    >
                        {({index, style}) => {
                            const item = optionsForRender[index]
                            return (
                                <MultiSelectItem
                                    key={item.value}
                                    style={style}
                                    title={item.label}
                                    selected={value.indexOf(item.value) >= 0}
                                    onClick={() => handleMenuClick(item.value)}
                                >
                                    {item.label}
                                </MultiSelectItem>
                            )
                        }}
                    </List>
                </>
            )}

            {optionsForRender.length === 0 && <div className={styles.noData}>No data</div>}
        </div>
    )
}
