import {InviteTeammatePermissions} from '@/pages/TeamManagement/components/InviteEditTeammate/models'

export const generatePermissionsArray = (permissions: InviteTeammatePermissions) => {
    console.log(permissions)
    return Object.keys(permissions).reduce((accumulator, current) => {
        const result = []
        if (permissions[current] !== 'no') {
            result.push(`${current}.${permissions[current]}`)
        }
        return [...accumulator, ...result]
    }, [])
}
