import {injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'

import {LoadingState} from '@/types'
import {TerminalType} from '@/constants'
import {IFilterContainerStore, IOutletsContainerStore} from '@/components'
import {fetchDossierMerchantOutlets, OutletModel} from '@/stores/outlets'
import {RouterStore} from '@/router/RouterStore'
import {ROUTES} from '@/router/routes'
import {PaginationStore} from '@/stores'
import {getRouterStore} from '@/router/utils'

@injectable()
export class OutletsStore implements IOutletsContainerStore {
    routerStore: RouterStore = null
    loadingState: LoadingState = LoadingState.IDLE
    _outlets: OutletModel[] = null
    type: TerminalType = null
    paginationContainerStore: PaginationStore = null
    outletListFilterStore: IFilterContainerStore = null

    constructor(
        type: TerminalType,
        routerStore: RouterStore,
        paginationContainerStore: PaginationStore,
        outletListFilterStore?: IFilterContainerStore
    ) {
        makeObservable(this, {
            loadingState: observable,
            _outlets: observable,
            type: observable,
            isLoading: computed,
            outlets: computed,
            onOutletCardClick: action,
            reload: action,
            onBack: action
        })

        this.type = type
        this.routerStore = routerStore
        this.paginationContainerStore = paginationContainerStore
        this.outletListFilterStore = outletListFilterStore

        reaction(
            () => this.outletListFilterStore.submittedFilter,
            () => {
                runInAction(() => {
                    this.paginationContainerStore.setPageNumber(0)
                    this.loadDossierMerchantOutlets()
                })
            }
        )

        reaction(
            () => this.paginationContainerStore.paging,
            () => {
                this.loadDossierMerchantOutlets()
            }
        )
    }

    get outlets(): OutletModel[] {
        if (this._outlets === null && this.loadingState === LoadingState.IDLE) {
            this.loadDossierMerchantOutlets()
        }

        return this._outlets?.filter((outlet) => outlet.id) || []
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    onOutletCardClick = (id: number): void => {
        if (this.type === TerminalType.POS) {
            this.routerStore.push(`${ROUTES.settings.posOutlets}/${id}`)
        } else {
            this.routerStore.push(`${ROUTES.settings.onlineOutlets}/${id}`)
        }
    }

    loadDossierMerchantOutlets = async () => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await fetchDossierMerchantOutlets({
                type: this.type,
                page: this.paginationContainerStore?.paging?.page,
                size: this.paginationContainerStore?.paging?.size,
                ...this.outletListFilterStore.getFilterForBackend()
            })

            if (error) {
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._outlets = result.rows
                    this.paginationContainerStore.setTotal(result.totalRows)
                })

                if (!result) return
            }
        } catch (error) {
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }

    reload = async () => {
        await this.loadDossierMerchantOutlets()
    }

    onBack = async () => {
        getRouterStore().push(ROUTES.settings.base)
    }
}
