import {inject, injectable} from 'inversify'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {openErrorNotification, openSuccessNotification} from '@/utils'
import {DATE_FORMAT} from '@/constants'
import {OnlinePaymentsExportsModalInterface} from './OnlinePaymentsExportsModalInterface'
import {getExportColumns} from '@/pages/OnlinePayments/services/ui-utils'
import {SelectItem} from '@/components/dumb/Select'
import {OnlinePaymentsModalOptionsType} from '@/stores/transactions/OnlinePaymentsExportsStore/models'
import {exportOnlinePayments} from '@/stores/transactions/OnlinePaymentsExportsStore/services'
import {OnlinePaymentsStoreSymbol, TOnlinePaymentsStore} from '@/pages/OnlinePayments'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import translations from './translations'

export type OnlinePaymentsExportOptionsType = Record<
    keyof OnlinePaymentsModalOptionsType,
    {value: string; label: string}
>

@injectable()
export class OnlinePaymentsExportsModalStore implements OnlinePaymentsExportsModalInterface {
    constructor(
        @inject(OnlinePaymentsStoreSymbol) onlinePaymentsStore: TOnlinePaymentsStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStoreInterface
    ) {
        this.onlinePaymentsStore = onlinePaymentsStore
        this.profileStore = profileStore
        makeObservable(this, {
            fields: observable,
            isUploading: observable,
            selectedExportType: observable,
            selectExportType: action.bound,
            selectOption: action.bound,
            handleCancel: action.bound
        })
    }

    onlinePaymentsStore: TOnlinePaymentsStore
    profileStore: ProfileStoreInterface
    options: OnlinePaymentsExportOptionsType = getExportColumns()
    fields: string[] = Object.keys(this.options)
    isUploading = false
    exportTypeOptions = [
        {value: 'all', label: translations().all(Object.keys(this.options).length)},
        {value: 'custom', label: translations().custom}
    ]
    selectedExportType: 'all' | 'custom' = 'all'

    get isButtonDisabled() {
        return this.isUploading || !this.fields.length
    }

    public selectExportType = (value: 'all' | 'custom') => {
        this.selectedExportType = value
        if (value === 'all') this.fields = Object.keys(this.options)
        else this.fields = []
    }

    public selectOption = (option: SelectItem) => {
        if (this.fields?.includes(option.value)) {
            const index = this.fields.indexOf(option.value)
            this.fields.splice(index, 1)
        } else this.fields.push(option.value)
    }

    public handleCancel = () => {
        this.onlinePaymentsStore.openExportsModal(false)
        this.fields = Object.keys(this.options)
    }

    public onFormSubmit = async () => {
        const {from, to} = this.onlinePaymentsStore.exportsRequest
        const currentMerchant = this.profileStore?.merchant

        const params = {
            ...this.onlinePaymentsStore.exportsRequest,
            from: from.format(),
            to: to.format(),
            fileName: `${currentMerchant.name} ${from.format(DATE_FORMAT)} - ${to.format(
                DATE_FORMAT
            )}.csv`,
            format: 'csv',
            fields: [...this.fields]
        }

        try {
            runInAction(() => {
                this.isUploading = true
            })
            const {result, error} = await exportOnlinePayments(params)
            if (error) throw new Error(error.message)
            openSuccessNotification(translations().successExportMessage)
        } catch (error) {
            openErrorNotification(error?.message)
        } finally {
            runInAction(() => {
                this.isUploading = false
            })
        }
    }
}
