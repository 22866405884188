import translations from '../translations'
import {TagType} from '@/components/dumb/Tag/props'
import {DossierBankAccountType} from '@/stores/bank-accounts'

export const normalizeAccountType = (type: string) => {
    if (type === 'direct-debit' || type === 'direct debit' || type === 'directDebit') {
        return 'direct-debit'
    }

    return type
}

export const getAccountTypeLabel = (accountType: DossierBankAccountType) => {
    switch (normalizeAccountType(accountType)) {
        case 'direct-debit':
            return translations().accountTypes.directDebit
        case 'settlements':
            return translations().accountTypes.settlements
        default:
            return null
    }
}

export const getAccountTypeTagType = (accountType: DossierBankAccountType) => {
    switch (normalizeAccountType(accountType)) {
        case 'direct-debit':
            return TagType.Success
        case 'settlements':
            return TagType.Processing
        default:
            return TagType.Default
    }
}
