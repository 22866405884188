import {postWithAuth} from '@/api'
import {
    AddBankAccountRequest,
    AddBankAccountResponse,
    ValidationResult
} from '@/stores/bank-account-create/models'

export const addBankAccount = (data: AddBankAccountRequest) =>
    postWithAuth<AddBankAccountResponse>('/oppapi/v1/merchant-info/bank-accounts', data)

export const validateBankAccountDetails = (
    data: Pick<AddBankAccountRequest, 'bankAccountNumber' | 'sortCode'>
) => postWithAuth<ValidationResult>('/oppapi/v1/merchant-info/bank-accounts/check', data)
