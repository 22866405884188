import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import _ from 'lodash'
import {useInjection} from 'dna-react-ioc'
import {Input, MultiSelect, Select, Text} from '@/components'
import {useScreenType} from '@/hooks'
import {Col, Divider, Form, Radio, Row, Typography} from 'antd'
import {TInviteEditTeammateStore} from '@/pages/TeamManagement/components/InviteEditTeammate/TInviteEditTeammateStore'
import {InviteEditTeammateModalProps} from '@/pages/TeamManagement/components/InviteEditTeammate/props'
import {customPermissions, financePermissions, Role} from '@/stores/team-management/constants'
import {hasProducts} from '@/stores/auth/services'
import {ProductsMap} from '@/stores/auth/constants/products-map'
import {renderMobileSectionPermissionsFormItem, renderSectionPermissionsFormItem} from './services'
import {InviteTeammatePermissions} from './models'
import {AccessType} from './constants'
import translations from './translations'
import styles from './styles.scss'
import {
    ADMIN_ROLE,
    FULL_ACCESS_RADIO_BUTTON,
    INVITE_AS_RADIO,
    NO_ACCESS_RADIO_BUTTON,
    READ_ACCESS_RADIO_BUTTON,
    RESTRICTED_ROLE,
    TEAMMATE_EMAIL,
    TEAMMATE_FIRST_NAME,
    TEAMMATE_LAST_NAME,
    TEAMMATE_ROLE_SELECT,
    TEAMMATE_ROLE_TYPE, TEAMMATES_STORES
} from '@/constants/playwright-ids'

const SECTIONS_CONFIG = [
    {
        code: 'overview',
        accessLevels: ['no', 'read']
    },
    {
        code: 'pos_payments',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'online_payments',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'payment_links',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'virtual_terminal',
        accessLevels: ['no', 'read', 'full'],
        readDisabled: true
    },
    {
        code: 'zash_epos',
        accessLevels: ['no', 'read', 'full'],
        readDisabled: true
    },
    {
        code: 'pos_amex_payments',
        accessLevels: ['no', 'read', 'full'],
        requiresProduct: 'amex'
    },
    {
        code: 'chargebacks',
        accessLevels: ['no', 'read'],
        requiresAdmin: true
    },
    {
        code: 'settlements',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'invoices',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'reports',
        accessLevels: ['no', 'read']
    },
    {
        code: 'merchant.profile',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'merchant.bank_accounts',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'merchant.pos_stores',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'merchant.ecom_stores',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'payment_methods',
        accessLevels: ['no', 'read', 'full']
    },
    {
        code: 'teammates',
        accessLevels: ['no', 'read', 'full'],
        requiresAdmin: true
    }
]

export const InviteEditTeammate = observer(
    ({injectableIdentifier, formId, form, type}: InviteEditTeammateModalProps) => {
        const {isMobile} = useScreenType()
        const {teammate, inviteEditTeammate, selectStores, isAdmin, setStores, stores} =
            useInjection<TInviteEditTeammateStore>(injectableIdentifier)

        const isTeammateAdmin = teammate?.role === Role.admin

        /**
         *  UI states
         */
        const [inviteAs, setInviteAs] = useState<AccessType>(
            teammate?.inviteAs || isAdmin ? AccessType.admin : AccessType.restricted
        )
        const [selectedRole, setSelectedRole] = useState<Role>(teammate?.role || Role.finance)
        const [selectedPermissions, setSelectedPermissions] = useState<InviteTeammatePermissions>(
            teammate?.permissions || financePermissions
        )

        useEffect(() => {
            const shopIds =
                teammate?.shopIds?.length > 0
                    ? Array.from(new Set(teammate?.shopIds))
                    : selectStores?.map((store) => store.value)

            form.setFieldValue('shopIds', shopIds)
            setStores(shopIds)
        }, [teammate, selectStores])

        useEffect(() => {
            form.resetFields(
                Object.keys(selectedPermissions).map(
                    (key) =>
                        ['permissions', key] as ['permissions', keyof typeof selectedPermissions]
                )
            )
        }, [selectedPermissions])

        useEffect(() => {
            form.resetFields(['role'])
        }, [selectedRole])

        useEffect(() => {
            form.resetFields(['inviteAs'])
        }, [inviteAs])

        useEffect(() => {
            teammate && setInviteAs(teammate.inviteAs)
            teammate && setSelectedRole(teammate.role)
            teammate && setSelectedPermissions(teammate.permissions)
            form.resetFields(['email', 'firstName', 'lastName'])
        }, [teammate])

        useEffect(() => {
            return () => {
                setInviteAs(
                    teammate?.inviteAs || isAdmin ? AccessType.admin : AccessType.restricted
                )
                setSelectedRole(teammate?.role || Role.finance)
                setSelectedPermissions(teammate && teammate.permissions)
            }
        }, [])

        const onInvitedAsEditChange = (inviteAs: AccessType) => {
            if (inviteAs === AccessType.restricted) {
                setSelectedRole(null)
            }

            setInviteAs(inviteAs)
        }

        const onFinish = (values) => {
            values.email = values.email?.toLowerCase()
            inviteEditTeammate(values).then(() => {
                setSelectedRole(Role.finance)
                setInviteAs(isAdmin ? AccessType.admin : AccessType.restricted)
                setSelectedPermissions(financePermissions)
                form.resetFields()
            })
        }

        const onPermissionChange = (sectionCode, permission) => {
            const _selectedPermissions = {...selectedPermissions, [sectionCode]: permission}
            setSelectedPermissions(_selectedPermissions)
            if (_.isEqual(_selectedPermissions, financePermissions)) {
                setSelectedRole(Role.finance)
            } else {
                setSelectedRole(Role.custom)
            }
        }

        const onRoleChange = (role) => {
            if (role === Role.finance) {
                setSelectedPermissions(financePermissions)
            } else {
                setSelectedPermissions(customPermissions)
            }
            setSelectedRole(role)
        }

        const renderDesktopPermissions = () => {
            return (
                <>
                    <Row>
                        <Col sm={10}>
                            <Text>{translations().formFields.sectionName}</Text>
                        </Col>
                        <Col sm={14}>
                            <Row>
                                <Col sm={8}>
                                    <Text type={'secondary'} data-test-id={NO_ACCESS_RADIO_BUTTON}>
                                        {translations().formFields.noAccess}
                                    </Text>
                                </Col>
                                <Col sm={8}>
                                    <Text
                                        type={'secondary'}
                                        data-test-id={READ_ACCESS_RADIO_BUTTON}
                                    >
                                        {translations().formFields.readAccess}
                                    </Text>
                                </Col>
                                <Col sm={8}>
                                    <Text
                                        type={'secondary'}
                                        data-test-id={FULL_ACCESS_RADIO_BUTTON}
                                    >
                                        {translations().formFields.fullAccess}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider className={styles.sectionPermissionsDivider} />
                    {SECTIONS_CONFIG.map((section) => {
                        if (section.requiresAdmin && !isAdmin) return null
                        if (
                            section.requiresProduct &&
                            !hasProducts([ProductsMap[section.requiresProduct]])
                        )
                            return null

                        const accessLevels = section.accessLevels.map((value) => ({
                            value,
                            disabled: value === 'read' && section.readDisabled
                        }))

                        return renderSectionPermissionsFormItem({
                            sectionName: translations().formFields.sections[section.code],
                            sectionCode: section.code,
                            accessLevels,
                            onChange: onPermissionChange,
                            initialValue: selectedPermissions[section.code],
                            disabled: section.requiresAdmin && !isAdmin
                        })
                    })}
                </>
            )
        }

        const renderMobilePermissions = () => {
            return (
                <>
                    {SECTIONS_CONFIG.map((section) => {
                        if (section.requiresAdmin && !isAdmin) return null
                        if (
                            section.requiresProduct &&
                            !hasProducts([ProductsMap[section.requiresProduct]])
                        )
                            return null

                        const accessLevels = section.accessLevels.map((value) => ({
                            label: translations().formFields[`${value}Access`],
                            value,
                            disabled: value === 'read' && section.readDisabled
                        }))

                        return renderMobileSectionPermissionsFormItem(
                            translations().formFields.sections[section.code],
                            section.code,
                            accessLevels,
                            onPermissionChange,
                            selectedPermissions[section.code]
                        )
                    })}
                </>
            )
        }

        const renderCreateUserAccessTypes = () => (
            <Radio.Group onChange={(event) => setInviteAs(event?.target?.value)}>
                {isAdmin && (
                    <Radio value={AccessType.admin}>
                        {translations().formFields.inviteAsAdmin}
                    </Radio>
                )}
                <Radio value={AccessType.restricted}>
                    {translations().formFields.inviteAsRestrictedAccess}
                </Radio>
            </Radio.Group>
        )

        const renderEditUserAccessTypes = () => {
            return (
                <Radio.Group onChange={(event) => onInvitedAsEditChange(event?.target?.value)}>
                    {(isAdmin || (!isAdmin && isTeammateAdmin)) && (
                        <Radio value={AccessType.admin} data-test-id={ADMIN_ROLE}>
                            {translations().formFields.inviteAsAdmin}
                        </Radio>
                    )}
                    {(isAdmin || (!isAdmin && !isTeammateAdmin)) && (
                        <Radio value={AccessType.restricted} data-test-id={RESTRICTED_ROLE}>
                            {translations().formFields.inviteAsRestrictedAccess}
                        </Radio>
                    )}
                </Radio.Group>
            )
        }

        return (
            <Form
                id={formId}
                form={form}
                layout={'vertical'}
                onFinish={onFinish}
                autoComplete='off'
            >
                <Form.Item
                    label={translations().formFields.email}
                    name={'email'}
                    initialValue={teammate?.email}
                    rules={[
                        {required: true, message: translations().formErrors.emailRequired},
                        {type: 'email', message: translations().formErrors.enterValidEmail}
                    ]}
                >
                    <Input
                        disabled={!!teammate}
                        placeholder={translations().formFields.email}
                        data-test-id={TEAMMATE_EMAIL}
                    />
                </Form.Item>
                <Form.Item
                    label={translations().formFields.firstName}
                    name={'firstName'}
                    initialValue={teammate?.firstName}
                    rules={[{required: true, message: translations().formErrors.firstNameRequired}]}
                >
                    <Input
                        placeholder={translations().formFields.firstName}
                        data-test-id={TEAMMATE_FIRST_NAME}
                    />
                </Form.Item>
                <Form.Item
                    label={translations().formFields.lastName}
                    name={'lastName'}
                    initialValue={teammate?.lastName}
                    rules={[{required: true, message: translations().formErrors.lastNameRequired}]}
                >
                    <Input
                        placeholder={translations().formFields.lastName}
                        data-test-id={TEAMMATE_LAST_NAME}
                    />
                </Form.Item>
                <Form.Item
                    name={'shopIds'}
                    label={translations().formFields.store}
                    rules={[{required: true, message: translations().formErrors.shopRequired}]}
                    data-test-id={TEAMMATES_STORES}
                    getValueProps={(value) => ({value: value ?? stores})}
                    getValueFromEvent={(v) => {
                        setStores(v)
                        form.setFieldValue('shopIds', v)
                        return v
                    }}
                >
                    <MultiSelect
                        isSearchable={true}
                        filterOption={(input, option) =>
                            String(option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        placeholder={translations().formFields.store}
                        options={selectStores}
                    />
                </Form.Item>
                <Typography.Title level={5}>
                    {translations().formFields.teammatePermissions}
                </Typography.Title>
                <Form.Item
                    name={'inviteAs'}
                    initialValue={inviteAs}
                    rules={[{required: true, message: translations().formErrors.lastNameRequired}]}
                    data-test-id={INVITE_AS_RADIO}
                >
                    {type === 'create'
                        ? renderCreateUserAccessTypes()
                        : renderEditUserAccessTypes()}
                </Form.Item>
                {inviteAs === AccessType.restricted && (
                    <>
                        <Form.Item
                            name={'role'}
                            initialValue={selectedRole}
                            rules={[
                                {required: true, message: translations().formErrors.roleRequired}
                            ]}
                            data-test-id={TEAMMATE_ROLE_SELECT}
                        >
                            <Select
                                placeholder={translations().formFieldsPlaceholders.role}
                                options={[
                                    {
                                        label: translations().formFields.accountantAccess,
                                        value: 'finance'
                                    },
                                    {
                                        label: translations().formFields.customAccess,
                                        value: 'custom'
                                    }
                                ]}
                                onChange={onRoleChange}
                                data-test-id={TEAMMATE_ROLE_TYPE}
                            />
                        </Form.Item>

                        <Typography.Title level={5}>
                            {translations().formFields.grantedPermissions}
                        </Typography.Title>
                        {!isMobile && renderDesktopPermissions()}
                        {isMobile && renderMobilePermissions()}
                    </>
                )}
            </Form>
        )
    }
)
