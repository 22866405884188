import {register} from 'dna-react-ioc'
import {ISettingsStore} from '@/pages/Settings'
import {SettingsStore} from '@/stores/settings'
import {SettingsStoreSymbol} from '@/pages/Settings/Settings'
import {CompanyProfileStoreSymbol} from '@/pages/CompanyProfile/CompanyProfile'
import {ICompanyProfileStore} from '@/pages/CompanyProfile'
import {CompanyProfileStore} from '@/stores/company-profile'
import {IBankAccountsStore} from '@/pages/BankAccounts'
import {BankAccountsStoreSymbol} from '@/pages/BankAccounts/BankAccounts'
import {BankAccountsStore} from '@/stores/bank-accounts'
import {
    POSOutletListFilterStoreSymbol,
    POSOutletsPaginationStoreSymbol,
    POSOutletsStatusStoreSymbol,
    POSOutletsStoreSymbol
} from '@/pages/POSOutlets/POSOutlets'
import {OutletListFilterStore, OutletsStore} from '@/stores/outlets'
import {
    IFilterContainerStore,
    IOutletDetailsContainerStore,
    IOutletListFilterContainerStore,
    IOutletsContainerStore,
    PaginationContainerStoreType
} from '@/components'
import {TerminalType} from '@/constants'
import {POSOutletDetailsStoreSymbol} from '@/pages/POSOutletDetails/POSOutletDetails'
import {OutletDetailsStore} from '@/stores/outlets/OutletDetailsStore'
import {
    OnlineOutletListFilterStoreSymbol,
    OnlineOutletsPaginationStoreSymbol,
    OnlineOutletsStatusStoreSymbol,
    OnlineOutletsStoreSymbol
} from '@/pages/OnlineOutlets/OnlineOutlets'
import {OnlineOutletDetailsStoreSymbol} from '@/pages/OnlineOutletDetails/OnlineOutletDetails'
import {RouterStoreSymbol} from '@/router/RouterStore'
import {PaginationStore, StatusStore} from '@/stores'
import {ChangeBankAccountDetailsStoreSymbol} from '@/pages/ChangeBankAccountDetails/ChangeBankAccountDetails'
import {IChangeBankAccountDetails} from '@/pages/ChangeBankAccountDetails'
import {ChangeBankAccountDetailsStore} from '@/stores/change-bank-account-details'
import {ProfileStoreSymbol} from '@/pages/Profile'
import {DossierMerchantInfoStoreSymbol} from '@/stores/profile/DossierMerchantInfo'

export const settingsState = () => {
    register<ISettingsStore>(SettingsStoreSymbol).to(SettingsStore).inSingletonScope()
    register<ICompanyProfileStore>(CompanyProfileStoreSymbol)
        .to(CompanyProfileStore)
        .inSingletonScope()
    register<IBankAccountsStore>(BankAccountsStoreSymbol).to(BankAccountsStore).inSingletonScope()

    register<IOutletsContainerStore>(POSOutletsStoreSymbol)
        .toDynamicValue(
            (context) =>
                new OutletsStore(
                    TerminalType.POS,
                    context.container.get(RouterStoreSymbol),
                    context.container.get(POSOutletsPaginationStoreSymbol),
                    context.container.get(POSOutletListFilterStoreSymbol)
                )
        )
        .inSingletonScope()

    register<IOutletListFilterContainerStore>(POSOutletListFilterStoreSymbol)
        .toDynamicValue(() => new OutletListFilterStore())
        .inSingletonScope()

    register<IOutletDetailsContainerStore>(POSOutletDetailsStoreSymbol)
        .toDynamicValue(() => new OutletDetailsStore('pos'))
        .inSingletonScope()

    register<PaginationContainerStoreType>(POSOutletsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()

    register<StatusStore>(POSOutletsStatusStoreSymbol).to(StatusStore).inSingletonScope()

    register<IOutletsContainerStore>(OnlineOutletsStoreSymbol)
        .toDynamicValue(
            (context) =>
                new OutletsStore(
                    TerminalType.ECOM,
                    context.container.get(RouterStoreSymbol),
                    context.container.get(OnlineOutletsPaginationStoreSymbol),
                    context.container.get(OnlineOutletListFilterStoreSymbol)
                )
        )
        .inSingletonScope()

    register<IFilterContainerStore>(OnlineOutletListFilterStoreSymbol)
        .toDynamicValue(() => new OutletListFilterStore())
        .inSingletonScope()

    register<IOutletDetailsContainerStore>(OnlineOutletDetailsStoreSymbol)
        .toDynamicValue(() => new OutletDetailsStore('online'))
        .inSingletonScope()

    register<StatusStore>(OnlineOutletsStatusStoreSymbol).to(StatusStore).inSingletonScope()

    register<PaginationContainerStoreType>(OnlineOutletsPaginationStoreSymbol)
        .to(PaginationStore)
        .inSingletonScope()

    register<IChangeBankAccountDetails>(ChangeBankAccountDetailsStoreSymbol)
        .toDynamicValue(
            (context) =>
                new ChangeBankAccountDetailsStore(
                    context.container.get(POSOutletDetailsStoreSymbol),
                    context.container.get(OnlineOutletDetailsStoreSymbol),
                    context.container.get(BankAccountsStoreSymbol),
                    context.container.get(ProfileStoreSymbol),
                    context.container.get(DossierMerchantInfoStoreSymbol)
                )
        )
        .inSingletonScope()
}
