import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Form, Space} from 'antd'

import {Alert, Container} from '@/components'

import {
    AlertSection,
    DynamicFields,
    FormActionButtons,
    StaticFields
} from '@/pages/BankAccountCreate/components'
import {
    CreateBankAccountFormValues,
    CreateBankAccountStep
} from '@/stores/bank-account-create/models'
import {useBankAccountValidation} from '@/pages/BankAccountCreate/hooks/useBankAccountValidation'

import translations from '../../translations'
import {BankAccountCreateFormProps} from './props'
import styles from './BankAccountCreateForm.scss'
import {GoogleManagerTagClassNames} from '@/constants'

export const BankAccountCreateForm: React.FC<BankAccountCreateFormProps> = observer(({store}) => {
    const [form] = Form.useForm<CreateBankAccountFormValues>()
    const validateBankAccount = useBankAccountValidation(store)

    const handleSubmit = async (values: CreateBankAccountFormValues) => {
        store.addBankAccount({
            bankAccountType: values.bankAccountType,
            bankAccountNumber: values.bankAccountNumber,
            sortCode: values.sortCode
        })
    }

    const handleFieldChange = React.useCallback(async () => {
        const accountNumber = form.getFieldValue('bankAccountNumber')
        const sortCode = form.getFieldValue('sortCode')

        if (!accountNumber || !sortCode) return

        try {
            await form.validateFields(['bankAccountNumber', 'sortCode'])
            validateBankAccount(accountNumber, sortCode)
        } catch (error) {
            console.error('Validation error:', error)
        }
    }, [form, validateBankAccount])

    const isFormDisabled =
        store.step === CreateBankAccountStep.failure || store.step === CreateBankAccountStep.success

    useEffect(() => {
        form.setFieldsValue({
            bankAccountType: store.isSoleTrader ? 'personal' : 'business'
        })
    }, [store.isSoleTrader])

    return (
        <Container className={styles.container}>
            <Space direction='vertical' size='large' className={styles.formSpace}>
                <Form
                    form={form}
                    layout='horizontal'
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    onFinish={handleSubmit}
                    requiredMark={false}
                    initialValues={{
                        entityType: store.dossierData?.businessStructureValue,
                        legalName: store.dossierData?.companyName,
                        bankAccountType: store.isSoleTrader ? 'personal' : 'business'
                    }}
                    className={styles.form}
                >
                    {store.step === CreateBankAccountStep.initialForm && (
                        <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Alert
                                    title={translations().infoTop.title}
                                    description={
                                        form.getFieldValue('bankAccountType') === 'personal'
                                            ? translations().infoTop.topDescriptionSoleTrader()
                                            : translations().infoTop.topDescription()
                                    }
                                />
                            )}
                        </Form.Item>
                    )}

                    <StaticFields
                        businessStructure={store.dossierData?.businessStructureValue}
                        companyName={store.dossierData?.companyName}
                    />

                    <DynamicFields
                        isFormDisabled={isFormDisabled}
                        isSoleTrader={store.isSoleTrader}
                        isAllowedToToggleAccountType={store.isAllowedToToggleAccountType}
                        isValidating={store.isValidating}
                        onFieldChange={handleFieldChange}
                        form={form}
                    />

                    <AlertSection step={store.step} isValidationPassed={store.isValidationPassed} />

                    <Form.Item>
                        <FormActionButtons
                            step={store.step}
                            onBack={store.onBack}
                            onSuccessAction={store.onSuccessActionButtonClick}
                            openChat={store.openChat}
                            isValidationPassed={store.isValidationPassed}
                            startVerificationTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .startVerification
                            }
                            proceedToOutletsTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .proceedToOutlets
                            }
                            contactSupportTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .contactSupport
                            }
                            initialFormCancelTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .initialFormCancel
                            }
                            successCancelTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .successCancel
                            }
                            failureCancelTag={
                                GoogleManagerTagClassNames.settings.bankAccounts.addNewBankAccount
                                    .failureCancel
                            }
                        />
                    </Form.Item>
                </Form>
            </Space>
        </Container>
    )
})
