import 'reflect-metadata'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {injectable} from 'inversify'
import {ApiResponse} from 'back-connector'
import {createSaveAndPlanRefreshAuthCredentials, error, isEmpty} from 'dna-common'
import {
    ACCESS_TOKEN_KEY,
    AUTH_ERROR_KEY,
    MERCHANT_ID_KEY,
    REFRESH_ACCESS_TOKEN_BEFORE,
    REFRESH_TOKEN_KEY,
    RETRY_REFRESH_ACCESS_TOKEN_COUNT,
    RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT
} from '@/constants/auth-constants'
import {UNAUTHORISED, UNKNOWN} from '@/constants'
import {storage, userHash} from '@/services/storage'
import {openErrorNotification, openSuccessNotification, tryParse} from '@/utils'
import {
    getHomeUrl,
    getPermissions,
    isLoginPage,
    navigateUserAfterLogin,
    savePermissions
} from '@/stores/auth/services/utils'
import {ROUTES} from '@/router/routes'
import {getRouterStore} from '@/router/utils'
import translations from '../../pages/Login/translations'
import {
    fetchUserAsDefault,
    login,
    logout,
    refreshTokenRequest,
    updateToken
} from '@/stores/auth/services'
import {
    LoginRequest,
    LoginResponse,
    UpdateMerchantResponse,
    UserData,
    UpdateTokenRequest,
    GrantTypeType
} from '@/stores/auth/models'
import {ProfileStoreSymbol} from '@/pages/Profile/Profile'
import {ProfileStoreInterface} from '@/pages/Profile'
import {SelectItem} from '@/components/dumb/Select'
import {subscribe} from 'dna-react-ioc'
import {AuthStoreInterface} from '@/stores'
import {TwoFAModalStoreInterface} from '@/stores/profile/TwoFAModalStore/TwoFAModalStoreInterface'
import {TwoFAModalStoreSymbol} from '@/pages/Profile/components/TwoFA/components/TwoFAModal'
import {AuthNotificationStoreSymbol} from '@/layouts'
import {AuthNotificationStore} from '@/stores/notification/AuthNotificationStore'
import {LogoutRateLimiterPayload} from '@/services/logout/LogoutRateLimiter'
import {RATE_LIMIT_EXCEEDED} from '@/stores/auth/constants/error-codes'

export const AuthStoreSymbol = Symbol('AuthStoreSymbol')

const createMerchantPortalSaveAndPlanRefreshAuthCredentials = (
    logOut: (shouldDeleteToken: boolean, payload: LogoutRateLimiterPayload) => Promise<void>
): any =>
    createSaveAndPlanRefreshAuthCredentials({
        refreshAccessToken: async () => {
            const refreshToken = storage.get(REFRESH_TOKEN_KEY)
            if (!refreshToken) {
                return null
            }

            const {result, error, status} = await refreshTokenRequest(refreshToken)

            if (error) {
                return {error: {type: status === 401 ? UNAUTHORISED : UNKNOWN}}
            }

            subscribe<AuthNotificationStore>(AuthNotificationStoreSymbol).refreshOnTokenChange(
                result
            )
            return {response: mapTokens(result)}
        },
        logOut: (payload) =>
            logOut(true, {trigger: 'refresh-token-failure', additionalInfo: payload}),
        refreshAccessTokenBefore: REFRESH_ACCESS_TOKEN_BEFORE,
        retryRefreshAccessTokenCount: RETRY_REFRESH_ACCESS_TOKEN_COUNT,
        retryRefreshAccessTokenTimeout: RETRY_REFRESH_ACCESS_TOKEN_TIMEOUT,
        saveTokens: ({accessToken, refreshToken}) => {
            storage.set(ACCESS_TOKEN_KEY, accessToken)
            storage.set(REFRESH_TOKEN_KEY, refreshToken)
        },
        expiresAtKeyPrefix: userHash
    })

const mapTokens = (result: LoginResponse) =>
    result && {
        accessToken: result.access_token,
        refreshToken: result.refresh_token,
        expiresIn: result.expires_in
    }

const authResponseFromStorage = storage.get('authResponse')

@injectable()
export class AuthStore implements AuthStoreInterface {
    twoFAModalStore: TwoFAModalStoreInterface
    isLoading = false
    requireAuth = false
    requireAdditionalAuth = false
    email: string = storage.get('email') || ''
    isAdmin = storage.get('is_admin') === 'true'
    permissions = []
    authResponse: LoginResponse & Pick<LoginRequest, 'username'> =
        (Boolean(authResponseFromStorage) && JSON.parse(authResponseFromStorage)) || null
    savedUpdatedTokenResult: LoginResponse = null
    _error: Error = null

    constructor() {
        makeObservable(this, {
            email: observable,
            isAdmin: observable,
            isLoading: observable,
            permissions: observable,
            requireAuth: observable,
            requireAdditionalAuth: observable,
            authResponse: observable,
            _error: observable,

            error: computed,
            isAuthenticated: computed,

            login: action.bound,
            logout: action.bound,
            interceptor: action.bound,
            updateTokenAfterLogin: action.bound,
            updateTokenTwoFA: action.bound,
            fetchSetDefaultMerchant: action.bound,
            updateToken: action.bound,
            enforceTokenTwoFA: action.bound,
            onTwoFAModalClose: action.bound,
            resetAuthFlags: action.bound,
            handleAuthMethod: action.bound,
            setLoading: action.bound,
            backToLogin: action,
            clearStorage: action,
            clearStore: action,
            setError: action
        })

        const {
            planRefreshAuthCredentials,
            saveAndPlanRefreshAuthCredentials,
            isAccessTokenExpired
        } = createMerchantPortalSaveAndPlanRefreshAuthCredentials(this.logout)

        this.isAccessTokenExpired = isAccessTokenExpired
        this.saveAndPlanRefreshAuthCredentials = (tokenData: LoginResponse) => {
            saveAndPlanRefreshAuthCredentials(mapTokens(tokenData))
        }

        window.addEventListener('storage', this.storageListener)

        if (this.isAuthenticated) {
            planRefreshAuthCredentials({
                trigger: 'AuthStore.constructor'
            })
            runInAction(() => {
                this.permissions = getPermissions() || []
            })
        }
    }

    get error() {
        // read from local storage
        // check if exists and parse
        if (storage.get(AUTH_ERROR_KEY)) {
            this._error = tryParse<Error>(storage.get(AUTH_ERROR_KEY))
        }
        return this._error
    }

    get merchants(): SelectItem[] {
        if (storage.get(ACCESS_TOKEN_KEY)) {
            const {profileData} = subscribe<ProfileStoreInterface>(ProfileStoreSymbol)

            return profileData?.merchants?.map((merchant) => ({
                label: merchant.name,
                value: merchant.merchantId
            }))
        }

        return []
    }

    get isAuthenticated() {
        return Boolean(
            this.email &&
                storage.get(ACCESS_TOKEN_KEY) &&
                // !this.isAccessTokenExpired() &&
                !this.requireAdditionalAuth &&
                !this.requireAuth
        )
    }

    get getHomeUrl() {
        return getHomeUrl(this.permissions)
    }

    async login(data: LoginRequest): Promise<void> {
        try {
            this.setLoading(true)

            const {error, result} = await login(data)

            if (error) {
                openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
                return
            }

            if (this.handleAuthMethod(result, data.username)) {
                return
            }

            if (!this.isUserAllowed(result.permissions)) {
                openErrorNotification(translations().permissionErrorText)
                return
            }

            this.saveUserData({username: data.username, result})
            this.resetAuthFlags()
            navigateUserAfterLogin(this.permissions)
        } catch (error) {
            openErrorNotification(translations().permissionErrorText)
            return
        } finally {
            this.setLoading(false)
        }
    }

    handleAuthMethod(result: LoginResponse, username: string): boolean {
        const {auth_method, additional_auth_requirement} = result

        if (
            auth_method === '2fa' ||
            auth_method === '2fa_enforced' ||
            additional_auth_requirement === 'merchant_selection'
        ) {
            this.saveAndPlanRefreshAuthCredentials(result)
            this.authResponse = {...result, username: username || ''}
            storage.set('authResponse', JSON.stringify(this.authResponse))

            if (auth_method === '2fa') {
                this.requireAuth = true
                this.navigateTo(ROUTES.twoFA)
                return true
            } else if (auth_method === '2fa_enforced') {
                this.requireAuth = true
                this.navigateTo(ROUTES.twoFAEnforced)
                return true
            } else if (additional_auth_requirement === 'merchant_selection') {
                this.requireAdditionalAuth = true
                this.navigateTo(ROUTES.chooseCompany)
                return true
            }
        }

        return false
    }

    async updateTokenAfterLogin(
        data: Pick<LoginRequest, 'merchantId' | 'isDefault'>
    ): Promise<void> {
        try {
            this.setLoading(true)

            const request: UpdateTokenRequest = {
                grant_type: 'password',
                merchantId: data.merchantId
            }

            const {result, error} = await updateToken(request)

            if (data.isDefault) {
                await this.fetchSetDefaultMerchant(data)
            }

            if (error) {
                openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
                return
            }

            this.resetAuthFlags()

            this.saveUserData({username: this.authResponse?.username || '', result})
            storage.set(MERCHANT_ID_KEY, data.merchantId)

            navigateUserAfterLogin(this.permissions)
        } catch (error) {
            this.authResponse = null
            openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
        } finally {
            this.setLoading(false)
        }
    }

    async updateTokenTwoFA(twoFAcode: string, grant_type: GrantTypeType): Promise<void> {
        try {
            this.setLoading(true)

            const request: UpdateTokenRequest = {
                twoFAcode: twoFAcode,
                grant_type: grant_type
            }

            const {result, error} = await updateToken(request)

            if (error || !result) {
                openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
                return
            }

            if (result.additional_auth_requirement === 'merchant_selection') {
                this.saveAndPlanRefreshAuthCredentials(result)
                this.requireAdditionalAuth = true

                this.navigateTo(ROUTES.chooseCompany)
                return
            }

            if (!this.isUserAllowed(result.permissions)) {
                openErrorNotification(translations().permissionErrorText)
                return
            }

            this.saveUserData({username: this.authResponse?.username || '', result})
            this.resetAuthFlags()
            navigateUserAfterLogin(this.permissions)
        } catch (error) {
            this.authResponse = null
            openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
        } finally {
            this.setLoading(false)
        }
    }

    async updateToken(data: Pick<LoginRequest, 'merchantId'>): Promise<void> {
        try {
            this.setLoading(true)

            const {result, error} = await updateToken({...data, grant_type: 'password'})

            if (error) {
                openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
            } else {
                storage.set(MERCHANT_ID_KEY, data.merchantId)
                this.saveUserDataOnMerchantChange(result)
                navigateUserAfterLogin(this.permissions)
            }
        } catch (error) {
            openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
        } finally {
            this.setLoading(false)
        }
    }

    async enforceTokenTwoFA(code: string): Promise<boolean> {
        const {settings, selectedFrequency, setRecoveryCodes} = this.twoFAModalStore
        const {statuses, types, frequencies} = settings

        const statusId = statuses.find(({value}) => value === 'ENABLED')?.id
        const typeId = types.find(({value}) => value === 'AUTHENTICATOR')?.id

        const request: UpdateTokenRequest = {
            grant_type: 'authorize_code',
            twoFAcode: code,
            twoFAStatusId: statusId,
            twoFATypeId: typeId,
            twoFAFrequencyId: selectedFrequency
        }

        try {
            const {result, error} = await updateToken(request)

            if (error) {
                openErrorNotification(translations().ourSupportTeamIsHereToHelp(error.message), 15)
                return false
            }

            this.savedUpdatedTokenResult = result

            if (result.recovery_code?.code) {
                setRecoveryCodes([{code: result.recovery_code.code}])
            }

            const updatedSettings = {
                frequency: frequencies.find(({id}) => id === request.twoFAFrequencyId),
                type: types.find(({id}) => id === request.twoFATypeId),
                status: statuses.find(({id}) => id === request.twoFAStatusId)
            }

            this.twoFAModalStore.twoFAStore.setUserSettings(updatedSettings)

            return true
        } catch (error) {
            openErrorNotification(error.message, 5)
            return false
        }
    }

    initTwoFAModalStore = () => {
        this.twoFAModalStore = subscribe<TwoFAModalStoreInterface>(TwoFAModalStoreSymbol)
    }

    onTwoFAModalClose = () => {
        if (!this.savedUpdatedTokenResult) {
            return
        }

        if (this.savedUpdatedTokenResult.additional_auth_requirement === 'merchant_selection') {
            this.requireAdditionalAuth = true
            this.navigateTo(ROUTES.chooseCompany)
            return
        }

        this.saveUserData({
            username: this.authResponse?.username || '',
            result: this.savedUpdatedTokenResult
        })

        this.requireAdditionalAuth = false
        this.requireAuth = false

        navigateUserAfterLogin(this.permissions)
    }

    onTwoFAModalOpen = () => {
        this.twoFAModalStore.twoFAStore.setIsModalOpen(true)
    }

    resetAuthFlags = () => {
        this.requireAdditionalAuth = false
        this.requireAuth = false
    }

    isUserAllowed = (permissions: string[]): boolean => {
        return permissions?.length > 0
    }

    navigateTo = (route: string) => {
        getRouterStore().push(route)
    }

    setLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    backToLogin = async () => {
        await this.logoutRequest({
            trigger: 'back-to-login'
        })
        this.requireAdditionalAuth = false
        this.requireAuth = false
        this.clearStore()
        this.clearStorage()
        getRouterStore().replace(ROUTES.login)
    }

    fetchSetDefaultMerchant = async (data: Pick<LoginRequest, 'merchantId' | 'isDefault'>) => {
        try {
            const {error} = await fetchUserAsDefault(data)

            if (error) {
                openErrorNotification(error.message, 5)
            } else {
                openSuccessNotification(translations().successfullyUpdatedDefaultMerchant, 5)
            }
        } catch (error) {
            openErrorNotification(error.message, 5)
        }
    }

    saveUserData = ({username, result}: UserData) => {
        const email = username.toLocaleLowerCase()
        const isAdmin = result.is_admin
        const permissions = result.permissions
        storage.set('email', email)
        storage.set('is_admin', String(isAdmin))
        savePermissions(permissions)
        this.saveAndPlanRefreshAuthCredentials(result)

        runInAction(() => {
            this.email = email
            this.permissions = permissions
            this.isAdmin = isAdmin
        })
    }

    saveUserDataOnMerchantChange = (result: UpdateMerchantResponse) => {
        const isAdmin = result?.is_admin
        const permissions = result?.permissions || []
        storage.set('is_admin', String(isAdmin))
        savePermissions(permissions)

        runInAction(() => {
            this.permissions = permissions
            this.isAdmin = isAdmin
        })
    }

    saveAndPlanRefreshAuthCredentials(tokenData: LoginResponse) {
        // override
    }

    isAccessTokenExpired() {
        // override
        return false
    }

    // handler triggered in other tabs
    storageListener = async (e: StorageEvent) => {
        if (storage.isEqual(e.key, ACCESS_TOKEN_KEY)) {
            const newValue = tryParse<string>(e.newValue)

            if (isEmpty(newValue)) {
                if (!isLoginPage()) {
                    this.logout(false, {trigger: 'other-tab-logout'})
                }
            } else {
                if (isLoginPage()) {
                    runInAction(() => {
                        this.email = storage.get('email')
                        this.permissions = getPermissions()
                        this.isAdmin = storage.get('is_admin')
                    })
                    navigateUserAfterLogin(getPermissions())
                }
            }
        }
    }

    async logout(shouldDeleteToken = true, payload: LogoutRateLimiterPayload) {
        if (isLoginPage()) {
            return
        }

        let errorWhileDeletingToken = false
        if (shouldDeleteToken) {
            errorWhileDeletingToken = await this.logoutRequest(payload)
        }

        if (!errorWhileDeletingToken) {
            this.clearStorage()
            this.clearStore()

            window.location.href = ROUTES.login
        }
    }

    /**
     * Logout request, returns error object if there occurs any issue during logout
     */
    logoutRequest = async (payload: LogoutRateLimiterPayload): Promise<any> => {
        // check if user is authenticated
        if (!this.isAuthenticated) {
            return
        }

        try {
            const result = await logout(payload)
            if (result && result.error) {
                throw new Error(result.error.message)
            }
            // else if (result.status === 401) {
            //     throw new Error('Unauthorized')
            // }
        } catch (err) {
            error('Error while deleting token: ', err)
            if (err.code !== RATE_LIMIT_EXCEEDED) {
                this.setError(err)
            }
            return err
        }

        return null
    }

    interceptor = async <T>(promise?: Promise<ApiResponse<T>>, payload?: any) => {
        const response = await promise

        if (response.status === 401 && this.isAuthenticated) {
            openErrorNotification(translations().sessionExpired)
            await this.logout(false, {
                trigger: 'api-call',
                apiCallPayload: payload
            })
        }

        return response
    }

    clearStorage = () => {
        storage.set(ACCESS_TOKEN_KEY, '')
        storage.set(REFRESH_TOKEN_KEY, '')
        storage.set(MERCHANT_ID_KEY, '')
        storage.set('email', '')
        storage.set('permissions', '')
        storage.set('roles', '')
        storage.set('is_admin', '')
        storage.set('authResponse', null)
        storage.set(AUTH_ERROR_KEY, null)
    }

    clearStore = () => {
        this.email = ''
        this.permissions = []
        this.isAdmin = false
        this.isLoading = false
        this.authResponse = null
        this.requireAuth = false
        this.requireAdditionalAuth = false
        this.savedUpdatedTokenResult = null
    }

    setError = (error: Error) => {
        // write to local storage
        storage.set(AUTH_ERROR_KEY, JSON.stringify(error))
        this._error = error
    }

    destroy() {
        window.removeEventListener('storage', this.storageListener)
    }
}
