import React from 'react'
import classNames from 'classnames'
import {Select as AntSelect} from 'antd'
import {Icon} from '@/components/dumb/Icon'
import {SelectProps} from './props'
import styles from './styles.scss'

export const Select: React.FC<SelectProps> = ({
    value,
    options,
    className,
    popupClassName,
    onChange,
    placeholder,
    children,
    ...rest
}) => {
    if (children && children.length > 0) {
        return (
            <div className={styles.selectContainer}>
                <AntSelect
                    {...rest}
                    className={classNames(styles.select, className)}
                    suffixIcon={<Icon type={'caret_down'} />}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    popupClassName={classNames(styles.dropdown, popupClassName)}
                >
                    {children}
                </AntSelect>
            </div>
        )
    }

    return (
        <div className={styles.selectContainer}>
            <AntSelect
                {...rest}
                className={classNames(styles.select, className)}
                suffixIcon={<Icon type={'caret_down'} />}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                options={options}
            />
        </div>
    )
}
