import translations from './translations'

translations.add('sl', {
    labels: {
        bank: 'Banka',
        sortCode: 'Kód Pobočky',
        accountNumber: 'Číslo Účtu',
        associatedOutlets: 'Pridružené Prevádzky',
        changeSettlementsBankDetails: 'Zmeniť účet zúčtovania',
        changeDirectDebitBankDetails: 'Zmeniť účet priameho inkasa',
        changeInProgress: 'Prebieha zmena',
        noAssociatedOutlets: 'Nepoužíva sa pre zúčtovania'
    }
})
