import '@/services/leaflet'
import moment from 'moment-timezone'
import uuid from 'uuid/v4'
import React, {Suspense, useEffect} from 'react'
import {observer} from 'mobx-react'
import {DNAIoCProvider} from 'dna-react-ioc'
import {useRoutes, useLocation, useNavigate} from 'react-router-dom'
import {ConfigProvider, notification} from 'antd'
import * as Sentry from '@sentry/react'
import {GlobalLoading} from '@/components/dumb/GlobalLoading'
import {TranslationBuilder} from '@/components/dumb/Translation'
import {appState} from '@/services/app-state-init/app-state'
import {ROUTES} from '@/router/routes'
import {routesMap} from '@/router/routes-map'
import {BrowserRouter} from '@/router/BrowserRouter'
import {getRouterStore} from '@/router/utils'
import {storage} from '@/services/storage'
import {themeConfig} from '@/constants/theme'
import {VISITOR_ID_KEY} from '@/constants/auth-constants'
import {useNotificationStore} from '@/stores/notification'
import {log} from 'dna-common'
import {useAuthStore} from '@/stores'
import {hasPermissions} from '@/stores/auth/services'
import {routesPermissionMap} from '@/router/routes-permission-map'
import {useWaitForTimeoutOrChange} from '@/hooks'
import {setNotificationInstance} from './utils'
import {ErrorBoundaryWrapper} from '@/components/containers/ErrorBoundaryWrapper'

moment.locale('en_US', {
    week: {
        dow: 1
    }
})

appState()

if (window.location.hostname !== 'localhost') {
    // initialize Sentry
    Sentry.init({
        dsn: _SENTRY_DSN_,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                maskAllInputs: false
            })
        ],
        environment: _SENTRY_ENVIRONMENT_,
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'https//:test-portal.dnapayments.com',
            'https//:portal.dnapayments.com'
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const useInterceptRoutes = () => {
    const authStore = useAuthStore()
    const navigate = useNavigate()
    const location = useLocation()
    const {isAppBlocked} = useNotificationStore()
    const element = useRoutes(routesMap)
    const unAuthRoutes = [
        ROUTES.login,
        ROUTES.resetPassword,
        ROUTES.resetPasswordFailure,
        ROUTES.twoFA,
        ROUTES.recoveryCode,
        ROUTES.chooseCompany,
        ROUTES.confirmEmail,
        ROUTES.twoFAEnforced,
        ROUTES.confirmTwoFA,
        ROUTES.setPassword,
        ROUTES.setPasswordSuccess
    ]

    // useEffect hook to handle navigation logic based on authentication status, app state, and current route.
    // 1. First, we check if the application is currently blocked (e.g., maintenance mode or system issue).
    //    If `isAppBlocked` is true, the user is redirected to the maintenance page (ROUTES.maintenance).
    // 2. If the app is not blocked, the next check handles the authentication flow:
    //    a. If the user is *not* authenticated (`authStore.isAuthenticated` is false) and they are
    //       trying to access a protected route (i.e., a route not included in the `unAuthRoutes` array),
    //       they are redirected to the login page (ROUTES.login).
    //    b. If the user *is* authenticated and they try to access a route for unauthenticated users
    //       (i.e., included in the `unAuthRoutes` array) or the maintenance route, they are redirected
    //       to their designated home page, which is provided by `authStore.getHomeUrl`.
    // 3. The effect will run whenever the authentication status (`authStore.isAuthenticated`), the current
    //    route (`location.pathname`), or the app's blocked status (`isAppBlocked`) changes. The `navigate`
    //    function is also included in the dependency array to ensure any navigation updates trigger the effect properly.
    // if a user does not have permissions to a given route

    useEffect(() => {
        // set sentry user
        if (window.location.hostname !== 'localhost') {
            const email = storage.get('email')
            log('Setting user as: ', email)
            Sentry.setUser({email})
        }

        if (isAppBlocked) {
            navigate(ROUTES.maintenance)
        } else {
            if (!authStore.isAuthenticated && !unAuthRoutes.includes(location.pathname)) {
                navigate(ROUTES.login)
            } else if (authStore.isAuthenticated) {
                if (
                    unAuthRoutes.includes(location.pathname) ||
                    location.pathname === ROUTES.maintenance
                ) {
                    navigate(authStore.getHomeUrl)
                } else if (!hasPermissions(routesPermissionMap[location.pathname] || [])) {
                    let urlToRedirect = ROUTES.noPermissions

                    if (location.pathname === ROUTES.home) {
                        urlToRedirect = authStore.getHomeUrl
                    }

                    navigate(urlToRedirect)
                }
            }
        }
    }, [authStore.isAuthenticated, location.pathname, isAppBlocked, navigate])

    return element
}

const AppComponent = observer(() => {
    const {error} = useAuthStore()
    console.log('AUTH ERROR', error)

    if (error) {
        throw error
    }
    // to disable block app uncomment please
    // const routeResult = useRoutes(routesMap)

    // and comment this
    const routeResult = useInterceptRoutes()
    const path = useLocation().pathname
    const navigate = useNavigate()

    // to disable block app uncomment please
    // const isAppBlocked = false

    // and comment this
    const {isAppBlocked} = useNotificationStore()

    const conditionMet = useWaitForTimeoutOrChange(isAppBlocked)

    // useEffect(() => {
    //     if (!routeResult) navigate(ROUTES.noPage)
    // }, [isAppBlocked, routeResult, path, navigate])

    return <Suspense fallback={<GlobalLoading />}>{conditionMet ? routeResult : null}</Suspense>
})

const App = () => {
    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        setNotificationInstance(api as any)
    }, [api])

    useEffect(() => {
        if (TranslationBuilder.getIsGreekLanguageSelected()) {
            document.body.classList.add('greek-lang')
        }
    }, [])

    useEffect(() => {
        if (!Boolean(storage.get(VISITOR_ID_KEY))) {
            storage.set(VISITOR_ID_KEY, uuid())
        }
    }, [])

    return (
        <DNAIoCProvider>
            <ErrorBoundaryWrapper>
                <BrowserRouter routerStore={getRouterStore()}>
                    <ConfigProvider theme={themeConfig}>
                        <AppComponent />
                        {contextHolder}
                    </ConfigProvider>
                </BrowserRouter>
            </ErrorBoundaryWrapper>
        </DNAIoCProvider>
    )
}

export {App}
