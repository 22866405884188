import React from 'react'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/router/routes'
import {TransactionType} from '@/types/transactions'
import {POSPaymentStateStatus} from '@/constants/pos-payment-state-status'
import {OnlinePaymentStatus} from '@/constants/online-payment-status'
import {ChargebackStatus} from '@/constants/chargeback-status'
import {PaymentCardType} from '@/constants/payment-card-type'
import {ResonCodeCategory} from '@/constants/reason-code-category'
import {CardSchemeType} from '@/constants/card-scheme'
import translations from './translations'

translations.add('sl', {
    siteTitle: 'Merchant Portal DNA Payments',
    pages: {
        home: 'Domov',
        activation: 'Aktivácia',
        overview: 'Prehľad',
        paymentLinks: 'Platobné prepojenia',
        posPayments: 'POS payments',
        posAmexPayments: 'POS American Express',
        onlinePayments: 'Online platby',
        chargebacks: 'Povračila',
        virtualTerminal: 'Virtuálny terminál',
        settlements: 'Zúčtovanie, platba',
        zashEpos: 'Zash ePOS',
        billing: 'Fakturácia',
        invoices: 'Faktúry',
        reports: 'Správy',
        paymentMethods: 'Spôsoby platby',
        payPal: 'PayPal',
        payByBank: 'Pay by Bank',
        openBanking: 'Open Banking',
        settings: 'Nastavenia',
        developers: 'Vývojári',
        checkoutCustomization: 'Prispôsobenie platby',
        tariffs: 'Tarify',
        profile: 'Profil',
        login: 'Prihlásiť sa',
        resetPassword: 'Obnoviť heslo',
        twoFA: 'Dvostopenjska Preverba Identitete',
        twoFAEnforced: 'Uvedena je bila dvostopenjska avtentikacija',
        recoveryCode: 'Recovery code',
        chooseCompany: 'Choose Company',
        confirmEmail: 'Potvrdiť e-mail',
        confirmTwoFA: 'Dvojna avtentikacija',
        cardsManagement: 'Správa kariet',
        makeTransfer: 'Make transfer',
        setPassword: 'Nastaviť heslo',
        teamManagement: 'Riadenie tímu',
        noPermissions: 'Bez povolení',
        help: 'Pomoc',
        ecospendReturn: 'Ekovýdavková návratnosť',
        noPage: 'Žiadna strana',
        accountInfo: 'Informácie o účte',
        exports: 'Vývoz',
        companyProfile: 'Profil Spoločnosti',
        bankAccounts: 'Bankové účty',
        bankAccountCreate: '+ Pridať nový bankový účet',
        changeBankAccountDetails: 'Zmeniť údaje bankového účtu',
        posOutlets: 'POS Prevádzky',
        onlineOutlets: 'Online Prevádzky'
    },
    constants: {
        onlinePaymentStatus: {
            [OnlinePaymentStatus.all]: 'Všetko',
            [OnlinePaymentStatus.auth]: 'Prebiehajúci',
            [OnlinePaymentStatus.created]: 'Vytvorené',
            [OnlinePaymentStatus.credited]: 'Pripísané',
            [OnlinePaymentStatus.charge]: 'Naúčtovaný',
            [OnlinePaymentStatus.cancel]: 'Zrušené',
            [OnlinePaymentStatus.refund]: 'Vrátený',
            [OnlinePaymentStatus.reject]: 'Zamietnuté',
            [OnlinePaymentStatus.failed]: 'Zlyhalo',
            [OnlinePaymentStatus.new]: 'Nové',
            [OnlinePaymentStatus.threeDSecure]: '3D zabezpečené',
            [OnlinePaymentStatus.tokenized]: 'Tokenizované',
            [OnlinePaymentStatus.verified]: 'Overené',
            [OnlinePaymentStatus.processing]: 'Spracovanie',
            [OnlinePaymentStatus.abandoned]: 'Opustené',
            [OnlinePaymentStatus.other]: 'Ostatné'
        },
        chargebackStatus: {
            [ChargebackStatus.all]: 'Vse',
            [ChargebackStatus.chargeback]: 'Povračilo',
            [ChargebackStatus['chargeback-reversal']]: 'Obrat povračila',
            [ChargebackStatus['second-chargeback-lost']]: 'Drugo povračilo - Izgubljeno'
        },
        reasonCodeCategory: {
            [ResonCodeCategory['processing-errors']]: 'Napake pri obdelavi',
            [ResonCodeCategory['fraud']]: 'Prevara',
            [ResonCodeCategory['consumer']]: 'Potrošnik',
            [ResonCodeCategory['authorisation']]: 'Avtorizacija'
        },

        posPaymentStatus: {
            all: 'Všetko',
            authorised: 'Pooblaščeno',
            cancelled: 'Zrušené',
            charged: 'Naúčtovaný',
            declined: 'Zamietnuté',
            refunded: 'Vrátený',
            chargeback: 'Stornovanie',
            'chargeback-reversal': 'Obrátené stornovanie',
            'second-chargeback-lost': 'Druhé stornovanie - stratené'
        },
        outletStatus: {
            active: 'Aktívny',
            inactive: 'Neaktívny'
        },
        posPaymentStateStatus: {
            [POSPaymentStateStatus.all]: 'Všetko',
            [POSPaymentStateStatus.successful]: 'Úspešné',
            [POSPaymentStateStatus.failed]: 'Zamietnuté'
        },
        payByLinkStatus: {
            active: 'Aktívny',
            expired: 'Platnosť vypršala',
            paid: 'Zaplatené',
            cancelled: 'Zrušené',
            viewed: 'Zobrazené',
            attempted: 'Pokus vykonaný',
            all: 'Všetko'
        },
        paymentMethodType: {
            card: 'Karta',
            paypal: 'PayPal',
            applepay: 'Apple Pay',
            googlepay: 'Google Pay',
            ecospend: 'Open Banking',
            paybybankapp: 'Pay by Bank',
            klarna: 'Klarna',
            clicktopay: 'ClickToPay',
            astropay: 'AstroPay',
            alipay: 'Alipay',
            wechatpay: 'WeChat Pay'
        },
        fileType: {
            PDF: 'PDF',
            EXCEL: 'EXCEL',
            CSV: 'CSV'
        },
        issuerRegion: {
            Domestic: 'Domáci',
            Intra: 'Vnútorný',
            International: 'Medzinárodný'
        },
        cardType: {
            [PaymentCardType.Credit]: 'Kredit',
            [PaymentCardType.Debit]: 'Debet'
        },
        cardScheme: {
            [CardSchemeType.Amex]: 'American Express',
            [CardSchemeType.Diners]: 'Diners Club',
            [CardSchemeType.Mastercard]: 'MasterCard',
            [CardSchemeType.UnionPay]: 'UnionPay',
            [CardSchemeType.Visa]: 'Visa'
        },
        captureMethods: {
            'ecom-paybybankapp': 'Pay by Bank app',
            'ecom-openbanking': 'Open Banking',
            'ecom-klarna': 'Klarna',
            'pos-contactless': 'POS Bezdotykový',
            'pos-openbanking': 'Open Banking',
            'pos-alipay': 'Alipay',
            ecom: 'ECOM',
            pos: 'POS',
            stored: 'Stored',
            moto: 'MOTO',
            recurring: 'Recurring'
        },
        terminalTypes: {
            all: 'Všetko',
            ecom: 'ECOM',
            pos: 'POS'
        }
    },
    filter: {
        all: 'Všetko',
        allAll: 'Všetko/Všetko'
    },
    success: {
        paymentChargedSuccessfully: 'Platba bola úspešne účtovaná',
        paymentRefundedSuccessfully: 'Čiastočné vrátenie platby bolo úspešné',
        paymentRefundedFullySuccessfully: 'Platba v plnej výške bola úspešná',
        newPaymentProcessedSuccessfully: 'Spracovanie novej platby bolo úspešné',
        sendReceiptSuccess: 'Potvrdenie bolo úspešne odoslané.',
        downloadReceiptSuccess: 'Račun je bil uspešno prenesen.'
    },
    errors: {
        refundProcessing: 'Transakcia vrátenia platby sa momentálne spracováva.',
        unknown: 'Oojoj, niečo sa pokazilo. Náš tím sa tým už zaoberá.',
        errorOccured: 'Vyskytla sa chyba, skúste to znova neskôr.',
        general: 'Ľutujeme, momentálne nemôžeme spracovať žiadosť. Skúste neskôr prosím.',
        maxAttempt: (count) => `Doseženih največ ${count} poskusov.`,
        INVALID_CARD_NUMBER: 'Neplatné číslo karty',
        INVALID_CARDHOLDER_NAME: 'Neplatné meno držiteľa karty',
        INVALID_SECURE_CODE: 'Neplatné bezpečnostné kódy',
        INVALID_EXPIRY_DATE: 'Neplatný dátum ukončenia'
    },
    transactionTypes: {
        [TransactionType.REFUND]: 'Vrátenie',
        [TransactionType.ADJUSTMENT]: 'Úprava',
        [TransactionType.OTHER]: 'Ostatné',
        [TransactionType.PAYBYLINK]: 'Zaplatiť odkazom',
        [TransactionType.RETAIL]: 'Predaj',
        [TransactionType.SALE]: 'Predaj',
        [TransactionType.INIT]: 'Init',
        [TransactionType.VERIFICATION]: 'Overovanie',
        [TransactionType.TOKENIZATION]: 'Tokenizácia',
        [TransactionType.P2P]: 'P2P',
        [TransactionType.RECURRING]: 'Opakujúce sa',
        [TransactionType.AUTH]: 'Len povolenie',
        [TransactionType.PREAUTH as any]: 'Predbežné povolenie'
    },
    transactionTypesTooltip: {
        [TransactionType.SALE]: '“Predaj” - transakcia bude autorizovaná a zúčtovaná automaticky',
        [TransactionType.AUTH]:
            '“Len autorizácia” - rezervácia finančných prostriedkov s ďalším manuálnym zúčtovaním (funkcia zaúčtovania)',
        [TransactionType.VERIFICATION]: '“Iba overenie” - iba overenie účtu',
        [TransactionType.PREAUTH as any]:
            '“Predbežná autorizácia” - financovanie predbežnej autorizácie s ďalším manuálnym zúčtovaním (funkcia zaúčtovania)'
    },
    languages: {
        english: 'English',
        spanish: 'Español',
        greek: 'Ελληνικά',
        romanian: 'Română',
        slovak: 'Slovenský'
    },
    action: {
        title: 'Akcia',
        viewDetails: 'Vizualizare detalii'
    },
    fields: {
        accountNumber: 'Číslo účtu',
        cardMask: 'Maska karty',
        cardScheme: 'Kartová schéma',
        captureMethod: 'Metóda zachytávania',
        currency: 'Mena',
        mid: 'MID',
        status: 'Stav',
        store: 'Obchod',
        terminalId: 'ID terminálu',
        terminalType: 'Typ terminálu',
        transactionId: 'ID transakcie'
    },
    messages: {
        successExportMessage: (
            <span>
                Your download request has been queued for processing. Once the document is ready, it
                can be downloaded from the <Link to={ROUTES.exports}>Exports</Link> section.
            </span>
        ),
        validation: {
            minLength: (n) => `Vnesite vsaj ${n} znake`
        }
    }
})
