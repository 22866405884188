import {isEmpty} from 'dna-common'
import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable, reaction, runInAction} from 'mobx'
import {AuthStoreInterface, AuthStoreSymbol} from '@/stores'
import {DossierStoreProductsType} from '@/stores/store-and-terminals'
import {LoadingState} from '@/types'
import {storage} from '@/services/storage'
import {MERCHANT_ID_KEY} from '@/constants/auth-constants'
import {
    DossierMerchantCompanyInfo,
    DossierMerchantInfoModel,
    DossierMerchantInfoStoreInterface,
    getDossierMerchantProfile
} from '@/stores/profile/DossierMerchantInfo'
import {noProductsAccess, PRODUCTS_KEY} from '@/stores/auth/constants/configurations'

export const DossierMerchantInfoStoreSymbol = Symbol.for('DossierMerchantInfoStoreSymbol')

@injectable()
export class DossierMerchantInfoStore implements DossierMerchantInfoStoreInterface {
    _dossierMerchantData: DossierMerchantInfoModel = null
    loadingState: LoadingState = LoadingState.IDLE
    _products: DossierStoreProductsType = null

    constructor(@inject(AuthStoreSymbol) authStore: AuthStoreInterface) {
        makeObservable(this, {
            _products: observable,
            loadingState: observable,
            _dossierMerchantData: observable,
            products: computed,
            dossierMerchantData: computed,
            mainInfo: computed,
            loadDossierMerchantData: action,
            storageListener: action
        })

        window.addEventListener('storage', this.storageListener)

        reaction(
            () => ({
                isAuthenticated: authStore.isAuthenticated,
                email: authStore?.email
            }),
            async ({isAuthenticated}) => {
                if (!isAuthenticated) {
                    runInAction(() => (this.loadingState = LoadingState.IDLE))
                    return
                }

                await this.loadDossierMerchantData()
            }
        )
    }

    get products(): DossierStoreProductsType {
        return this._products
    }

    get dossierMerchantData(): DossierMerchantInfoModel {
        if (isEmpty(this._dossierMerchantData) && this.loadingState === LoadingState.IDLE) {
            this.loadDossierMerchantData()
        }

        return this._dossierMerchantData
    }

    get mainInfo(): DossierMerchantCompanyInfo {
        return this.dossierMerchantData?.mainInfo
    }

    loadDossierMerchantData = async () => {
        if (this.loadingState === LoadingState.LOADING) {
            return
        }

        runInAction(() => (this.loadingState = LoadingState.LOADING))

        try {
            const {result, error} = await getDossierMerchantProfile()

            if (error) {
                storage.set(PRODUCTS_KEY, JSON.stringify(noProductsAccess))
                this._products = noProductsAccess
                runInAction(() => (this.loadingState = LoadingState.FAILED))
            } else {
                runInAction(() => {
                    this.loadingState = LoadingState.DONE
                    this._dossierMerchantData = result
                    storage.set(PRODUCTS_KEY, JSON.stringify(result?.products))
                    this._products = result?.products
                })

                if (!result) return
            }
        } catch (error) {
            storage.set(PRODUCTS_KEY, JSON.stringify(noProductsAccess))
            this._products = noProductsAccess
            runInAction(() => (this.loadingState = LoadingState.FAILED))
            throw new Error(error.message)
        }
    }

    storageListener = async (e: StorageEvent) => {
        if (storage.isEqual(e.key, MERCHANT_ID_KEY) && e.newValue === '""') {
            await this.loadDossierMerchantData()
        }
    }
}
