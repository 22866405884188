import React from 'react'
import {observer} from 'mobx-react-lite'
import {Spin} from 'antd'
import {useInjection} from 'dna-react-ioc'

import {disableDatesBeforeOneYear} from '@/stores'
import {RangePickerContainer, RefreshButton, SelectWithTitleContainer} from '@/components'

import {TChargebacksFilterStore} from './TChargebacksFilterStore'
import translations from './translations'
import styles from './styles.scss'

export const ChargebacksFilterStoreSymbol = Symbol('ChargebacksFilterStoreSymbol')

export const ChargebacksRangePickerContainerStoreSymbol = Symbol(
    'ChargebacksRangePickerContainerStoreSymbol'
)
export const ChargebacksStatusSelectContainerStoreSymbol = Symbol(
    'ChargebacksStatusSelectContainerStoreSymbol'
)

export const ChargebacksFilter = observer(() => {
    const store = useInjection<TChargebacksFilterStore>(ChargebacksFilterStoreSymbol)

    return (
        <div className={styles.root}>
            <Spin spinning={store.chargebacksTableStore.isLoading} indicator={<></>}>
                <div className={styles.container}>
                    <RefreshButton onClick={store.refresh} />

                    <SelectWithTitleContainer
                        className={styles.select}
                        displayValue={translations().status}
                        injectableIdentifier={ChargebacksStatusSelectContainerStoreSymbol}
                    />

                    <div className={styles.divider}></div>

                    <RangePickerContainer
                        injectableIdentifier={ChargebacksRangePickerContainerStoreSymbol}
                        disabledDate={disableDatesBeforeOneYear}
                    />
                </div>
            </Spin>
        </div>
    )
})
