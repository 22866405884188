import React from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react'
import {LatLngExpression} from 'leaflet'
import {MapContainer, Marker, TileLayer} from 'react-leaflet'
import {MapCenterUpdater} from './components'

import styles from './GeolocationMap.scss'
interface GeolocationPaneProps {
    center: LatLngExpression
    zoom?: number
    className?: string
}

export const GeolocationMap = observer(({center, className, zoom = 11}: GeolocationPaneProps) => {
    return (
        <div
            onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
            }}
            className={classNames(styles.geolocationMap, className)}
        >
            <MapContainer
                center={center}
                zoom={zoom}
                zoomControl={false}
                attributionControl={false}
            >
                <TileLayer
                    attribution={null}
                    url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                    minZoom={0}
                    maxZoom={20}
                />
                <Marker position={center} />
                <MapCenterUpdater center={center} />
            </MapContainer>
        </div>
    )
})
