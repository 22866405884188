import React from 'react'
import classNames from 'classnames'
import {Icon} from '@/components'
import styles from './EcospendBackLink.scss'

interface EcospendBackLinkProps {
    className?: string
    children?: React.ReactNode
    onClick(): void
}

export const EcospendBackLink: React.FC<EcospendBackLinkProps> = ({ className, onClick, children }) => (
    <a
        href="#"
        className={classNames(styles.ecospendBackLink, className)}
        onClick={(e) => { e.preventDefault(); onClick() }}>
        <Icon type="back_arrow" />
        {children}
    </a>
)
