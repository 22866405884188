import React from 'react'
import {notification} from 'antd'
import type {NotificationInstance} from 'antd/es/notification/interface'
import * as DOMPurify from 'dompurify'
import {Icon} from '@/components'

const NotificationInstance = notification

let notificationInstance: typeof NotificationInstance = null

export const setNotificationInstance = (instance: typeof NotificationInstance) =>
    (notificationInstance = instance)

export const openSuccessNotification = (message: string | React.ReactNode, duration = 3) => {
    const key = `openSuccess${Date.now()}`
    notificationInstance?.success({
        message,
        duration,
        key,
        placement: 'top',
        closeIcon: null,
        icon: <Icon hoverable onClick={() => notification.destroy(key)} type={'check'} />,
        style: {
            width: 575
        }
    })
}

export const openErrorNotification = (
    message: string | React.ReactNode,
    duration = 3,
    key = ''
) => {
    const generatedKey = key || `openError${Date.now()}`
    notificationInstance?.error({
        message,
        duration,
        key: generatedKey,
        placement: 'top',
        closeIcon: null,
        icon: <Icon hoverable onClick={() => notification.destroy(generatedKey)} type={'close'} />,
        style: {
            width: 575
        }
    })
}

export const notificationDescription = (description: string) => {
    return DOMPurify.default.sanitize(description || '')
}
