import {inject, injectable} from 'inversify'
import {action, computed, makeObservable, observable} from 'mobx'

import {IChangeBankAccountDetails} from '@/pages/ChangeBankAccountDetails'
import {POSOutletDetailsStoreSymbol} from '@/pages/POSOutletDetails/POSOutletDetails'
import {OutletDetailsStore} from '@/stores/outlets/OutletDetailsStore'
import {OnlineOutletDetailsStoreSymbol} from '@/pages/OnlineOutletDetails/OnlineOutletDetails'
import {OutletDetailsResponseModel} from '@/stores/outlets'
import {BankAccountsStore, DossierBankAccount, DossierBankDetail} from '@/stores/bank-accounts'
import {BankAccountsStoreSymbol} from '@/pages/BankAccounts/BankAccounts'
import {
    AccountType,
    ChangeBankAccountDetailsRequest,
    ModalState
} from '@/stores/change-bank-account-details/models'
import {changeBankAccountDetails} from '@/stores/change-bank-account-details/services/fetchers'
import {ProfileStoreSymbol} from '@/pages/Profile'
import {ProfileStore} from '@/stores/profile/ProfileStore'
import {
    DossierMerchantInfoStore,
    DossierMerchantInfoStoreSymbol
} from '@/stores/profile/DossierMerchantInfo'
import {LoadingState} from '@/types'
import {getRouterStore} from '@/router/utils'
import {PATHS} from './constants/constants'

@injectable()
export class ChangeBankAccountDetailsStore implements IChangeBankAccountDetails {
    private outletStore: OutletDetailsStore
    private bankAccountsStore: BankAccountsStore
    private profileStore: ProfileStore
    private dossierMerchantInfoStore: DossierMerchantInfoStore
    selectedBankAccount: DossierBankAccount = null
    loadingState: LoadingState = LoadingState.IDLE
    modalState: ModalState = {
        confirm: false,
        success: false,
        error: false
    }
    currentData: Pick<ChangeBankAccountDetailsRequest, 'paymentMethod'> = null
    selectAccountType = null

    constructor(
        @inject(POSOutletDetailsStoreSymbol) private posOutletStore: OutletDetailsStore,
        @inject(OnlineOutletDetailsStoreSymbol) private onlineOutletStore: OutletDetailsStore,
        @inject(BankAccountsStoreSymbol) private _bankAccountsStore: BankAccountsStore,
        @inject(ProfileStoreSymbol) profileStore: ProfileStore,
        @inject(DossierMerchantInfoStoreSymbol) dossierMerchantInfoStore: DossierMerchantInfoStore
    ) {
        this.outletStore = this.detectOutletStore()
        this.bankAccountsStore = _bankAccountsStore
        this.profileStore = profileStore
        this.dossierMerchantInfoStore = dossierMerchantInfoStore

        makeObservable(this, {
            selectedBankAccount: observable,
            loadingState: observable,
            modalState: observable,
            selectAccountType: observable,

            outletDetails: computed,
            isLoading: computed,
            accountType: computed,
            bankAccounts: computed,
            bankAccountsLoading: computed,
            hasSettlementBlocks: computed,
            hasDirectDebitBlocks: computed,
            hasAllBlocks: computed,
            associatedOutlets: computed,
            currentBankAccount: computed,

            changeBankDetails: action,
            openConfirmBankDetailsModal: action,
            handleModalState: action,
            onBack: action,
            setSelectAccountType: action
        })
    }

    get isLoading(): boolean {
        return this.loadingState === LoadingState.LOADING
    }

    get outletDetails(): OutletDetailsResponseModel {
        return this.outletStore.details
    }

    get currentBankAccount(): DossierBankDetail {
        if (this.accountType === 'settlement') {
            return this.outletDetails?.settlementAccounts[0]
        } else {
            return this.outletDetails?.directDebitAccounts[0]
        }
    }

    get bankAccounts(): DossierBankAccount[] {
        return this.bankAccountsStore?.bankAccounts?.filter(
            (account) => account.bankDetail.id !== this.currentBankAccount?.id
        )
    }

    get bankAccountsLoading(): boolean {
        return this.bankAccountsStore.loadingState === LoadingState.LOADING
    }

    get accountType(): AccountType {
        const path = window.location.pathname

        if (path.includes(PATHS.DIRECT_DEBIT)) return 'directDebit'

        if (path.includes(PATHS.SETTLEMENTS)) return 'settlement'

        return 'directDebit'
    }

    get hasSettlementBlocks(): boolean {
        return this.outletStore.hasSettlementBlocks
    }
    get hasDirectDebitBlocks(): boolean {
        return this.outletStore.hasDirectDebitBlocks
    }
    get hasAllBlocks(): boolean {
        return this.outletStore.hasAllBlocks
    }

    get shouldChangeAllOutletsBankDetails(): boolean {
        const mainInfo = this.dossierMerchantInfoStore.mainInfo
        const accountType = this.accountType

        if (!mainInfo || !accountType) {
            return false
        }

        const isUberGross =
            mainInfo.chargeMerchant === 'UBER_ID' && mainInfo.settlementType === 'DD'
        const isUberSettlement = mainInfo.settlementPayment === 'userid'

        switch (accountType) {
            case 'directDebit':
                return isUberGross

            case 'settlement':
                return isUberSettlement

            case 'all':
                if (isUberGross && !isUberSettlement) {
                    return true
                }

                if (isUberSettlement && mainInfo.settlementType !== 'DD') {
                    return true
                }

                return isUberGross && isUberSettlement
            default:
                return false
        }
    }

    get associatedOutlets(): string[] {
        if (this.shouldChangeAllOutletsBankDetails) {
            return this.selectedBankAccount?.stores?.map((store) => store.storeName) ?? []
        }
        return [this.outletDetails?.storeName].filter(Boolean)
    }

    openConfirmBankDetailsModal = (
        data: Pick<ChangeBankAccountDetailsRequest, 'paymentMethod'>
    ) => {
        this.currentData = data

        this.handleModalState('confirm', true)
    }

    changeBankDetails = async () => {
        try {
            this.loadingState = LoadingState.LOADING
            const prepareData: ChangeBankAccountDetailsRequest = {
                bankAccountNumber: this.selectedBankAccount?.bankDetail?.bankAccountNumber,
                bankAccountType: this.selectedBankAccount?.bankDetail?.bankAccountTypeCode,
                dossierId: this.dossierMerchantInfoStore?.mainInfo?.dsrId?.toString(),
                initiator: this.profileStore.profileData?.email,
                outletIds: [this.outletDetails?.id?.toString()],
                sortCode: this.selectedBankAccount?.bankDetail?.sortCode,
                firstName: this.profileStore?.profileData?.firstName,
                surname: this.profileStore?.profileData?.lastName,
                paymentMethod: this.currentData.paymentMethod
            }

            const response = await changeBankAccountDetails(prepareData)

            if (response.status === 200) {
                this.loadingState = LoadingState.DONE
                this.handleModalState('success', true)
                this.onCloseConfirmModal()
                this.initStore()
            } else {
                this.loadingState = LoadingState.FAILED
                this.handleModalState('error', true)
                this.onCloseConfirmModal()
                throw new Error(`HTTP error! Status: ${response.status}`)
            }
        } catch (error) {
            this.loadingState = LoadingState.FAILED
            console.error(error)
        }
    }

    private detectOutletStore(): OutletDetailsStore {
        const path = window.location.pathname

        if (path.includes('/pos-outlets/')) {
            return this.posOutletStore
        }

        if (path.includes('/online-outlets/')) {
            return this.onlineOutletStore
        }

        throw new Error('Can not detect Outlet Store')
    }

    selectBankAccount = (account: DossierBankAccount) => {
        this.selectedBankAccount = account
    }

    onBack = () => {
        this.setSelectAccountType(null)
        getRouterStore().back()
    }

    onCloseConfirmModal = () => {
        this.handleModalState('confirm', false)
    }

    onCloseSuccessModal = async () => {
        this.handleModalState('success', false)
        this.setSelectAccountType(null)
        await this.loadData()
        this.onBack()
    }

    onCloseErrorModal = async () => {
        this.handleModalState('error', false)
        this.setSelectAccountType(null)
        await this.loadData()
        this.onBack()
    }

    initStore = () => {
        this.handleModalState('confirm', false)
        this.currentData = null
    }

    loadData = async () => {
        await this.bankAccountsStore.loadDossierBankAccounts()
    }

    handleModalState = (type: keyof ModalState, isOpen: boolean): void => {
        this.modalState[type] = isOpen
    }

    setSelectAccountType = (type: AccountType): void => {
        this.selectAccountType = type
    }
}
