import React from 'react'
import classNames from 'classnames'
import {ReloadOutlined} from '@ant-design/icons'
import {Button} from '@/components'

import styles from './styles.scss'

import {REFRESH} from '@/constants/playwright-ids'

export const RefreshButton = ({
    onClick,
    disabled,
    className
}: {
    onClick: () => void
    disabled?: boolean
    className?: string
}) => {
    return (
        <Button
            onClick={onClick}
            className={classNames(styles.button, className)}
            icon={<ReloadOutlined />}
            disabled={disabled}
            data-test-id={REFRESH}
        />
    )
}
